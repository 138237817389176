import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "dComponent";

class DComponentService {

    async listComponents(category) {
        return await axios.get( getCustomUrl(apiPrefix, `/list?category=${category}`), defaultHeaders() );
    }
    
}

export default DComponentService;