import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { deleteDFolder } from 'services/actions/DFolderAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { connect } from 'formik';

export const ModalDeleteFolder = (props) => {

    const { open, handleClose, handleConfirmClose, dFolder } = props;

    const onDelete = () => {
      props.dispatch(showBackdrop(true));
      props.dispatch(deleteDFolder(dFolder.id)).then((res) => {
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
        props.dispatch(showSnackBar("error", "Carpeta Eliminada"));
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo Eliminar la Carpeta"));
        props.dispatch(showBackdrop(false));
      });
    }
    
    return (
      <Modal
        open={open}
        handleClose={handleClose}
        size="xs"
      >
        <ModalHeader
          text="Eliminar Carpeta"
          className='positionElements'
          onCancel={handleClose}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
              ¿Está seguro de eliminar esta carpeta y sus archivos?
            </Typography>
        </ModalBody>
  
        <ModalFooter 
          confirmText="Eliminar"
          cancelText="Cancelar"
          onCancel={handleClose}
          className="footerRight"
          onConfirm={onDelete}
        />
      </Modal>
      
    )
  }

  const mapStateToProps = (state) => ({...state})
  export default connect(mapStateToProps)(ModalDeleteFolder)