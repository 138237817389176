import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Formik } from 'formik';
import { TextField, Typography, Grid, Divider} from '@material-ui/core';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { listStyles } from 'services/actions/DStyleAction'
import { listComponents } from 'services/actions/DComponentAction'
import { createItem, findItem, updateItem } from 'services/actions/ItemAction'
import CustomTableStyle from 'components/CustomAddStyle';
import {Autocomplete} from '@material-ui/lab';

export const ModalItem = (props) => {
  const { open, closeModal, confirmCloseModal, editMode, idItem} = props; 

  const [data, setData] = React.useState({
    position: "",
    category: null,
    component: null,
    typeValue: "",
  });  

  const [dataTable, setDataTable] = React.useState({
    category_id: null,
    style_id: null,
    typeValue: "",
  });

  const [table, setTable] = React.useState([]);
  const [styles, setStyles] = React.useState([]);
  const [components, setComponents] = React.useState([]);
  const [categorySearch, setCategorySearch] = React.useState("");
  const [componentSearch, setComponentSearch] = React.useState("");

  const categories = [
    {id: 'Texto', name: 'Texto'},
    {id: 'Lista', name: 'Lista'},
    {id: 'Forma', name: 'Forma'},
    {id: 'Tabla', name: 'Tabla'},
    {id: 'Ilustraciones', name: 'Ilustraciones'},
  ];

  React.useEffect(()=>{
    if(open){
      if(idItem){
        props.dispatch(showBackdrop(true));
        props.dispatch(findItem(idItem)).then(res => {
          setData({
            ...data,
            position: res.position,
            typeValue: res.value,
            category: {id: res.category, name: res.category},
            component: {id: res.componentMapping.id, name: res.componentMapping.name},
          }); 

          props.dispatch(listComponents(res.category)).then(resComponent => {                           
            setComponents(getCustomComponents(resComponent));
          })

          setTable(setCustomTable(res.mappedStyles))
          
          props.dispatch(showBackdrop(false));
        });
      }
    }
  },[open])

  const validateForm = (values) => {
    let errors = {};

    const formats = {
      name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
    };

    if(!values.position) errors.position = "Posición requerida";
    if(!values.typeValue) errors.typeValue = "Tipo de valor requerido";
    if(values.typeValue && !formats.name.test(values.typeValue)) errors.typeValue = "Tipo de valor inválido";
    if(!values.category) errors.category = "Categoria requerida";
    
    return errors;
  };

  const onSubmit = (values, {resetForm} ) => {
    const formData = new FormData();
    formData.append('position', values.position);
    formData.append('category', values.category && values.category.id);
    formData.append('idComponent', values.component && values.component.id);
    formData.append('value', values.typeValue);

    const selectStyles = table.map((row) => ({
      idStyle: row.id,
      value: row.typeValue
    }));
    formData.append('styles', JSON.stringify(selectStyles));

    props.dispatch(showBackdrop(true));
    if (idItem) {
      props.dispatch(updateItem(idItem, formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Item Actualizado"));
        confirmCloseModal && confirmCloseModal();
      }).catch(err => {
        props.dispatch(showSnackBar('error', err.response.data.error));
        props.dispatch(showBackdrop(false));
      });
    }else{
      props.dispatch(createItem(formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Item Registrado"));
        confirmCloseModal && confirmCloseModal();
      }).catch(err => {
        props.dispatch(showSnackBar('error', err.response.data.error));
        props.dispatch(showBackdrop(false));
      });
    }

  }

  const getComponents = (category) =>{
    if (category) {
      props.dispatch(showBackdrop(true));
      props.dispatch(listComponents(category.id)).then(res => {
        props.dispatch(showBackdrop(false));
        setComponents(getCustomComponents(res));
      })
    }
  }

  const getStyles = (category) =>{
    if (category) {
      props.dispatch(showBackdrop(true));
      props.dispatch(listStyles(category.id)).then(res => {
        props.dispatch(showBackdrop(false));
        setStyles(getCustomStyles(res));
      })
    }
  }

  const getCustomComponents = (originalComponents) => {
    return originalComponents ? originalComponents.map(component => ({id: component.id, name: component.name})) : [];
  }

  const getCustomStyles = (originalStyles) => {
    return originalStyles ? originalStyles.map(style => ({id: style.id, name: style.name})) : [];
  }

  const setCustomTable = (res) => {
    return res ? res.map(style => ({index: style.index, id: style.id, name: style.category, style: style.name, typeValue: style.value})) : [];
  }

  const customComponent = getCustomComponents(components || []);
  const customStyles = getCustomStyles(styles || []);

  const setCategory = (event) => {
    setDataTable({...dataTable, category_id: event, style_id: null, typeValue: ""});
    getStyles(event);
  }

  const setStyle = (event) => {
    setDataTable({...dataTable, style_id: event});
  }

  const setTypeValue = (event) => {
    setDataTable({...dataTable, typeValue: event.target.value});
  }

  const addStyleTable = () => {
    if (!dataTable.style_id || !dataTable.category_id || !dataTable.typeValue) {
      props.dispatch(showSnackBar('error', 'Llenar todos los campos de estilos'));
    }else{
      const index = table.length;
      setTable([
        ...table,
        {index: index, id: dataTable.style_id.id, name: dataTable.category_id.name, style: dataTable.style_id.name, typeValue: dataTable.typeValue}
      ]);      
      setDataTable({ category_id: null, style_id: null, typeValue: ""});
    } 
  }

  const deleteStyleTable = (selectStyle) => {
    const index = table.findIndex(value => value.index === selectStyle.index);
    table.splice(index,1);
    setTable([
      ...table
    ]);
  }

  return (
    <Modal
      open={open}
      handleClose={()=> { closeModal(false) }}
      size="md"
    >
      <ModalHeader
        text={editMode ? "Editar Item":"Nuevo Item"}
        className='positionElements'
        onCancel={()=> { closeModal(false) }}
      >
      </ModalHeader>

      <ModalBody>
        
        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems="center" justifyContent="center">

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>Posición: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="position"
                        type="number" 
                        name="position"
                        value={values.position}
                        onChange={handleChange}
                        placeholder="Posición"
                        error={errors.position && touched.position ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>Categoría: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        id="category"
                        value={values.category}
                        onChange={(event, newValues) => {
                          setFieldValue("category", newValues);
                          setFieldValue("component", null);
                          setFieldValue("typeValue", "");
                          getComponents(newValues);
                        }}
                        options={categories || []}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        inputValue={categorySearch}
                        onInputChange={(event, newInputValue) => {
                          setCategorySearch(newInputValue);
                        }}
                        filterSelectedOptions
                        renderInput={params => (
                            <TextField {...params} variant="standard" fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>Ítem: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        id="component"
                        value={values.component}
                        onChange={(event, newValues) => {
                          setFieldValue("component", newValues);
                        }}
                        options={customComponent || []}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        inputValue={componentSearch}
                        onInputChange={(event, newInputValue) => {
                          setComponentSearch(newInputValue);
                        }}
                        filterSelectedOptions
                        renderInput={params => (
                            <TextField {...params} variant="standard" fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>Valor: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="typeValue"
                        type="text" 
                        name="typeValue"
                        fullWidth
                        value={values.typeValue}
                        onChange={handleChange}
                        placeholder="Valor"
                        error={errors.typeValue && touched.typeValue ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <CustomTableStyle
                      {...props}
                      customStyles={customStyles}
                      data={dataTable}
                      table={table}
                      setCategory={setCategory}
                      setStyle={setStyle}
                      setTypeValue={setTypeValue}
                      addStyleTable={addStyleTable}
                      deleteStyleTable={deleteStyleTable}
                    />
                  </Grid>
                </Grid>

                <Divider className="custom-divider"/>
                <ModalFooter 
                  buttonType="submit"
                  confirmText={editMode ? "ACTUALIZAR" : "REGISTRAR"}
                  cancelText="CANCELAR" 
                  onCancel={closeModal}
                  onConfirm={handleSubmit}
                />

              </form>
            )
          }}
          </Formik>

      </ModalBody>

    </Modal>
  )
}