import React from 'react';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { renameDFile } from 'services/actions/DFileAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Field, Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { TextField } from '@material-ui/core';

export const ModalRenameFile = (props) => {

  const { open, handleClose, handleConfirmClose, dFile} = props;

  const [data, setData] = React.useState({
    name: ""
  });

  React.useEffect(() => {
    if(open){
      setData({
        name: dFile.name
      });
    }else{
      setData({
        name: ""
      });
    }
  }, [open]);

  const validateForm = (values) => {
    let errors = {};
    
    if (!values.name) errors.name = 'Nombre requerido';
    
    return errors;
  }

  const onSubmit = (values) => {
    props.dispatch(showBackdrop(true));
    props.dispatch(renameDFile(dFile.id, values.name)).then(res => {
      props.dispatch(showSnackBar("success", "Archivo renombrado"));
      props.dispatch(showBackdrop(false));
      handleConfirmClose && handleConfirmClose();
    }).catch(err => {
      props.dispatch(showSnackBar("warning", err.response.data.error || ""));
      props.dispatch(showBackdrop(false));
    });
  }

  return (
    
    <Modal
      open={open}
      handleClose={handleClose}
      size="xs"
    >
      <ModalHeader
        icon={<BorderColorIcon />}
        text="Renombrar Archivo"
        className='positionElements'
        onCancel={handleClose}
      >
      </ModalHeader>

      <ModalBody>
        
        <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {
            ({handleChange, handleSubmit, setFieldValue, values, errors, touched}) => {
              return (
                <Form onSubmit={handleSubmit}>

                  <TextField
                    id="name"
                    type="text" 
                    value={values.name}
                    onChange={handleChange}
                    error={ errors.name && touched.name ? true : false }
                    placeholder="Nombre de la carpeta" 
                    fullWidth
                    style={{padding: "5px"}}
                  />

                  <ModalFooter 
                    confirmText="RENOMBRAR"
                    cancelText="CANCELAR"
                    onCancel={handleClose}
                    onConfirm={handleSubmit}
                    className="footerRight"
                  />

                </Form>
              )
            }
          }
        </Formik>

      </ModalBody>

      
    </Modal>
    
  )
};