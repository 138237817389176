import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CssBaseline, makeStyles } from '@material-ui/core';

import MiniDrawer from 'components/mini_drawer/MiniDrawer';
import MainHeader from 'components/header/MainHeader';
import { getSessionInfo } from 'utils/common'


const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100hv',
  },
}))

const PrivateRoute = ({ component:Component, ...rest }) => {
  const classes = useStyles();

  const session = getSessionInfo();

  return (
    <div className={classes.root}>
      {
        session ? (
          <>
            <CssBaseline />
            <MiniDrawer {...rest}/>
            <MainHeader/>
            <Route {...rest} render={props => <div style={{width: "100%"}}> <Component {...props} {...rest} /> </div>}/>
          </>
        ) : (
          <>
            <Route {...rest} render={props => <div style={{width: "100%"}}> <Redirect to="/" /> </div>}/>
          </>
        )
      }
    </div>
  );
}

export default PrivateRoute;