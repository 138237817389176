import { DialogTitle } from '@material-ui/core';
import React from 'react'
import { Typography,Button  } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const ModalHeader = (props) => {
  const { icon, text, className="", onCancel } = props;

  return (
    <DialogTitle className="modal-header">
      <div className={className}>
        <div className={className}>
          {icon && icon}<Typography className="ml2">{text || ""}</Typography>
        </div>
        <Button onClick={onCancel && onCancel}>
            <CloseIcon />
        </Button>       
      </div>
    </DialogTitle>
  )
}

export default ModalHeader
