import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { deleteDFile } from 'services/actions/DFileAction'; 
import { paperbinDFile } from 'services/actions/DFileAction'; 
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { connect } from 'formik';

export const ModalDeleteFile = (props) => {

    const { open, handleClose, handleConfirmClose, dFile } = props;

    const updateDeleted = () => {
      props.dispatch(showBackdrop(true));
      props.dispatch(deleteDFile(dFile.id)).then(res => {
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
        props.dispatch(showSnackBar("error", "Archivo Eliminado"));
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo Eliminar el Archivo"));
        props.dispatch(showBackdrop(false));
      });
    }

    const InPaperBin = () => {
      props.dispatch(showBackdrop(true));
      props.dispatch(paperbinDFile(dFile.id)).then(res => {
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
        props.dispatch(showSnackBar("warning", "Enviado a la Papelera"));
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || ""));
        props.dispatch(showBackdrop(false));
      });
    }
    
    return (
      <Modal
        open={open}
        handleClose={handleClose}
        size="sm"
      >
        <ModalHeader
          text="Eliminar Archivo"
          className='positionElements'
          onCancel={handleClose}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
            ¿Está seguro de Eliminar este Archivo?
            </Typography>
        </ModalBody>
  
        <ModalFooter
          confirmText="Eliminar Definitivamente"
          cancelText="Cancelar"
          onCancel={handleClose}
          className="footerRight"
          onConfirm={updateDeleted}
          onSecondaryConfirm={InPaperBin}
          secondaryText="Enviar a Papelera"
        />
      </Modal>
      
    )
  }

  const mapStateToProps = (state) => ({...state})
  export default connect(mapStateToProps)(ModalDeleteFile)
