import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { pColor, pLetterColor, sColor } from 'assets/styles/docset-css';

const theme = createMuiTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.2,
    primary: {
      light: '#6FB2D1',
      main: pColor,
      dark: '#2D799C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#BBBBBB',
      main: '#A5A5A5',
      dark: '#868686',
      contrastText: '#fff',
    },
  },
});

const ThemeSettings = (props) => {
	return(
		<MuiThemeProvider theme={theme}>
			{props.children}
		</MuiThemeProvider>
	);
}

export default ThemeSettings;
