import React, {useState, useEffect} from 'react'
import CloseIcon from '@material-ui/icons/Close';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { TextField, Typography, Grid, Button, Avatar, Select, MenuItem, Divider} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PersonAdd } from '@material-ui/icons';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { createUser, updateUser, getRoles, findUser } from 'services/actions/UserAction';
import { getCustomRoleName, getSessionInfo } from 'utils/common';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOff';
import config from "config/Config";
import { listCompanies } from 'services/actions/CompanyAction';
import {Autocomplete} from '@material-ui/lab';

const cleanUser = {
  id: "",
  idRole: 3,
  username: "",
  companies: [],
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  rut: "",
  dob: new Date(),
  phone: "",
  sex:"M",
  storage: 0,
  usedStorage: 0,
  avatar: null
};

export const ModalUser = (props) => {
  const { open, handleClose, editMode, user, onConfirmCallBack } = props;

  const session = getSessionInfo();
  const adminUser = session && session.user || {};
  const role = session && session.role || {};
  const companyId = session && session.user && session.user.idCompany || {};
  const isUserAdmin = role.name == "Admin";
  const isUserAdminCompany = role.name == "AdminEmpresa";

  const [data, setData] = React.useState(cleanUser);
  const [roles, setRoles] = React.useState([]);
  const [avatarImage, setAvatarImage] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const [companySearch, setCompanySearch] = React.useState("");

  React.useEffect(()=>{
    if(open){
      if(user){
        props.dispatch(showBackdrop(true));
        props.dispatch(findUser(user.id)).then(res => {
          props.dispatch(showBackdrop(false));
          setData({
            ...res,
            storage: isUserAdminCompany ? res.userCompany.storage : 0,
            usedStorage: isUserAdminCompany ? res.userCompany.usedStorage : 0,
            companies: getCustomCompanies(res.mappedCompanies || [])
            });
        })
      }else{
        setData(cleanUser);
      }
      setAvatarImage(null);
      props.dispatch(getRoles()).then(res => setRoles(res || []));
      if(isUserAdmin){
        props.dispatch(listCompanies()).then(response =>{
          setCompanies(response);
        });
      }
    }else{
      setData(cleanUser);
    }
  },[open])

  const validateForm = (values) => {
      let errors = {};
      const formats = {
        name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        storage: /^\d{0,14}$/,
      };

      if(!values.firstName) errors.firstName = "Primer nombre requerido";
      if(values.firstName && !formats.name.test(values.firstName)) errors.firstName = "Primer nombre inválido";
      if(!values.lastName) errors.lastName = "Apellido requerido";
      if(values.lastName && !formats.name.test(values.lastName)) errors.lastName = "Apellido inválido";
      if(!values.idRole) errors.idRole = "Rol requerido";
      if(!values.username) errors.username = "Nombre de usuario requerido";
      if(!values.email) errors.email = "Correo requerido";
      if(values.email && !formats.email.test(values.email)) errors.email = "Correo inválido";
      if(!values.phone) errors.phone = "N° Celular requerido";
      if(!user && !values.password) errors.password = "Contraseña requerido";

      if(!values.rut) errors.rut = "RUT requerido";
      if(isUserAdmin && values.idRole != 1 && (!values.companies || values.companies.length == 0)) errors.companies = "Al menos una empresa es necesaria";

      if (isUserAdminCompany) {
        if(!values.storage) errors.storage = "Almacenamiento requerido";
        if(!formats.storage.test(values.storage)) errors.storage = "Almacenamiento inválido";
      }
      return errors;
  };

  const processImage = (event) => {
    if(event && event.target.files && event.target.files.length > 0){
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setAvatarImage(imageUrl)
    }else{
        setAvatarImage(null)
    }
 }

  const deleteImage = (values) => {
    setAvatarImage(null);  
    setData({...values, avatar:null});
    document.getElementById('image').value = "";
  }

  const onSubmit = (values, {resetForm} ) => {
    const fileInput = document.querySelector('#image') ;

    const formData = new FormData();
    formData.append('image', fileInput.files[0]);
    if(values.avatar) formData.append('avatar', values.avatar);
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('sex', values.sex);
    formData.append('email', values.email);
    formData.append('username', values.username);

    var dateDOB = (new Date(values.dob)).toUTCString();
    formData.append('dob', dateDOB);
    formData.append('phone', values.phone);
    formData.append('rut', values.rut);
    formData.append('idRole', values.idRole);
    formData.append('storage', values.storage);


    if(isUserAdmin) {
      if (values.idRole !== 1){        
        for (var i = 0; i < values.companies.length; i++) {
          formData.append('companies[]', values.companies[i].id);
        }
      }
    }else {
      formData.append('companies[]', companyId);
    }

    props.dispatch(showBackdrop(true));
    if(values.id){
        // Editar
        props.dispatch(updateUser(values.id, formData)).then(res => {
          props.dispatch(showBackdrop(false));
          props.onConfirmCallBack && props.onConfirmCallBack();
        }).catch(err => {
          props.dispatch(showSnackBar('error', err.response.data.email ? err.response.data.email : err.response.data.error));
          props.dispatch(showBackdrop(false));
        });
    }else{
        // Agregar
        formData.append('password', values.password);
        
        props.dispatch(createUser(formData)).then(res => {
            props.dispatch(showBackdrop(false));
            props.onConfirmCallBack && props.onConfirmCallBack();
        }).catch(err => {
            props.dispatch(showSnackBar('error', err.response.data.email ? err.response.data.email : err.response.data.error));
            props.dispatch(showBackdrop(false));
            console.log('error', err);
        });
    }
  }

  const limitedRoles = !isUserAdmin ? (roles.filter(r => parseInt(r.id) != 1)) : roles;

  const getCustomCompanies = (originalCompanies) => {
    return originalCompanies ? originalCompanies.map(company => ({id: company.id, name: company.name})) : [];
  }

  const customCompanies = getCustomCompanies(companies || []);

  return (
    <Modal open={open} handleClose={handleClose} handle size="sm">
      <ModalHeader
        icon={<PersonAdd />}
        text={user ? (editMode ? "Editar" : "Detalles") : "Agregar Usuario"}
        className='positionElements'
        onCancel={handleClose}
      >
      </ModalHeader>

      <ModalBody>
        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems="center" justify="center">
                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Nombres:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="firstName"
                        type="text" 
                        name="firstName"
                        fullWidth
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="Nombres"
                        error={errors.firstName && touched.firstName ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Apellidos:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="lastName"
                        type="text" 
                        name="lastName"
                        fullWidth
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="Apellidos"
                        error={errors.lastName && touched.lastName ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1"><b>Sexo:</b> </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        id="sex"
                        value={values.sex}
                        onChange={(event)=>{
                          setFieldValue("sex", event.target.value)
                        }}
                        fullWidth
                        disabled={!editMode}
                      >
                        <MenuItem value="M">Hombre</MenuItem>
                        <MenuItem value="F">Mujer</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Usuario:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="username"
                        type="text"
                        name="username"
                        fullWidth
                        value={values.username}
                        onChange={handleChange}
                        placeholder="Nombre de usuario"
                        error={errors.username && touched.username ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Correo:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="email"
                        type="text" 
                        name="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Correo electrónico"
                        error={errors.email && touched.email ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>


                  {
                    !values.id && (
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Contraseña:</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="password"
                            type="password" 
                            name="password"
                            fullWidth
                            value={values.password}
                            onChange={handleChange}
                            placeholder="****"
                            error={errors.password && touched.password ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>RUT:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="rut"
                        type="text" 
                        name="rut"
                        fullWidth
                        value={values.rut}
                        onChange={handleChange}
                        placeholder="Rut de usuario"
                        error={errors.rut && touched.rut ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>N° Celular:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="phone"
                        type="text" 
                        name="phone"
                        fullWidth
                        value={values.phone}
                        onChange={handleChange}
                        placeholder="Número de celular"
                        error={errors.phone && touched.phone ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Fecha de Nacimiento:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              id="dob"
                              label="Fecha de Nacimiento"
                              onChange={(date) => {
                                  setFieldValue("dob", date);
                              }}
                              value={values.dob}
                              autoOk={true}
                              disableFuture
                              className="custom-datepicker"
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                              disabled={!editMode}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="body1"><b>Rol:</b> </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        id="idRole"
                        value={values.idRole}
                        onChange={(event) => { 
                          setFieldValue("idRole", event.target.value);
                         }}
                        error={ errors.idRole && touched.idRole ? true : false }
                        fullWidth
                        disabled={!editMode}
                      >
                        {
                          limitedRoles.map((role, i) => {
                            var formatRole = {...role, name: getCustomRoleName(role.name)};
                            return <MenuItem key={i} value={formatRole.id}>{formatRole.name}</MenuItem>;
                          })
                        }
                      </Select>
                    </Grid>
                  </Grid>

                  {
                    values.idRole != 1 && isUserAdmin && (
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography variant="body1"><b>Empresas:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <Autocomplete
                            id="companies"
                            multiple
                            value={values.companies}
                            onChange={(event, newValues) => {
                              setFieldValue("companies", newValues)
                            }}
                            options={customCompanies || []}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionSelected={(option, value) => option.id === value.id}
                            inputValue={companySearch}
                            onInputChange={(event, newInputValue) => {
                              setCompanySearch(newInputValue);
                            }}
                            filterSelectedOptions
                            renderInput={params => (
                                <TextField {...params} variant="standard" fullWidth />
                            )}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    ) 
                  }

                  {
                    isUserAdminCompany && (
                    <>
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Almacenamiento (Mb):</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="storage"
                            type="text" 
                            name="storage"
                            fullWidth
                            value={values.storage}
                            onChange={handleChange}
                            placeholder="Almacenamiento (Mb)"
                            error={errors.storage && touched.storage ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Espacio usado:</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="storage"
                            type="text" 
                            name="storage"
                            fullWidth
                            value={values.usedStorage || 0}
                            onChange={handleChange}
                            placeholder="Espacio usado (Bytes)"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </>
                    )
                  }

                  {
                      ( !user || editMode) && (
                        <Grid item xs={12}>
                          <p style={{margin:"0px"}}> <b>Avatar</b> </p>
                          <Button
                            variant="contained"
                            component="label"
                            fullWidth
                          >
                            <GetAppIcon style={{marginRight: "12px"}} />
                            <input id="image" accept="image/*" type="file" onChange={processImage} />
                          </Button>
                        </Grid>
                      )
                  }
                  {
                    (avatarImage || (values.id && values.avatar)) && (
                      <Grid container item xs={12} justify = "center" >
                        <Avatar 
                          style={{ height:140, width:140, display:"flex" }} 
                          src={avatarImage ? avatarImage : (config.api + values.avatar)}
                        />
                        {
                          editMode && values.avatar && (
                            <HighlightOffTwoToneIcon 
                              style={{ color: 'red', display: values.id ? "flex" : "none" }} 
                              onClick={() => { deleteImage(values) }}
                            />
                          )
                        }
                    </Grid>
                    )
                  }
                </Grid>
                
                <Divider className="custom-divider"/>
                {
                  ( !user || editMode ) && (
                    <ModalFooter 
                      buttonType="submit"
                      confirmText={user ? "ACTUALIZAR" : "REGISTRAR"}
                      cancelText="CANCELAR" 
                      onCancel={handleClose}
                      onConfirm={handleSubmit}
                    />
                  )
                }
              </form>
            )
          }}

        </Formik>
      </ModalBody>

      
    </Modal>
  )
}