export const customCategories = [
    {id: 'Letra', name: 'Letra'}
];

export const customSizes = [
  {id: "20px", name: "Muy Grande"},
  {id: "18px", name: "Grande"},
  {id: "16px", name: "Mediano"},
  {id: "13px", name: "Pequeño"},
  {id: "10px", name: "Muy Pequeño"},
];

export const customWeights = [
  {id: "normal", name: "Normal"},
  {id: "bold", name: "Negrita"},
  {id: "bolder", name: "Fuerte"},
  {id: "lighter", name: "Ligero"},
];

export const customUnderline = [
  {id: "underline", name: "Con Subrayado"},
  {id: "", name: "Sin Subrayado"},
];
