import SectionService from "services/api/SectionService";

const service = new SectionService();

export const createSection = (data) => async dispatch => {
    const res = await service.createSection(data);
    return res && res.data || [];
}

export const updateSection = (id, data) => async dispatch => {
    const res = await service.updateSection(id, data);
    return res && res.data || [];
}

export const findSection = (id) => async dispatch => {
    const res = await service.findSection(id);
    return res && res.data || [];
}

export const listSection = (idTemplate, idSection) => async dispatch => {
    const res = await service.listSection(idTemplate, idSection);
    return res && res.data || [];
}

export const deleteSection = (id) => async dispatch => {
    const res = await service.deleteSection(id);
    return res && res.data || [];
}