import CompanyService from "services/api/CompanyService";

const service = new CompanyService();

export const createCompany = (data) => async dispatch => {
    const res = await service.createCompany(data);
    return res && res.data || [];
}

export const updateCompany = (id, data) => async dispatch => {
    const res = await service.updateCompany(id, data);
    return res && res.data || [];
}

export const findCompany = (id) => async dispatch => {
    const res = await service.findCompany(id);
    return res && res.data || [];
}

export const listCompanies = (term) => async dispatch => {
    const res = await service.listCompanies(term);
    return res && res.data || [];
}

export const deleteCompany = (id) => async dispatch => {
    const res = await service.deleteCompany(id);
    return res && res.data || [];
}