import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import BusinessIcon from '@material-ui/icons/Business';
import StarIcon from '@material-ui/icons/Star';
import { MenuOptionsFolder } from './MenuOptionsFolder';
import { useHistory } from 'react-router-dom';
import { sColor } from 'assets/styles/docset-css';

const Folder = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(.5),
    margin: theme.spacing(.9),
    width: "90%",
    color: theme.palette.text.secondary,
    cursor: "pointer"
}));

const CustomFolder = (props) => {
    const { dFolder, keyId, showBreadCrumb } = props;

    const [folderOptions, setFolderOptions] = React.useState(null);
    const [showFolderOptions, setShowFolderOptions] = React.useState(false);
    const history = useHistory();

    const openFolderOptions = (event) => {
        setFolderOptions(event.currentTarget);
        setShowFolderOptions(true);
        event.preventDefault();
    }

    const closeFolderOptions = () => {
        setShowFolderOptions(false);
        setFolderOptions(null);
    }

    const openFolder = () => {
        const path = history.location.pathname;
        switch (true) {
            case path.includes('/compartidos-conmigo'):
                history.push(`/compartidos-conmigo/${dFolder.id}`);
                break;
            case path.includes('/compartidos-empresa'):
                history.push(`/compartidos-empresa/${dFolder.id}`);
                break;
            default:
                history.push(`/mi-carpeta/${dFolder.id}`);
                break;
        }
        
        showBreadCrumb && showBreadCrumb();
    }

    return(
        <Grid key={keyId} item xs={12} sm={4} md={3} lg={2}>
            <Folder className="content_folder" onContextMenu={openFolderOptions} onClick={closeFolderOptions} onDoubleClick={openFolder}>
                {
                    !!dFolder.sharedCompany && <BusinessIcon style={{color: sColor, float:"right" }}/>
                }
                { !!dFolder.important && <StarIcon className="start_icon"/> }  
                <IconButton aria-label="settings" align="right" className='iconButton'>
                    <FolderIcon className='folder_icon'/>
                    <Typography className="name">
                        { dFolder.name }
                    </Typography>          
                </IconButton>
            </Folder> 
            <MenuOptionsFolder
                id="long-menu"
                options={folderOptions}
                open={showFolderOptions}
                dFolder={dFolder}
                handleClose={closeFolderOptions}
                onRename={() => { props.onRename && props.onRename(dFolder) }}
                onImportant={() => { props.onImportant && props.onImportant(dFolder) }}
                onShare={() => { props.onShare && props.onShare(dFolder) }}
                onDeleted={() => { props.onDeleted && props.onDeleted(dFolder) }}
            />
        </Grid>
    );
}
export default CustomFolder;