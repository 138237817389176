import DStyleService from "services/api/DStyleService";

const service = new DStyleService()

export const listStyles = (category) => async dispatch => {
    const res = await service.listStyles(category);
    return res && res.data || [];
}
export const listValues = (typeValues) => async dispatch => {
    const res = await service.listValues(typeValues);
    return res && res.data || [];
}