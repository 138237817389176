import React, { Component, createRef } from "react";
import ModalBody from './common/ModalBody'
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import Grid from '@material-ui/core/Grid';
import ProgressBar from 'react-customizable-progressbar'
import { Typography } from "@material-ui/core";
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import {getStorage} from 'services/actions/UserAction'


const ModalStorage = (props) => {

    const { open, handleClose } = props;
    const [storage, setStorage] = React.useState(null);

    React.useEffect (() =>{
        if(open){
            dataStorage();
        }
    }, [open])

    const dataStorage = () => {
        props.dispatch(showBackdrop(true));
        props.dispatch(getStorage()).then(res => {
            setStorage(res);
            props.dispatch(showBackdrop(false));
            props.onConfirmCallBack && props.onConfirmCallBack();
          }).catch(err => {
            props.dispatch(showSnackBar('error', err.response.data.email ? err.response.data.email : err.response.data.error));
            props.dispatch(showBackdrop(false));
          });
    }

    const miCarpeta = storage ? parseFloat(storage.miCarpeta / storage.storageTotal * 100).toFixed(2) : 0;
    const archived = storage ? parseFloat(storage.archived / storage.storageTotal * 100).toFixed(2) : 0;
    const paperBin = storage ? parseFloat(storage.paperBin / storage.storageTotal * 100).toFixed(2) : 0;
    const available = storage ? parseFloat(storage.available / storage.storageTotal * 100).toFixed(2) : 0;

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            handle
            size="md"
        >
            <ModalHeader
                text={"Almacenamiento Utilizado"}
                className='positionElements'
                onCancel={handleClose}
            />

            <ModalBody>
                <Grid container className='container'>
                    <Grid style={progress}>
                        <ProgressBar
                            progress={miCarpeta}
                            radius={68}
                            strokeLinecap={'round'}
                            transition={'0.3s ease'}
                            trackStrokeLinecap={'round'}
                            trackTransition={'1s ease'}
                            initialAnimation={true}
                            initialAnimationDelay={1}
                            strokeWidth={8}
                            trackStrokeWidth={8}
                            strokeColor={'#f4ce4a'}
                            children={`Mi Carpeta ${storage ? storage.miCarpeta : 0} Bytes`}
                        />
                            <Typography style={label} >{`${miCarpeta}%`}</Typography>
                    </Grid>

                    <Grid style={progress}>
                        <ProgressBar
                            progress={archived}
                            radius={68}
                            strokeLinecap={'round'}
                            transition={'0.3s ease'}
                            trackStrokeLinecap={'round'}
                            trackTransition={'1s ease'}
                            initialAnimation={true}
                            initialAnimationDelay={1}
                            strokeWidth={8}
                            trackStrokeWidth={8}
                            strokeColor={'#1A73E8'}
                            children={`Archivados ${storage ? storage.archived : 0} Bytes`}
                        />
                            <Typography style={label} >{`${archived}%`}</Typography>
                    </Grid>

                    <Grid style={progress}>
                        <ProgressBar
                            progress={paperBin}
                            radius={68}
                            strokeWidth={8}
                            trackStrokeWidth={8}
                            strokeColor={'#ff625c'}
                            children={`Papelera ${storage ? storage.paperBin : 0} Bytes`}
                        />
                        <Typography style={label} >{`${paperBin}%`}</Typography>
                    </Grid>

                    <Grid style={progress}>
                    <ProgressBar
                        progress={available}
                        radius={68}
                        strokeWidth={8}
                        trackStrokeWidth={8}
                        strokeColor={'#00C642'}
                        children={` Disponible ${storage ? storage.available : 0} Bytes`}
                    />
                        <Typography style={label} >{`${available}%`}</Typography>
                    </Grid>
                </Grid>
            </ModalBody>

            <ModalFooter className="btnCancel" 
                onConfirm={() => {}}
                cancelText={"Cerrar"}
                onCancel={handleClose}
            />

        </Modal>
    )
}

const progress ={
    position: 'relative',
    margin: '10px',
    border: 'solid 1px rgba(0, 0, 0, 0.54)',
    padding: '0',
    maxHeight: '215px',
    minHeight: '215px',
    textAlign: 'center',
  }
  
  const label = {
    marginTop: '-125px',
    fontSize: '20px',
    textAling: 'center',
  }

export default ModalStorage;