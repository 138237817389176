import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listImportantFolders } from 'services/actions/DFolderAction';
import { listImportantFiles } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFolders from 'components/Folder/CustomFolders';
import CustomFiles from 'components/File/CustomFiles';
import { updateFileList } from 'services/actions/CommonAction';
import { showBackdrop } from 'services/actions/CustomAction';
class ImportantPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dFolders: [],
      dFiles: [],
      showFilesMessage: true,
      filesMessage: "Cargando documentos...",
      showFoldersMessage: true,
      foldersMessage: "Cargando carpetas..."
    }
  }

  async componentDidMount() {
    this.getMyFolders("name");
    this.getMyFiles("name");
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.getMyFolders();
      this.getMyFiles();
      this.props.dispatch(updateFileList(false));
    }
  }

  getMyFolders = (sortBy = "name") => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listImportantFolders("", "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    })
  }

  getMyFiles = (sortBy = "name") => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listImportantFiles("", "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
  }

  render() {
    const { dFiles = [], dFolders = [], showFilesMessage, showFoldersMessage, filesMessage, foldersMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Importantes"
            getMyFiles={this.getMyFiles}
            getMyFolders={this.getMyFolders}
            defaultOrder={"name"}
          />

          {
            showFoldersMessage ? (
              <Typography variant='caption' style={{width:"inherit"}}>{foldersMessage}</Typography>
            ) : (
              <CustomFolders
                {...this.props}
                dFolders={dFolders}
                title="Carpetas"
                refreshList={this.getMyFolders}
              />
            )
          }
          <br></br>

          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <CustomFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
                refreshList={this.getMyFiles}
              />
            )
          }
        </Grid>

      </BasePage>
    );
  }
}

export default ImportantPage;