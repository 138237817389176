import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Select  from 'react-select';

const SortSelect = (props) => {  

  const { getMyFiles, getMyFolders, defaultOrder } = props;
  
  const handleChange = (option) => {
    getMyFiles && getMyFiles(option.value);
    getMyFolders && getMyFolders((option.value !== 'extension') && option.value);
  };

  const options = [
    { value: 'name', label: 'Nombre'},
    { value: 'updated_at', label: 'Fecha de Modificacon'},
    { value: 'extension', label:'Tipo de Archivo'}
  ];

  const order = (value) => {
    switch (value) {
      case "name":
        return options[0]
        break;
      case "updated_at":
        return options[1]
        break;
      case "extension":
        return options[2]
        break;
    }
    return "";
  };

  return (
    <Grid container style={{justifyContent:"end"}}>
      <Grid item xs={6}>
        <Select 
          defaultValue={order(defaultOrder)}
          onChange={handleChange} 
          options={options} 
          placeholder="Ordenar por..."
          isSearchable={false}
        />
      </Grid>
    </Grid>      
);
}

export default SortSelect;
