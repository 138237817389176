
import { Input, InputAdornment, Typography, Grid, IconButton, Button } from '@material-ui/core';
import CustomHeader from 'components/CustomHeader';
import 'assets/styles/docset-app.css';
import AddIcon from '@material-ui/icons/Add';
import BasePage from 'pages/BasePage';
import CustomTable from 'components/CustomTable'
import React, {Component} from 'react';

const columns = [
    { type: 'text', field: 'name', label: 'Nombre' },
    { type: 'text', field: 'rut', label: 'RUT' },
    { type: 'text', field: 'address', label: 'Dirección' },
    { type: 'text', field: 'adminName', label: 'Administrador' },
    { type: 'text', field: 'email', label: 'Correo' },
    
];

class DocumentGeneratePage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){
        return (
            <BasePage>
                <Grid conatiner className="design_body">
                    <Grid container item >
                        <Grid item xs={12} sm={10} md={7} style={{height:'70%'}}>
                            <div className="design_body_2">
                                <div className="add">
                                <Typography className="page_title" variant="h5" gutterBottom>
                                    <strong>Mis Plantillas</strong>
                                </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="designdiv2">
                        <CustomTable 
                            columns={columns} 
                            rows={[]}
                        />
                        </div>
                    </Grid>
                </Grid>
            </BasePage>
        )
    }
}

export default DocumentGeneratePage;