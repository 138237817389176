import React from 'react'
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { deleteUser } from 'services/actions/UserAction';
import { showBackdrop } from 'services/actions/CustomAction';

export const ModalConfirmDeleteUser = (props) => {
  const { open, closeModal, user, onListUser } = props;
  
  const onDeleted= async()=>{
    props.dispatch(showBackdrop(true));
    props.dispatch(deleteUser(user.id)).then(res => {
      onListUser && onListUser();
      closeModal();
      props.dispatch(showBackdrop(false));
    }).catch(err=>{
        this.props.dispatch(showBackdrop(false));
        console.error('log', err);
    })
  }
  
  return (
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR USUARIO"
        className='positionElements'
        onCancel={closeModal}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar este usuario?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
      />
    </Modal>
  )
}