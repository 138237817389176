import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "items";

class ItemService {

    async createItem(data){
        return await axios.post( getCustomUrl(apiPrefix, `/register`), data, defaultHeaders() );
    }

    async updateItem(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/update/${id}`), data, defaultHeaders() );
    }

    async findItem(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/find/${id}`), defaultHeaders() );
    }

    async listItem(term, idSection, idComponent) {
        return await axios.get( getCustomUrl(apiPrefix, `/list?term=${term}&idSection=${idSection}&idComponent=${idComponent}`), defaultHeaders() );
    }

    async deleteItem(id) {
        return await axios.delete( getCustomUrl(apiPrefix, `/delete/${id}`), defaultHeaders() );
    }
}

export default ItemService;