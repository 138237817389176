import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listRecentFiles } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import RecentFiles from 'components/File/RecentFiles';
import { showBackdrop } from 'services/actions/CustomAction';
import { updateFileList } from 'services/actions/CommonAction';

class RecentPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dFiles: [],
      showFilesMessage: true,
      filesMessage: "Cargando documentos..."
    }
  }

  async componentDidMount() {
    this.getRecentFiles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.getRecentFiles();
      this.props.dispatch(updateFileList(false));
    }
  }

  getRecentFiles = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listRecentFiles("", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
  }

  render() {
    const { dFiles = [], showFilesMessage, filesMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Recientes"
            getMyFiles={this.getRecentFiles}
            defaultOrder={"updated_at"}
          />
          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <RecentFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
              />
            )
          }
        </Grid>

      </BasePage>
    );
  }
}

export default RecentPage;