import UserService from "services/api/UserService";

const service = new UserService();

export const createUser = (data) => async dispatch => {
    const res = await service.createUser(data);
    return res && res.data || [];
}

export const updateUser = (id, data) => async dispatch => {
    const res = await service.updateUser(id, data);
    return res && res.data || [];
}

export const findUser = (id) => async dispatch => {
    const res = await service.findUser(id);
    return res && res.data || [];
}

export const listUsers = (term) => async dispatch => {
    const res = await service.listUsers(term);
    return res && res.data || [];
}

export const deleteUser = (id) => async dispatch => {
    const res = await service.deleteUser(id);
    return res && res.data || [];
}

export const getRoles = () => async dispatch => {
    const res = await service.getRoles();
    return res && res.data || [];
}

export const findUserByUserName = (username, rut) => async dispatch => {
    const res = await service.findUserByUserName(username, rut);
    return res && res.data || [];
}

export const recoverPassword = (id,email) => async dispatch => {
    const res = await service.recoverPassword(id,email);
    return res && res.data || [];
}

export const listUsersByCompany = (term) => async dispatch => {
    const res = await service.listUsersByCompany(term);
    return res && res.data || [];
}

export const listGeneralAdmin = (term) => async dispatch => {
    const res = await service.listGeneralAdmin(term);
    return res && res.data || [];
}

export const getStorage = (idCompany) => async dispatch => {
    const res = await service.getStorage(idCompany);
    return res && res.data || [];
}

export const updatePassword = (id, data) => async dispatch => {
    const res = await service.updatePassword(id, data);
    return res && res.data || [];
}

export const verifyUserRole = (term, idRole) => async dispatch => {
    const res = await service.verifyUserRole(term, idRole);
    return res && res.data || [];
}