import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listSharedFolderWithMe, findDFolder } from 'services/actions/DFolderAction';
import { listSharedFileWithMe } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFolders from 'components/Folder/CustomFolders';
import CustomFiles from 'components/File/CustomFiles';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { updateFileList } from 'services/actions/CommonAction';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

class SharedMePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dFolders: [],
      dFiles: [],
      breadCrumb: false,
      parentFolder: [],
      idFolder: null,
      showFilesMessage: true,
      filesMessage: "Cargando documentos...",
      showFoldersMessage: true,
      foldersMessage: "Cargando carpetas..."
    }
  }

  async componentDidMount() {
    this.validateOpenFolder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.validateOpenFolder();
      this.props.dispatch(updateFileList(false));
    }
  }

  getMyFoldersSharedWithMe = (sortBy) => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedFolderWithMe("", params.idFolder || "", sortBy || "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    }).catch(err => {
      if(err.response.data.error == "Ud. no tiene acceso a esta carpeta"){
        this.props.history.push('/compartidos-conmigo');
      }

      this.props.dispatch(showSnackBar("warning", err.response.data.error || "Ud. no tiene acceso a esta carpeta"));
      this.props.dispatch(showBackdrop(false));
    });
  }

  getMyFilesSharedWithMe = (sortBy) => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedFileWithMe("", params.idFolder || "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    }).catch(err => {
      if(err.response.data.error == "Ud. no tiene acceso a esta carpeta"){
        this.props.history.push('/compartidos-conmigo');
      }
      
      this.props.dispatch(showSnackBar("warning", err.response.data.error || "Ud. no tiene acceso a esta carpeta"));
      this.props.dispatch(showBackdrop(false));
    });
  }

  validateOpenFolder = () => {
    this.getMyFoldersSharedWithMe();
    this.getMyFilesSharedWithMe();
  }

  showBreadCrumb = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(findDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ parentFolder: res });
      })
    }
  }

  pathBreadCrumb = (folder) => {
    return `/compartidos-conmigo${folder ? ("/" + folder.id) : ""}`;
  }

  render() {
    const { dFiles = [], dFolders = [], showFilesMessage, showFoldersMessage, filesMessage, foldersMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Compartidos Conmigo"
            getMyFiles={this.getMyFilesSharedWithMe}
            getMyFolders={this.getMyFoldersSharedWithMe}
            defaultOrder={"updated_at"}
          />

          <Grid style={{ marginBottom: "15px" }}>
            <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
              <Link className='link' to={"/compartidos-conmigo"}>
                Inicio
              </Link>
              {
                (this.props.location.pathname != "/compartidos-conmigo") && (
                  <Link className='link' to={() => { this.pathBreadCrumb(this.state.parentFolder) }}>
                    {this.state.parentFolder.name}
                  </Link>
                )
              }
            </Breadcrumbs>
          </Grid>

          {
            showFoldersMessage ? (
              <Typography variant='caption' style={{width:"inherit"}}>{foldersMessage}</Typography>
            ) : (
              <CustomFolders
                {...this.props}
                dFolders={dFolders}
                title="Carpetas"
                refreshList={this.getMyFoldersSharedWithMe}
                showBreadCrumb={this.showBreadCrumb}
              />
            )
          }
                      
          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <CustomFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
              />
            )
          }
        </Grid>

      </BasePage>
    );
  }
}

export default SharedMePage;