import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "sections";

class SectionService {

    async createSection(data){
        return await axios.post( getCustomUrl(apiPrefix, `/register`), data, defaultHeaders() );
    }

    async updateSection(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/update/${id}`), data, defaultHeaders() );
    }

    async findSection(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/find/${id}`), defaultHeaders() );
    }

    async listSection(idTemplate, idSection) {
        return await axios.get( getCustomUrl(apiPrefix, `/list?idTemplate=${idTemplate}&idSection=${idSection}`), defaultHeaders() );
    }

    async deleteSection(id) {
        return await axios.delete( getCustomUrl(apiPrefix, `/delete/${id}`), defaultHeaders() );
    }
}

export default SectionService;