import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { ModalAddFolder } from 'components/Modals/ModalAddFolder';
import { listMyFolders, findDFolder, verifyFindDFolder } from 'services/actions/DFolderAction';
import { listMyFiles } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFolders from 'components/Folder/CustomFolders';
import CustomFiles from 'components/File/CustomFiles';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { updateFileList } from 'services/actions/CommonAction';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';


class MyFolderPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showFolderModal: false,
      dFolders: [],
      dFiles: [],
      currentPath: window.location.pathname,
      breadCrumb: false,
      parentFolder: [],
      idFolder: null,
      sortByFile: "name",
      sortByFolder: "name",
      showFilesMessage: true,
      filesMessage: "Cargando documentos...",
      showFoldersMessage: true,
      foldersMessage: "Cargando carpetas..."
    };
  }

  async componentDidMount() {
    this.validateOpenFolder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.validateOpenFolder();
      this.props.dispatch(updateFileList(false));
    }
  }

  getMyFolders = (sortBy) => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listMyFolders("", params.idFolder || "", sortBy || "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    })
    this.setState({ sortByFolder: sortBy });
  }

  getMyFiles = (sortBy) => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listMyFiles("", params.idFolder || "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
    this.setState({ sortByFile: sortBy });
  }

  openFolderModal = () => {
    this.setState({ showFolderModal: true })
  }

  closeFolderModal = (refresh) => {
    this.setState({ showFolderModal: false });
    if (refresh) {
      this.getMyFolders(this.state.sortByFolder);
    }
  }

  validateOpenFolder = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(verifyFindDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.getMyFolders(this.state.sortByFolder);
        this.getMyFiles(this.state.sortByFile);
        this.setState({ parentFolder: res });
      }).catch(err => {
        this.props.history.push('/mi-carpeta');
        this.props.dispatch(showSnackBar("warning", err.response.data.error || "Carpeta no encontrada"));
        this.props.dispatch(showBackdrop(false));
      });
    } else {
      this.getMyFolders(this.state.sortByFolder);
      this.getMyFiles(this.state.sortByFile);
    }
  }

  showBreadCrumb = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(findDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ parentFolder: res });
      })
    }
  }

  pathBreadCrumb = (folder) => {
    return `/mi-carpeta${folder ? ("/" + folder.id) : ""}`;
  }

  render() {
    const { dFiles = [], dFolders = [], showFolderModal, currentPath = "", sortByFile = "name", sortByFolder = "name", showFilesMessage, showFoldersMessage, filesMessage, foldersMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Mi Carpeta"
            addTooltipText="Crear carpeta"
            showAddIcon
            onAddClick={this.openFolderModal}
            getMyFiles={this.getMyFiles}
            getMyFolders={this.getMyFolders}
            defaultOrder={"name"}
          />

          <Grid container style={{ marginBottom: "15px" }}>
            <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
              <Link className='link' to={"/mi-carpeta"}>
                Inicio
              </Link>
              {
                (this.props.location.pathname != "/mi-carpeta") && (
                  <Link className='link' to={() => { this.pathBreadCrumb(this.state.parentFolder) }}>
                    {this.state.parentFolder.name}
                  </Link>
                )
              }
            </Breadcrumbs>
          </Grid>
          <Grid container>
            {
              showFoldersMessage ? (
                <Typography variant='caption'>{foldersMessage}</Typography>
              ) : (
                <CustomFolders
                  {...this.props}
                  dFolders={dFolders}
                  title="Carpetas"
                  refreshList={this.getMyFolders}
                  showBreadCrumb={this.showBreadCrumb}
                  order={sortByFolder}
                />
              )
            }
          </Grid>
          <Grid container>
            {
              showFilesMessage ? (
                <Typography variant='caption'>{filesMessage}</Typography>
              ) : (
                <CustomFiles
                  {...this.props}
                  dFiles={dFiles}
                  title="Archivos"
                  refreshList={this.getMyFiles}
                  order={sortByFile}
                />
              )
            }
          </Grid>
        </Grid>

        {
          showFolderModal && (
            <ModalAddFolder
              {...this.props}
              open={showFolderModal}
              currentPath={currentPath}
              closeModal={this.closeFolderModal}
            />
          )
        }
      </BasePage>
    );
  }
}

export default MyFolderPage;