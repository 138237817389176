import React, {useState, useEffect} from 'react'
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { TextField, Typography, Grid, Button, Avatar, Select, MenuItem, Divider, InputAdornment} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { createCompany, findCompany, updateCompany } from 'services/actions/CompanyAction';
import { getSessionInfo } from 'utils/common';
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOff';
import config from "config/Config";
import BusinessIcon from '@material-ui/icons/Business';

const cleanCompany = {
  id: "",
  name: "",
  address: "",
  adminName: "",
  rut: "",
  email: "",
  phone: "",
  storage: 0,
  usedStorage: 0,
  userStorage: 0,
  description: "",
  avatar: null
};

export const ModalCompany = (props) => {
  const { open, handleClose, editMode, isConfig, company, onConfirmCallBack } = props;
  const session = getSessionInfo();
  const user = session && session.user || {};
  const role = session && session.role || {};
  const companyId = session && session.user && session.user.idCompany || {};
  const isUserAdmin = role.name == "Admin";
  const Kb = Math.pow(10, 6);

  const [data, setData] = React.useState(cleanCompany);
  const [avatarImage, setAvatarImage] = React.useState(null);

  React.useEffect(()=>{
    if(open){
      if(company){
        props.dispatch(showBackdrop(true));
        props.dispatch(findCompany(company.id)).then(res => {
          props.dispatch(showBackdrop(false));
          setData({
            ...res,
            storage: (res.storage > 0 ? res.storage/Kb : 0),
            usedStorage: (res.usedStorage > 0 ? res.usedStorage/Kb : 0),
            userStorage: (res.userStorage > 0 ? res.userStorage/Kb : 0),
          });
        })
      }else{
        setData(cleanCompany);
      }
      setAvatarImage(null);
    }else{
      setData(cleanCompany);
    }
  },[open])

  const validateForm = (values) => {
      let errors = {};
      const formats = {
        name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        phone: /^\d{7,14}$/,
        storage: /^\d{0,14}$/,
        userStorage: /^\d{0,14}$/
      };

      if(!values.name) errors.name = "Nombre requerido";
      if(!values.adminName) errors.adminName = "Administrador requerido";
      if(values.adminName && !formats.name.test(values.adminName)) errors.adminName = "Nombre de Administrador inválido";
      if(!values.rut) errors.rut = "RUT requerido";
      if(!values.email) errors.email = "Correo requerido";
      if(values.email && !formats.email.test(values.email)) errors.email = "Correo inválido";
      if(!values.phone) errors.phone = "N° Celular requerido";
      if(values.phone && !formats.phone.test(values.phone)) errors.phone = "N° Celular inválido";
      if(!values.storage) errors.storage = "Almacenamiento de la empresa requerido";
      if(values.storage && !formats.storage.test(values.storage)) errors.storage = "Almacenamiento de la empresa inválido";
      if(!isConfig){
        if(!values.userStorage) errors.userStorage = "Almacenamiento por usuario requerido";
        if(values.userStorage && !formats.userStorage.test(values.userStorage)) errors.userStorage = "Almacenamiento por usuario inválido";
      }
      return errors;
  };

  const processImage = (event) => {
    if(event && event.target.files && event.target.files.length > 0){
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setAvatarImage(imageUrl)
    }else{
        setAvatarImage(null)
    }
 }

  const deleteImage = (values) => {
    setAvatarImage(null);  
    setData({...values, avatar:null});
    document.getElementById('image').value = "";
  }

  const onSubmit = (values, {resetForm} ) => {
    const fileInput = document.querySelector('#image') ;
    const formData = new FormData();
    if(!isConfig) formData.append('image', fileInput.files[0]);
    if(values.avatar && !isConfig) formData.append('avatar', values.avatar);
    formData.append('name', values.name);
    formData.append('adminName', values.adminName);
    formData.append('rut', values.rut);
    formData.append('email', values.email);
    formData.append('address', values.address || "");
    formData.append('description', values.description);
    formData.append('phone', values.phone);
    formData.append('storage', values.storage * Kb);
    formData.append('usedStorage', values.usedStorage);
    formData.append('userStorage', values.userStorage * Kb);

    props.dispatch(showBackdrop(true));
    if(parseInt(values.userStorage) > parseInt(values.storage)){
      props.dispatch(showSnackBar("warning", "El almacenamiento de usuario no debe ser mayor al de la Empresa"));
      props.dispatch(showBackdrop(false));
      return;
    }
    if(values.id){
        // Editar
        props.dispatch(updateCompany(values.id, formData)).then(res => {
          props.dispatch(showBackdrop(false));
          props.onConfirmCallBack && props.onConfirmCallBack();
        }).catch(err => {
          props.dispatch(showSnackBar('error', err.response.data.email ? err.response.data.email : err.response.data.error));
          props.dispatch(showBackdrop(false));
        });
    }else{
        // Agregar
        props.dispatch(createCompany(formData)).then(res => {
            props.dispatch(showBackdrop(false));
            props.onConfirmCallBack && props.onConfirmCallBack();
        }).catch(err => {
            props.dispatch(showSnackBar('error', err.response.data.email ? err.response.data.email : err.response.data.error));
            props.dispatch(showBackdrop(false));
            console.log('error', err);
        });
    }
  }

  return (
    <Modal open={open} handleClose={handleClose} handle size="sm">
      <ModalHeader
        icon={<BusinessIcon />}
        text={company && company.id ? (editMode ? "Editar" : "Detalles") : "Agregar Empresa"}
        className='positionElements'
        onCancel={handleClose}
      >
      </ModalHeader>

      <ModalBody>
        <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems="center" justify="center">

                  {
                    !isConfig && (
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="name"
                            type="text" 
                            name="name"
                            fullWidth
                            value={values.name}
                            onChange={handleChange}
                            placeholder="Nombre de la Empresa"
                            error={errors.name && touched.name ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  {
                    !isConfig && (
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>RUT:</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="rut"
                            type="text" 
                            name="rut"
                            fullWidth
                            value={values.rut}
                            onChange={handleChange}
                            placeholder="RUT"
                            error={errors.rut && touched.rut ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Administrador:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="adminName"
                        type="text" 
                        name="adminName"
                        fullWidth
                        value={values.adminName}
                        onChange={handleChange}
                        placeholder="Nombre del administrador"
                        error={errors.adminName && touched.adminName ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Dirección:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="address"
                        type="text" 
                        name="address"
                        fullWidth
                        value={values.address}
                        onChange={handleChange}
                        placeholder="Dirección"
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Correo:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="email"
                        type="text" 
                        name="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Correo electrónico"
                        error={errors.email && touched.email ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>N° Teléfono:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="phone"
                        type="text" 
                        name="phone"
                        fullWidth
                        value={values.phone}
                        onChange={handleChange}
                        placeholder="Número de teléfono"
                        error={errors.phone && touched.phone ? true : false}
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  {
                    !isConfig && (
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Almacenamiento de la Empresa (Mb):</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="storage"
                            type="text" 
                            name="storage"
                            fullWidth
                            value={values.storage}
                            onChange={handleChange}
                            placeholder="Almacenamiento de la empresa (Mb)"
                            error={errors.storage && touched.storage ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Espacio usado:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="storage"
                        type="text" 
                        name="storage"
                        fullWidth
                        value={values.usedStorage || 0}
                        onChange={handleChange}
                        placeholder="Espacio usado (Bytes)"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  {
                    !isConfig && (
                      <Grid item xs={12} container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1" className="custom-input"><b>Almacenamiento por usuario (Mb):</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="userStorage"
                            type="text" 
                            name="userStorage"
                            fullWidth
                            value={values.userStorage}
                            onChange={handleChange}
                            placeholder="Almacenamiento por usuario (Mb)"
                            error={errors.userStorage && touched.userStorage ? true : false}
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <Grid item xs={12} container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Descripción:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="description"
                        onChange={handleChange}
                        value={values.description || ""}
                        error={ errors.description && touched.description ? true : false }
                        multiline
                        rows={4}
                        InputProps={{
                          startAdornment: props.icon && ( <InputAdornment position="start"> { props.icon } </InputAdornment> ),
                        }}
                        fullWidth
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>

                  {
                      editMode && !isConfig && (
                        <Grid item xs={12}>
                          <p style={{margin:"0px"}}> <b>Avatar</b> </p>
                          <Button
                            variant="contained"
                            component="label"
                            fullWidth
                          >
                            <GetAppIcon style={{marginRight: "12px"}} />
                            <input id="image" accept="image/*" type="file" onChange={processImage} />
                          </Button>
                        </Grid>
                      )
                  }

                  {
                    !isConfig && (
                      (avatarImage || (values.id && values.avatar)) && (
                        <Grid container item xs={12} justify = "center" >
                          <Avatar 
                            style={{ height:140, width:140, display:"flex" }} 
                            src={avatarImage ? avatarImage : (config.api + values.avatar)}
                          />
                          {
                            editMode && values.avatar && (
                              <HighlightOffTwoToneIcon 
                                style={{ color: 'red', display: values.id ? "flex" : "none" }} 
                                onClick={() => { deleteImage(values) }}
                              />
                            )
                          }
                        </Grid>
                      )
                    )
                  }
                </Grid>
                
                <Divider className="custom-divider"/>

                {
                  ( !company || editMode ) && (
                    <ModalFooter 
                      buttonType="submit"
                      confirmText={company ? "ACTUALIZAR" : "REGISTRAR"}
                      cancelText="CANCELAR" 
                      onCancel={handleClose}
                      onConfirm={handleSubmit}
                    />
                  )
                }                
                
              </Form>
            )
          }}

        </Formik>
      </ModalBody>

      
    </Modal>
  )
}