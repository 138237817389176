import React from 'react'
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { showBackdrop } from 'services/actions/CustomAction';
import { deleteCompany } from 'services/actions/CompanyAction';

export const ModalConfirmDeleteCompany = (props) => {
  const { open, closeModal, company, onListCompany } = props;
  
  const onDeleted= async()=>{
    props.dispatch(showBackdrop(true));
    props.dispatch(deleteCompany(company.id)).then(res => {
        onListCompany && onListCompany();
      closeModal();
      props.dispatch(showBackdrop(false));
    }).catch(err=>{
        this.props.dispatch(showBackdrop(false));
        console.error('log', err);
    })
  }
  
  return (
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR EMPRESA"
        className='positionElements'
        onCancel={closeModal}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar esta empresa?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
      />
    </Modal>
  )
}