//AUTH
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//SHOP COMMONS
export const GET_INVENTORIES = 'GET_INVENTORIES';
export const UPDATE_CURRENT_SHOP = 'UPDATE_CURRENT_SHOP';
export const UPDATE_LAST_ROUTE = 'UPDATE_LAST_ROUTE';

//SNACKBARS
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

//BACKDROPS
export const SHOW_BACKDROP = 'SHOW_BACKDROP';
export const HIDE_BACKDROP = 'HIDE_BACKDROP';

//UPDATELIST
export const ACTIVE_UPDATE_FILE_LIST = 'ACTIVE_UPDATE_FILE_LIST';
export const DISABLE_UPDATE_FILE_LIST = 'DISABLE_UPDATE_FILE_LIST';