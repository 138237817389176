import React from 'react'
import { Avatar, Typography } from "@material-ui/core";
import perfil from 'assets/images/perfil.png'
import ModalBody from './common/ModalBody';
import Modal from './common/Modal'
import { getSessionInfo } from 'utils/common';
import config from "config/Config";

export const ModalPerfil = (props) => {
    const { open, handleClose } = props;
    
    const session = getSessionInfo() || {};
    const user = session.user || {};
    const userName = user ? (user.firstName + " "+ user.lastName) : "";
    const email = user.email || "";
    const avatarImage = user.avatar || "";

    return (
            <Modal
             open={open}
             handleClose={handleClose}
             size="sm"
             className={'modalPerfil'}
            >
                <ModalBody>
                    <div className="avatar">
                        <div className="avatar-img">
                            <img src={avatarImage && config.api+avatarImage || perfil} alt="#" />
                        </div>
                    </div>
                    <Typography variant='h5'align='center' className='nameUser'><strong>{userName}</strong></Typography>
                    <Typography variant='h6' align='center' className='emailUser'>{email}</Typography>
                    </ModalBody>
        </Modal>
    )
}
