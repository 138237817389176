import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ThemeSettings from '../components/ThemeSettings/ThemeSettings';
import PrivateRoute from './PrivateRoute';
import LoginPage from 'pages/login_page/LoginPage';
import MyFolderPage from 'pages/folder_page/MyFolderPage';
import SharedPage from 'pages/shared_page/SharedPage';
import SharedMePage from 'pages/sharedMe_page/SharedMePage';
import SharedCompanyPage from 'pages/SharedCompanyPage/SharedCompanyPage';
import ImportantPage from 'pages/important_page/ImportantPage';
import RecentPage from 'pages/recent_page/RecentPage';
import ArchivedPage from 'pages/archived_page/ArchivedPage';
import PaperBinPage from 'pages/paperBin_page/PaperBinPage';
import UserPage from 'pages/user_page/UserPage';
import SettingPage from 'pages/setting_page/SettingPage';
import CompanyPage from 'pages/company_page/CompanyPage';
import PageNotFound from 'pages/PageNotFound';
import GenerateDocumentPage from 'pages/generateDocument_page/GenerateDocumentPage';
import DocumentGeneratePage from 'pages/documentGenerate_page/DocumentGeneratePage'

const Routes = (props) => {
  return(  
    <ThemeSettings>
      <Switch style={{height:"100%"}}>
        <Route exact path="/" render={(props) => ( <LoginPage {...props} routePush={"/mi-carpeta"} /> )}/>
        <Route exact path="/login" render={(props) => ( <LoginPage {...props} routePush={"/mi-carpeta"} /> )}/>

        <PrivateRoute exact path="/mi-carpeta" {...props} component={MyFolderPage}/>
        <PrivateRoute exact path="/mi-carpeta/:idFolder" {...props} component={MyFolderPage}/>
        
        <PrivateRoute exact path="/mis-compartidos" {...props} component={SharedPage}/>

        <PrivateRoute exact path="/compartidos-conmigo" {...props} component={SharedMePage}/>
        <PrivateRoute exact path="/compartidos-conmigo/:idFolder" {...props} component={SharedMePage}/>

        <PrivateRoute exact path="/compartidos-empresa" {...props} component={SharedCompanyPage}/>
        <PrivateRoute exact path="/compartidos-empresa/:idFolder" {...props} component={SharedCompanyPage}/>

        <PrivateRoute exact path="/importantes" {...props} component={ImportantPage}/>

        <PrivateRoute exact path="/recientes" {...props} component={RecentPage}/>

        <PrivateRoute exact path="/archivados" {...props} component={ArchivedPage}/>

        <PrivateRoute exact path="/documentos-generados" {...props} component={DocumentGeneratePage}/>

        <PrivateRoute exact path="/generar-documento" {...props} component={GenerateDocumentPage}/>
        <PrivateRoute exact path="/generar-documento/:idTemplate" {...props} component={GenerateDocumentPage}/>

        <PrivateRoute exact path="/papelera" {...props} component={PaperBinPage}/>

        <PrivateRoute exact path="/usuarios" {...props} component={UserPage}/>

        <PrivateRoute exact path="/configuracion-de-la-empresa" {...props} component={SettingPage}/>

        <PrivateRoute exact path="/empresas" {...props} component={CompanyPage}/>

        <Route path="/no-encontrado" component={PageNotFound}/>
        <Redirect from="*" to="/no-encontrado" />
        
      </Switch>
    </ThemeSettings>
  )
};

export default Routes;
