import {Grid, makeStyles, TextField, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/Folder';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { createDFolder } from 'services/actions/DFolderAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Form } from 'semantic-ui-react';
import { listGeneralAdmin, listUsersByCompany } from 'services/actions/UserAction';
import ShareIcon from '@material-ui/icons/Share';
import { shareDFolder } from 'services/actions/DFolderAction';
import { Formik } from 'formik';
import { getSessionInfo } from 'utils/common';
import {Autocomplete} from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
  fullInputText: {
      margin: theme.spacing(1),
      width: "100%"
  }
}));

export const ModalShareFolder = props => {
  const { open, handleClose, handleConfirmClose, dFolder } = props;
  const classes = useStyles();

  const [data, setData] = React.useState({    
    shared: false,
    accessType: "Publico",
    sharedWith: [],
    sharedPermission: "Lector",
    sharedCompany: false,
    status: false,
  });

  const [users, setUsers] = React.useState([]);
  const [userSearch, setUserSearch] = React.useState("");

  const session = getSessionInfo();
  const adminRole = session && session.role || {};
  const user = session && session.user;
  const isUserAdminCompany = adminRole.name == 'AdminEmpresa';
  const isUserAdminGeneral = adminRole.name == 'Admin';

  const currentIdCompany = session && session.user && session && session.user.idCompany || "";

  React.useEffect(() => {

    if(open && dFolder){
      if(isUserAdminGeneral){
        props.dispatch(listGeneralAdmin("")).then(res => {
          setUsers(res || []);
  
          setData({
            shared: !!dFolder.shared || false,
            accessType: dFolder.accessType || "Publico",
            sharedPermission: dFolder.sharedPermission || "Lector",
            sharedWith: setCustomUser(dFolder.sharedWith, res) || [],
            sharedCompany:  dFolder.sharedCompany || false,
            status: dFolder.status ||false,
          });
        
        }).catch(err => showError(err));
      }else{
        props.dispatch(listUsersByCompany("", currentIdCompany)).then(res => {
          setUsers(res || []);
  
          setData({
            shared: !!dFolder.shared || false,
            accessType: dFolder.accessType || "Publico",
            sharedPermission: dFolder.sharedPermission || "Lector",
            sharedWith: setCustomUser(dFolder.sharedWith, res) || [],
            sharedCompany:  dFolder.sharedCompany || false,
            status: dFolder.status ||false,
          });
        
        }).catch(err => showError(err));
      }

    } else{
      setData({
        shared: false,
        accessType: "Publico",
        sharedWith: [],
        sharedPermission: "Lector",
        sharedCompany: false,
        status: false,
      });
    }

  }, [open]);

  const showError = (err) => {
    props.dispatch(showSnackBar('error', ""));
    props.dispatch(showBackdrop(false));
    console.log(err);
  }

  const setCustomUser = (findUsers, response) => {
    const data = findUsers ? findUsers.map( user => response.find(value => value.id == user)) : [];
    return getCustomUsers(data);
  }

  const getCustomUsers = (originalUsers) => {
    return originalUsers ? originalUsers.map(user => ({id: user.id, name: (user.firstName + ' '+ user.lastName)})) : [];
  }

  const customUsers = getCustomUsers(users || []);

  const onSubmit = (values) => {

    const formData = new FormData();
    const message = values.shared ? "Carpeta Compartida" : "Carpeta No Compartida";

    formData.append("shared", values.shared);
    formData.append("accessType", values.shared ? values.accessType : "");
    if(values.shared && values.accessType == "Privado"){
      for (var i = 0; i < values.sharedWith.length; i++) {
        formData.append('sharedWith[]', values.sharedWith[i].id);
      }
      formData.append('sharedPermission', values.sharedPermission);
    }
    formData.append("sharedCompany", values.sharedCompany);
    if(values.sharedCompany) formData.append("status", true);

    props.dispatch(showBackdrop(true));
    props.dispatch(shareDFolder(dFolder.id, formData)).then(res => {
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
        props.dispatch(showSnackBar("success", message));
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo compartir la carpeta"));
        props.dispatch(showBackdrop(false));
      });
  }

  return (
    <Modal open={open} handleClose={handleClose} size="sm">
      <ModalHeader
        icon={<ShareIcon />}
        text="Compartir"
        className="positionElements"
        onCancel={handleClose}
      ></ModalHeader>
      
      <ModalBody>

        <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
              return(
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} container spacing={3}>
                      <Grid item xs={5} className="item-center">
                        <Typography variant="body1">Compartir: </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          checked={values.shared}
                          onChange={() => {setFieldValue("shared", !values.shared)}}
                        />
                      </Grid>
                    </Grid>
                    { 
                      values.shared && (
                        <>
                        {
                          isUserAdminCompany && (
                            <Grid item xs={12} container spacing={3}>
                              <Grid item xs={5} className="item-center">
                                <Typography variant="body1">Compartir con la empresa: </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                  checked={values.sharedCompany}
                                  onChange={() => {setFieldValue("sharedCompany", !values.sharedCompany)}}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                        <Grid item xs={12} container spacing={3}>
                          <Grid item xs={5}>
                            <Typography variant="body1">Tipo de Acceso: </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Select
                              id="accessType"
                              value={values.accessType}
                              onChange={(event) => {setFieldValue("accessType", event.target.value)}}
                              fullWidth
                            >
                              <MenuItem value="Privado">Privado</MenuItem>
                              <MenuItem value="Publico">Público</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                        </>
                      )
                    }
                    {
                      values.shared && values.accessType == "Privado" && (
                        <Grid item xs={12} container spacing={3}>
                          <Grid item xs={5}>
                            <Typography variant="body1">Compartir con: </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Autocomplete
                                id="sharedWith"
                                multiple
                                value={values.sharedWith || []}
                                onChange={(event, newValues) => {
                                  setFieldValue('sharedWith',newValues);
                                }}
                                options={customUsers || []}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionDisabled={(option) => option.id == user.id}
                                inputValue={userSearch}
                                onInputChange={(event, newInputValue) => {
                                  setUserSearch(newInputValue);
                                }}
                                filterSelectedOptions
                                renderInput={params => (
                                    <TextField {...params} variant="standard" fullWidth />
                                )}
                              />
                          </Grid>

                          <Grid item xs={5}>
                            <Typography variant="body1">Permiso: </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Select
                              id="sharedPermission"
                              value={values.sharedPermission}
                              onChange={(event) => { setFieldValue('sharedPermission',event.target.value) }}
                              fullWidth
                            >
                              <MenuItem value="Lector">Lector</MenuItem>
                              <MenuItem value="Editor">Editor</MenuItem>
                            </Select>
                          </Grid>

                        </Grid>
                      )
                    }
                  </Grid>

                  
                  <ModalFooter 
                    confirmText="Compartir"
                    cancelText="Cancelar"
                    onCancel={handleClose}
                    className="footerRight"
                    onConfirm={handleSubmit}
                  />

                </form> 
              )    
          }}
        </Formik>
        
      </ModalBody>
    </Modal>
  );
};
