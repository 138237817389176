import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listSharedFolders,findDFolder, verifyFindDFolder } from 'services/actions/DFolderAction';
import { listSharedFiles } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFolders from 'components/Folder/CustomFolders';
import CustomFiles from 'components/File/CustomFiles';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { updateFileList } from 'services/actions/CommonAction';

class SharedPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dFolders: [],
      dFiles: [],
      showFilesMessage: true,
      filesMessage: "Cargando documentos...",
      showFoldersMessage: true,
      foldersMessage: "Cargando carpetas...",
      breadCrumb: false,
      parentFolder: [],
      sortByFile: "name",
      sortByFolder: "name",
    }
  }

  async componentDidMount() {
    this.validateOpenFolder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.validateOpenFolder();
      this.props.dispatch(updateFileList(false));
    }
  }

  getMyFolders = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedFolders("", "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    })
    this.setState({ sortByFolder: sortBy });
  }

  getMyFiles = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedFiles("", "", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
    this.setState({ sortByFile: sortBy });

  }

  validateOpenFolder = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(verifyFindDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.getMyFolders(this.state.sortByFolder);
        this.getMyFiles(this.state.sortByFile);
        this.setState({ parentFolder: res });
      }).catch(err => {
        this.props.history.push('/mis-compartidos');
        this.props.dispatch(showSnackBar("warning", err.response.data.error || "Carpeta no encontrada"));
        this.props.dispatch(showBackdrop(false));
      });
    } else {
      this.getMyFolders(this.state.sortByFolder);
      this.getMyFiles(this.state.sortByFile);
    }
  }

  showBreadCrumb = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(findDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ parentFolder: res });
      })
    }
  }

  render() {
    const { dFiles=[], dFolders=[], sortByFile = "name", sortByFolder = "name", showFilesMessage, showFoldersMessage, filesMessage, foldersMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Mis Compartidos"
            getMyFiles={this.getMyFiles}
            getMyFolders={this.getMyFolders}
            defaultOrder={"updated_at"}
            note="Los documentos archivados o en papelera no aparecerán para los demás usuarios"
          />

          {
            showFoldersMessage ? (
              <Typography variant='caption' style={{width:"inherit"}}>{foldersMessage}</Typography>
            ) : (
              <CustomFolders
                {...this.props}
                dFolders={dFolders}
                title="Carpetas"
                refreshList={this.getMyFolders}
                showBreadCrumb={this.showBreadCrumb}
                order={sortByFolder}
              />
            )
          }

          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <CustomFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
                refreshList={this.getMyFiles}
                order={sortByFile}
              />
            )
          }
        </Grid>

      </BasePage>
    );
  }
}

export default SharedPage;