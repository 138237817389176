import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listSharedCompanyFolders, sharedFoldersList, verifyFindSharedCompany, findDFolder } from 'services/actions/DFolderAction';
import { listSharedCompanyFiles, sharedFilesList } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFolders from 'components/Folder/CustomFolders';
import CustomFiles from 'components/File/CustomFiles';
import { getIdCompanySession, getSessionInfo } from 'utils/common';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { updateFileList } from 'services/actions/CommonAction';
import { ModalAddFolder } from 'components/Modals/ModalAddFolder';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

class SharedCompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dFolders: [],
      dFiles: [],
      currentPath: window.location.pathname,
      showFolderModal: false,
      pageSharedCompany: false,
      breadCrumb: false,
      parentFolder: [],
      idFolder: null,
      showFilesMessage: true,
      filesMessage: "Cargando documentos...",
      showFoldersMessage: true,
      foldersMessage: "Cargando carpetas..."
    }
  }

  async componentDidMount() {
    this.validateOpenFolder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.reload || (prevProps.location.pathname !== this.props.location.pathname)) {
      this.validateOpenFolder();
      this.props.dispatch(updateFileList(false));
    }
  }

  getSharedCompanyFolders = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedCompanyFolders("", getIdCompanySession(), "", sortBy || "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    })
  }

  getSharedCompanyFiles = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listSharedCompanyFiles("", getIdCompanySession(), "", sortBy || "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
  }

  getMyFolders = () => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(sharedFoldersList("", params.idFolder || "", "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({
        dFolders: res || [],
        showFoldersMessage: res.length <= 0,
        foldersMessage: "No hay carpetas."
      });
    })
  }

  getMyFiles = () => {
    const { computedMatch = {} } = this.props;
    const { params = {} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(sharedFilesList("", params.idFolder || "", "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
    })
  }

  openFolderModal = () => {
    this.setState({ showFolderModal: true, pageSharedCompany: true });
  }

  closeFolderModal = (refresh) => {
    this.setState({ showFolderModal: false, pageSharedCompany: false });
    if (refresh) {
      this.getMyFolders();
    }
  }

  validateOpenFolder = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(verifyFindSharedCompany(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.setState({
          parentFolder: res,
          dFolders: []
        });
        //this.getMyFolders();
        this.getMyFiles();
      }).catch(err => {
        this.props.history.push('/compartidos-empresa');
        this.props.dispatch(showSnackBar("warning", err.response.data.error || "Ud. no tiene acceso a esta carpeta"));
        this.props.dispatch(showBackdrop(false));
      });
    } else {
      this.getSharedCompanyFolders();
      this.getSharedCompanyFiles();
    }
  }

  showBreadCrumb = () => {
    const path = this.props.location.pathname;
    const split = path.split('/');
    const id = split && split[2];
    if (id) {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(findDFolder(id)).then((res) => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ parentFolder: res });
      })
    }
  }

  pathBreadCrumb = (folder) => {
    return `/compartidos-empresa${folder ? ("/" + folder.id) : ""}`;
  }

  render() {
    const { dFiles = [], dFolders = [], showFolderModal, currentPath = "", pageSharedCompany, showFilesMessage, showFoldersMessage, filesMessage, foldersMessage } = this.state;

    const session = getSessionInfo();
    const role = session && session.role || {};
    const isUserAdminCompany = role.name == "AdminEmpresa";

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Compartidos por la empresa"
            getMyFiles={this.getSharedCompanyFiles}
            getMyFolders={this.getSharedCompanyFolders}
            defaultOrder={"updated_at"}
          />

          <Grid style={{ marginBottom: "15px" }}>
            <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
              <Link className='link' to={"/compartidos-empresa"}>
                Inicio
              </Link>
              {
                (this.props.location.pathname != "/compartidos-empresa") && (
                  <Link className='link' to={() => { this.pathBreadCrumb(this.state.parentFolder) }}>
                    {this.state.parentFolder.name}
                  </Link>
                )
              }
            </Breadcrumbs>
          </Grid>

          {
            showFoldersMessage ? (
              <Typography variant='caption' style={{width:"inherit"}}>{foldersMessage}</Typography>
            ) :
            <CustomFolders
              {...this.props}
              dFolders={dFolders}
              title="Carpetas"
            />
          }
          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <CustomFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
              />
            )
          }
        </Grid>

        {
          showFolderModal && (
            <ModalAddFolder
              {...this.props}
              open={showFolderModal}
              currentPath={currentPath}
              closeModal={this.closeFolderModal}
              pageSharedCompany={pageSharedCompany}
            />
          )
        }

      </BasePage>
    );
  }
}

export default SharedCompanyPage;