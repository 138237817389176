import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import DocsetTitle from 'assets/images/DocsetTitle.png';
import { pColor } from 'assets/styles/docset-css';
import  {loginUser, loginErp}  from 'services/actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { getSessionInfo } from 'utils/common';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ModalSendPassword from 'components/Modals/ModalSendPassword';
import { findUserByUserName } from 'services/actions/UserAction';
import * as qs from 'query-string';
import 'assets/styles/docset-app.scss';

const useStyles = makeStyles((theme) => ({
  loginTitle: {
    color: pColor,
    fontWeight: "bold",
    fontSize: '4rem'
  },
  root: {
    marginBottom: theme.spacing(1)
  },
  image:{
    width: '500px'
  },
  loginBtn: {
    padding: "20px 60px",
    [theme.breakpoints.down('xs')]: {
      width: '65vw',
    },
  }
}));

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      // borderBottomColor: pColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: pColor,
      },
    },
  },
})(TextField);


const LoginPage = props => {
  const classes = useStyles();
  //const history = useHistory();

/* ==========LOGIN================== */
  const { rut_empresa = '', usuario="", password="" } = props.location && qs.parse(props.location.search);

  const [loginUsername, setLoginUsername] = React.useState("");
  const [loginRut, setLoginRut] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginPassword, setLoginPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [showModalSendPassword, setModalSendShowPassword] = React.useState(false);
  const [userData, setUserData] = React.useState({});

  const session = getSessionInfo();

  useEffect(()=>{
    
    if (usuario && password) {
      var decodeRutEmpresa;
      var decodeUser;
      var decodePassword;

      try {
        decodeRutEmpresa = atob(rut_empresa);
        decodeUser = atob(usuario);
        decodePassword = atob(password);

        const body = {
          rut: decodeRutEmpresa,
          username: decodeUser,
          password: decodePassword
        };
        
        props.dispatch(loginErp(body)).then(
          (res) => {
            props.history.push("/mi-carpeta");
            props.dispatch(showBackdrop(false));
          },
          (error) => {
           props.dispatch(showSnackBar("warning", error.response.data.error || ""));
           props.dispatch(showBackdrop(false));
          }
        ); 

      } catch (error){
        props.history.push("/");
      }

    }else {
      if(session && session.token){
        props.history.push("/mi-carpeta");
      }else{
        props.history.push("/");
      }
    }
  }, []);

  const handleClose = () => {
    setModalSendShowPassword(!showModalSendPassword)
  }

  const sendPassword = () => {
    setErrors("");
    props.dispatch(findUserByUserName(loginUsername, loginRut)).then(
      (res) => {
        setUserData(res)
        setModalSendShowPassword(!showModalSendPassword)
      },
      (error) => {
        props.dispatch(showSnackBar("warning", "Usuario no encontrado." || ""));
        props.dispatch(showBackdrop(false));
      }
    );
  }

  const handleValidation =()=>{
    let errors = {};
    let formIsValid = true;

    //Password
    if(!loginPassword){
       formIsValid = false;
       errors["password"] = "Contraseña requerida";
    }

    if(!loginUsername){
       formIsValid = false;
       errors["username"] = "Usuario requerido";
    }

   setErrors(errors);
   return formIsValid;
}

  const onChangePassword = (e) => {
    const password = e.target.value;
    setLoginPassword(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const body = {
      username: loginUsername,
      password: loginPassword,
      rut: loginRut
    }

    if(handleValidation()){
      props.dispatch(showBackdrop(true));
      props.dispatch(loginUser(body)).then(
       (res) => {
        const user = res.user || {};
        props.history.push("/mi-carpeta");
        props.dispatch(showBackdrop(false));
       },
       (error) => {
        props.dispatch(showSnackBar("warning", error.response.data.error || ""));
        props.dispatch(showBackdrop(false));
       }
     ); 
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container className="all-heigth custom-login">
        <Grid className="md-show" item md={2}/>
        <Grid item xs={12} md={4} className="login-logo">
          <img src={DocsetTitle} className={classes.image}/>         
          <p className="login-text" style={{ fontSize:"26px", fontWeight:"bolder"}}>
            La administración y Gestión de tu Documentación en un solo lugar 100% Web
          </p>
        </Grid>
        <Grid className="md-show" item md={1}/>
        <Grid container item xs={12} md={3} style={{textAlign:"center", justifyContent:"center", alignItems:"center"}}>
            <Paper elevation={3} className={classes.rootPaper}>       
              <form style={{padding: "20px"}}>         
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CssTextField
                      variant="outlined"
                      fullWidth
                      id="username"
                      placeholder="Usuario"
                      name="username"
                      autoComplete="username"
                      autoFocus
                      onChange={(event) => { setLoginUsername(event.target.value); }}
                      value={loginUsername}
                      onKeyPress={event => { event.key === 'Enter' && handleLogin(event) }}
                      label="Nombre de Usuario"
                    />
                    <span style={{color: "red"}}>{errors["username"]}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField
                      variant="outlined"
                      fullWidth
                      id="rut"
                      placeholder="RUT de la empresa"
                      name="rut"
                      autoComplete="rut"
                      onChange={(event) => { setLoginRut(event.target.value) }}
                      value={loginRut}
                      onKeyPress={event => { event.key === 'Enter' && handleLogin(event) }}
                      label="RUT de la empresa"
                    />
                  </Grid>
                  <Grid item xs={12} className={'input-password-content'}>
                    <CssTextField
                      variant="outlined"
                      fullWidth
                      name="password"
                      placeholder="Contraseña"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => { setShowPassword(!showPassword) }}
                              edge="end"
                              style={{color: pColor}}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      autoComplete="current-password"
                      value={loginPassword}
                      onChange={onChangePassword}
                      onKeyPress={event => { event.key === 'Enter' && handleLogin(event) }}
                      label="Contraseña"
                    />
                    <span style={{color: "red"}}>{errors["password"]}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span onClick={() => {sendPassword()}} style={{textAlign:"center", fontSize:"16px", color: pColor, cursor:"pointer"}}>¿Has olvidado la contraseña?</span>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.loginBtn + " custom-button"}
                      onClick={handleLogin}
                    >
                      Iniciar Sesión
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid className="md-show" item md={2}/>
          <ModalSendPassword
            {...props}
            open={showModalSendPassword}
            handleClose={handleClose}
            userData={userData}
          />
      </Grid>
    </>
  );
}

//export default LoginPage;
const mapStateToProps = (state) => ({ ...state })

export default connect(mapStateToProps)(withRouter(LoginPage));