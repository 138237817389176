import DFileService from "services/api/DFileService";


const service = new DFileService();

export const createDFile = (data) => async dispatch => {
    const res = await service.createFile(data);
    return res && res.data || [];
}

export const listMyFiles = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listMyFiles(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listSharedFiles= (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedFiles(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listImportantFiles = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listImportantFiles(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listSharedCompanyFiles = (term, idCompany, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedCompanyFiles(term, idCompany, idFolder, sortBy);
    return res && res.data || [];
}

export const listArchivedFiles = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listArchivedFiles(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listPaperBinFiles = (term, sortBy) => async dispatch => {
    const res = await service.listPaperBinFiles(term, sortBy);
    return res && res.data || [];
}

export const findDFile = (id) => async dispatch => {
    const res = await service.findFile(id);
    return res && res.data || [];
}

export const deleteDFile = (id) => async dispatch => {
    const res = await service.deleteDFile(id);
    return res && res.data || [];
}

export const updateDFile = (id, data) => async dispatch => {
    const res = await service.updateFile(id, data);
    return res && res.data || [];
}

export const renameDFile = (id, name) => async dispatch => {
    const res = await service.renameDFile(id, name);
    return res && res.data || [];
}

export const archiveDFile = (id, archived) => async dispatch => {
    const res = await service.archiveDFile(id, archived);
    return res && res.data || [];
}

export const importantDFile = (id) => async dispatch => {
    const res = await service.importantDFile(id);
    return res && res.data || [];
}

export const addDownload = (id) => async dispatch => {
    const res = await service.addDownload(id);
    return res && res.data || [];
}

export const duplicateDFile = (id, data) => async dispatch => {
    const res = await service.duplicateDFile(id, data);
    return res && res.data || [];
}

export const shareDFile = (id, data) => async dispatch => {
    const res = await service.shareDFile(id,data);
    return res && res.data || [];
}

export const paperbinDFile = (id) => async dispatch => {
    const res = await service.paperbinDFile(id);
    return res && res.data || [];
}

export const restoreDFile = (id) => async dispatch => {
    const res = await service.restoreDFile(id);
    return res && res.data || [];
}

export const globalSearchFiles = (term) => async dispatch => {
    const res = await service.globalSearchFiles(term);
    return res && res.data || [];
}

export const listRecentFiles = (term, sortBy) => async dispatch => {
    const res = await service.listRecentFiles(term, sortBy);
    return res && res.data || [];
}

export const listSharedFileWithMe = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedFileWithMe(term, idFolder, sortBy);
    return res && res.data || [];
}

export const sharedFilesList = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.sharedFilesList(term, idFolder, sortBy);
    return res && res.data || [];
}