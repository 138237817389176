import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { archiveDFile } from 'services/actions/DFileAction'; 
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { connect } from 'formik';

export const ModalArchiveFile = (props) => {

    const { open, handleClose, handleConfirmClose, dFile } = props;

    const [status, setStatus] = React.useState(false);

    React.useEffect(()=>{
      if(open){
        setStatus( dFile.archived ? false : true);
      }else{
        setStatus(true);
      }
    }, [open]);
    
    const updateArchived = () => {
      props.dispatch(showBackdrop(true));
      props.dispatch(archiveDFile(dFile.id, status)).then(
        (res) => {
          props.dispatch(showBackdrop(false));
          handleConfirmClose && handleConfirmClose();
        }).catch(err => {
          props.dispatch(showSnackBar("warning", err.response.data.error || ""));
          props.dispatch(showBackdrop(false));
        });
    }
    
    return (
      <Modal
        open={open}
        handleClose={handleClose}
        size="xs"
      >
        <ModalHeader
          text={`¿Está seguro de ${dFile && (dFile .archived ? "Desarchivar" : "Archivar")} este documento?`}
          className='positionElements'
          onCancel={handleClose}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
              {`El documento será ${dFile && (dFile .archived ? "removido de la" : "guardado en la")}  vista "Archivados".`}
            </Typography>
      </ModalBody>
  
        <ModalFooter 
          confirmText={dFile && (dFile .archived ? "Desarchivar" : "Archivar")}
          cancelText="Cancelar"
          onCancel={handleClose}
          className="footerRight"
          onConfirm={updateArchived}
        />
      </Modal>
    )
  }

  const mapStateToProps = (state) => ({...state})
  export default connect(mapStateToProps)(ModalArchiveFile)