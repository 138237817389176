import { Link, useHistory, withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';

import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import BusinessIcon from '@material-ui/icons/Business';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudIcon from '@material-ui/icons/Cloud';
import ReactToolTip from 'react-tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { HomeWork } from '@material-ui/icons';
import StarIcon from '@material-ui/icons/Star';
import LogoDocset from 'assets/images/LogoDocset.png';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PostAddIcon from '@material-ui/icons/PostAdd';

import 'react-pro-sidebar/dist/css/styles.css';

import ModalStorage from 'components/Modals/ModalStorage';
import { getSessionInfo } from 'utils/common';


const MiniDrawer = props => {

  let history = useHistory();

  const [showModalStorage, setShowModalStorage] = useState(false);
  const [currentView, setCurrentView] = useState();

  const session = getSessionInfo();
  const adminRole = session && session.role || {};
  const isUserAdmin = adminRole.name == 'Admin';
  const isUserAdminCompany = adminRole.name == 'AdminEmpresa';

  useEffect(() => {
    setCurrentView(history.location.pathname);
  },[history.location.pathname]);
  
  const handleOpenStorage = () => {
    setShowModalStorage(true);
  };

  const handleCloseStorage = () => {
    setShowModalStorage(false);
  };

  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar>
          
          <SidebarHeader>
            <div className="logotext" >
              {/* small and big change using menucollapse state */}
              <Link to='/mi-carpeta' >
              <img src={LogoDocset} className="docset" />
              </Link>
            </div>
          </SidebarHeader>
        
          <SidebarContent>

            <Menu>   
              {/* "#1a73e8"  /  "#6aa7f8" */}
              <MenuItem onClick={() => {setCurrentView("/mi-carpeta")}} className={`hover ${currentView == "/mi-carpeta" && "tab_active"}`} data-tip data-for="tooltipMyFolder" icon={<FolderIcon />}>
                <ReactToolTip id="tooltipMyFolder" className="tooltip" effect="solid">
                  {' '}
                  MI CARPETA
                </ReactToolTip>
                <Link to='/mi-carpeta'></Link>
              </MenuItem>           
            
              <MenuItem onClick={() => {setCurrentView("/mis-compartidos")}} className={`hover ${currentView == "/mis-compartidos" && "tab_active"}`} data-tip data-for="tooltipShared" icon={<FolderSharedIcon />}>
                <ReactToolTip id="tooltipShared" className="tooltip" effect="solid">
                  {' '}
                  MIS COMPARTIDOS
                </ReactToolTip>
                <Link to='/mis-compartidos'></Link>
              </MenuItem>  

              <MenuItem onClick={() => {setCurrentView("/compartidos-conmigo")}} className={`hover ${currentView == "/compartidos-conmigo" && "tab_active"}`} data-tip data-for="tooltipSharedMe" icon={<PersonAddIcon />}>
                <ReactToolTip id="tooltipSharedMe" className="tooltip" effect="solid">
                  {' '}
                  COMPARTIDOS CONMIGO
                </ReactToolTip>
                <Link to='/compartidos-conmigo'></Link>
              </MenuItem>  

              {
                !isUserAdmin && (
                  <MenuItem onClick={() => {setCurrentView("/compartidos-empresa")}} className={`hover ${currentView == "/compartidos-empresa" && "tab_active"}`} data-tip data-for="tooltipSharedCompany" icon={<BusinessIcon />}>
                    <ReactToolTip id="tooltipSharedCompany" className="tooltip" effect="solid">
                      {' '}
                      COMPARTIDOS POR LA EMPRESA
                    </ReactToolTip>
                    <Link to='/compartidos-empresa'></Link>
                  </MenuItem>  
                )
              }                         

              <SubMenu className={`hover ${currentView == "/importantes" ?  "tab_active" : (currentView == "/reciente" &&  "tab_active") }`} icon={<ScreenShareIcon />}>
                <MenuItem   onClick={() => {setCurrentView("/importantes")}} className={`hover ${currentView == "/importantes" && "tab_active"}`} data-tip data-for="tooltipImportant">
                  <StarIcon className="ico" />
                  <ReactToolTip id="tooltipImportant" className="tooltip" effect="solid">
                    IMPORTANTES
                  </ReactToolTip>
                  <Link to='/importantes'/>
                </MenuItem>

                <MenuItem  onClick={() => {setCurrentView("/recientes")}} className={`hover ${currentView == "/recientes" && "tab_active"}`} data-tip data-for="tooltipImportantt">
                  <AccessTimeIcon className="ico" />
                  <ReactToolTip id="tooltipImportantt" className="tooltip" effect="solid" place="right">
                    RECIENTES
                  </ReactToolTip>
                  <Link to='/recientes'></Link>
                </MenuItem>
              </SubMenu>

              <MenuItem  onClick={() => {setCurrentView("/archivados")}} className={`hover ${currentView == "/archivados" && "tab_active"}`} data-tip data-for="tooltipArchived" icon={<ArchiveIcon />} >
                <ReactToolTip id="tooltipArchived" className="tooltip" effect="solid">
                  {' '}
                  ARCHIVADOS
                </ReactToolTip>
                <Link to='/archivados'></Link>
              </MenuItem>

              <MenuItem  onClick={() => {setCurrentView("/documentos-generados")}} className={`hover ${currentView == "/documentos-generados" && "tab_active"}`} data-tip data-for="tooltipDocumentGenerate" icon={<PostAddIcon />} >   
                <ReactToolTip id="tooltipDocumentGenerate" className="tooltip" effect="solid">
                  {' '}
                  MIS PLANTILLAS
                </ReactToolTip>
                <Link to='/documentos-generados'></Link>
              </MenuItem>

              <MenuItem  onClick={() => {setCurrentView("/generar-documento")}} className={`hover ${currentView == "/generar-documento" && "tab_active"}`} data-tip data-for="tooltipGenerateDocument" icon={<BorderColorIcon />} >   
                <ReactToolTip id="tooltipGenerateDocument" className="tooltip" effect="solid">
                  {' '}
                  GENERAR DOCUMENTO
                </ReactToolTip>
                <Link to='/generar-documento'></Link>
              </MenuItem>

              <MenuItem  onClick={() => {setCurrentView("/papelera")}} className={`hover ${currentView == "/papelera" && "tab_active"}`} data-tip data-for="tooltipTrash" icon={<DeleteIcon />} >
                <ReactToolTip id="tooltipTrash" className="tooltip" effect="solid">
                  {' '}
                  PAPELERA
                </ReactToolTip>
                <Link to='/papelera'></Link>
              </MenuItem>

              <MenuItem  onClick={() => {setCurrentView("/usuarios")}} className={`hover ${currentView == "/usuarios" && "tab_active"}`} data-tip data-for="tooltipUser" icon={<PersonIcon />} >
                <ReactToolTip id="tooltipUser" className="tooltip" effect="solid">
                  {' '}
                  USUARIOS
                </ReactToolTip>
                <Link to='/usuarios'></Link>
              </MenuItem>

              {
                isUserAdmin && (
                  <MenuItem  onClick={() => {setCurrentView("/empresas")}} className={`hover ${currentView == "/empresas" && "tab_active"}`} data-tip data-for="tooltipCompany" icon={<HomeWork />} >
                    <ReactToolTip id="tooltipCompany" className="tooltip" effect="solid">
                      {' '}
                      EMPRESAS
                    </ReactToolTip>
                    <Link to='/empresas'></Link>
                  </MenuItem>
                )
              }
              
              {
                isUserAdminCompany && (
                  <MenuItem  onClick={() => {setCurrentView("/configuracion-de-la-empresa")}} className={`hover ${currentView == "/configuracion-de-la-empresa" && "tab_active"}`} data-tip data-for="tooltipSetting" icon={<SettingsIcon />} >
                      <ReactToolTip id="tooltipSetting" className="tooltip" effect="solid">
                        {' '}
                        CONFIGURACION DE LA EMPRESA
                      </ReactToolTip>
                      <Link to='/configuracion-de-la-empresa'></Link>
                    </MenuItem>
                )
              }
            </Menu>
          </SidebarContent>

          <SidebarFooter>
            <Menu>
              <MenuItem  className="hover" data-tip data-for="tooltipCloud" icon={<CloudIcon />} onClick={handleOpenStorage} />

              {
                showModalStorage && (
                  <ModalStorage 
                    {...props}
                    open={showModalStorage}
                    handleClose={handleCloseStorage}
                  />
                )
              }

              <ReactToolTip id="tooltipCloud" className="tooltip" effect="solid">
                {' '}
                ALMACENAMIENTO
              </ReactToolTip>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};
export default withRouter(MiniDrawer);
