import { 
    ACTIVE_UPDATE_FILE_LIST,
    DISABLE_UPDATE_FILE_LIST,
    UPDATE_LAST_ROUTE
 } from '../common/Types'

 const initialState = {
    lastRoute: "",
    reload: false
 }

 export default function(state = initialState, action){
    switch(action.type){
        case UPDATE_LAST_ROUTE:
            return {
                ...state,
                lastRoute: action.payload
            }
        case ACTIVE_UPDATE_FILE_LIST:
            return{
                ...state,
                reload: true
            }
        case DISABLE_UPDATE_FILE_LIST:
            return{
                ...state,
                reload: false
            }
        default:
            return state;
    }
 }