import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { duplicateDFile, findDFile} from 'services/actions/DFileAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { TextField } from '@material-ui/core';

export const ModalDuplicateFile = (props) => {

    const { open, handleClose, handleConfirmClose, dFile} = props;

    const [data, setData] = React.useState({
      id: "",
      name: ""
    });

    React.useEffect(() => {
      if(open && dFile){
        setData({
          id: dFile.id,
          name: dFile.name
        });
      }else{
        setData({
          id: "",
          name: ""
        })
      }
    }, [open]);
  
    const validateForm = (values) => {
      let errors = {};
      
      if (!values.name) errors.name = 'Nombre requerido';
      if (values.name == dFile.name) errors.name = 'No puede repetir el nombre original';
      
      return errors;
    }

    const onSubmit = (values, {resetForm} ) => {

      const formData = new FormData();
      formData.append("name", values.name);

      props.dispatch(showBackdrop(true));
      props.dispatch(duplicateDFile(values.id, formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Archivo Duplicado"));
        handleConfirmClose && handleConfirmClose();
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || ""));
        props.dispatch(showBackdrop(false));
      });
      
    }


    return (
    
        <Modal
          open={open}
          handleClose={handleClose}
          size="xs"
        >
          <ModalHeader
            icon={<FileCopyIcon />}
            text="Ingresar nuevo nombre para el Archivo"
            className='positionElements'
            onCancel={handleClose}
          >
          </ModalHeader>
    
          <ModalBody>
            
            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
              {
                ({handleChange, handleSubmit, values, errors, touched}) => {
                  return (
                    <Form onSubmit={handleSubmit}>
    
                      <TextField
                        id="name"
                        type="text" 
                        value={values.name}
                        onChange={handleChange}
                        error={ errors.name && touched.name ? true : false }
                        helperText={  errors.name}
                        placeholder="Nombre del Archivo" 
                        fullWidth
                        style={{padding: "5px"}}
                      />
    
                      <ModalFooter 
                        confirmText="DUPLICAR"
                        cancelText="CANCELAR"
                        onCancel={handleClose}
                        onConfirm={handleSubmit}
                        className="footerRight"
                      />
    
                    </Form>
                  )
                }
              }
            </Formik>
    
          </ModalBody>
    
          
        </Modal>
        
      )
}