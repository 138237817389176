import React from 'react'
import Dialog from '@material-ui/core/Dialog';
  

const Modal = (props) => {

    const { open, handleClose, size="", children, className } = props;

    return (
        <Dialog 
            onClose={handleClose} 
            fullWidth maxWidth={size}
            color="primary" 
            open={open}
            size={size}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: { borderRadius: 20 }
              }}
            className={className || ''}
       >
            {children}
        </Dialog>
    )
}

export default Modal
