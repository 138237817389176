import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader';
import Modal from './common/Modal';
import { Formik } from 'formik';
import { Button, Grid } from '@material-ui/core';
import  CustomTable  from 'components/CustomTable';
import { showBackdrop } from 'services/actions/CustomAction';
import { listTemplate } from 'services/actions/TemplateAction';
import { useHistory } from 'react-router-dom';

export const ModalTemplates = (props) => {
    const { open, closeModal, showAddNewTemplate } = props; 
    const [table, setTable] = React.useState([]);
    
    const columns = [
        { type: 'text', field: 'name', label: 'Nombre de la plantilla', format: (row) => `${row.name || ""}` },
    ];
    
    React.useEffect(()=>{
        props.dispatch(showBackdrop(true));
        props.dispatch(listTemplate("")).then(res => {
            setTable(res);
            props.dispatch(showBackdrop(false));
        });
    },[]);
    
    let history = useHistory();
    const onRowClick = (template) => {
        history.push(`/generar-documento/${template.id}`)
        showAddNewTemplate && showAddNewTemplate();
        closeModal && closeModal();
    }

    return (
        <Modal
            open={open}
            handleClose={()=> { closeModal(false) }}
            size="md"
        >
            <ModalHeader
                text={'Plantillas'}
                className='positionElements'
                onCancel={()=> { closeModal(false) }}
            >
            </ModalHeader>
            <ModalBody>
                <Grid item xs={12} container>
                    <CustomTable 
                    columns={columns} 
                    rows={table || []}
                    onRowClick={onRowClick}
                    hasOptions
                    /> 
                </Grid>
            </ModalBody>
        </Modal>
    );
}