import ItemService from "services/api/ItemService";

const service = new ItemService();

export const createItem = (data) => async dispatch => {
    const res = await service.createItem(data);
    return res && res.data || [];
}

export const updateItem = (id, data) => async dispatch => {
    const res = await service.updateItem(id, data);
    return res && res.data || [];
}

export const findItem = (id) => async dispatch => {
    const res = await service.findItem(id);
    return res && res.data || [];
}

export const listItem = (term, idSection, idComponent) => async dispatch => {
    const res = await service.listItem(term, idSection, idComponent);
    return res && res.data || [];
}

export const deleteItem = (id) => async dispatch => {
    const res = await service.deleteItem(id);
    return res && res.data || [];
}