import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import CustomFile from './CustomFile';
import { ModalRenameFile } from 'components/Modals/ModalRenameFile';
import { ModalArchiveFile } from 'components/Modals/ModalArchiveFile';
import ModalUploadFile from 'components/Modals/ModalUploadFile';
import config from 'config/Config';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { addDownload } from 'services/actions/DFileAction';
import { importantDFile } from 'services/actions/DFileAction';
import { ModalDuplicateFile } from 'components/Modals/ModalDuplicateFile';
import { ModalShareFile } from 'components/Modals/ModalShareFile';
import { ModalDeleteFile } from 'components/Modals/ModalDeleteFile';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const RecentFiles = (props) => {
    const { dFiles=[], title, refreshList } = props;
    let history = useHistory();
    
    const [selectedDFile, setSelectedDFile] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [showShareModal, setShowShareModal] = React.useState(false);
    const [showRenameModal, setShowRenameModal] = React.useState(false);
    const [showArchiveModal, setShowArchiveModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal ] = React.useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = React.useState(false);

    const onShare = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowShareModal(true);
    }

    const onEdit = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setEditMode(true);
        setShowEditModal(true);
    }
    
    const onDuplicate = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowDuplicateModal(true);
    }

    const onRename = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowRenameModal(true);
    }

    const showLocation = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        history.push(selectedDFile.folderPath);
    }
    
    const onImportant = (selectedDFile) => {
        props.dispatch(showBackdrop(true));
        props.dispatch(importantDFile(selectedDFile.id)).then(res => {
            props.dispatch(showBackdrop(false));
            refreshList();
        }).catch(err => {
            props.dispatch(showSnackBar("warning", err.response.data.error || ""));
            props.dispatch(showBackdrop(false));
        })
    }

    const onArchived = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowArchiveModal(true);
    }

    const onDownload = (selectedDFile) => {
        props.dispatch(showBackdrop(true));
        props.dispatch(addDownload(selectedDFile.id)).then(res => {
            props.dispatch(showBackdrop(false));
            window.open(config.api + selectedDFile.filePath, '_blank');
        }).catch(err => {
            props.dispatch(showSnackBar("warning", err.response.data.error || ""));
            props.dispatch(showBackdrop(false));
        });
    }

    const onDetails = (selectedDFile) => {
        setEditMode(false);
        setSelectedDFile(selectedDFile);
        setShowDetailsModal(true);
    }

    const onDeleted = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowDeleteModal(true);
    }

    const currentDay =  moment().format('"MMM Do YY"');
    const currentWeek =  moment().subtract(7, 'days').calendar();
    const currentMonth =  moment().format('MM/YYYY');

    const todayFiles = dFiles.filter(dFile => moment(dFile.updated_at).format('"MMM Do YY"') == currentDay);
    const weekFiles = dFiles.filter(dFile => dFile.updated_at >= currentWeek);
    const monthFiles = dFiles.filter(dFile => moment(dFile.updated_at).format('MM/YYYY') == currentMonth);

    const sections = [
        { name: "Hoy", files: todayFiles },
        { name: "Esta Semana", files: weekFiles },
        { name: "Este Mes", files: monthFiles },
    ];

    return(
        <Grid container item xs={12} className="folder_container">
            {
                sections.map(section => {
                    return <>
                        <Grid item xs={12}>
                            <Typography> { section.name } </Typography>
                        </Grid>
                        {
                            section.files.length > 0 && section.files.map((item, i) => {
                                return (
                                    <CustomFile
                                        dFile={item}
                                        key={i}
                                        keyId={i}
                                        onShare={onShare}
                                        onEdit={onEdit}
                                        onDuplicate={onDuplicate}
                                        onRename={onRename}
                                        showLocation={showLocation}
                                        onImportant={onImportant}
                                        onArchived={onArchived}
                                        onDownload={onDownload}
                                        onDetails={onDetails}
                                        onDeleted={onDeleted}
                                    />
                                )
                            })
                        }
                    </>
                })
            }
            <ModalShareFile
                {...props}
                open={showShareModal}
                handleClose={ () => { setShowShareModal(false) } }
                dFile={selectedDFile}
                handleConfirmClose={() => { 
                    setShowShareModal(false);
                    refreshList && refreshList();
                }}
            />
            <ModalUploadFile
                {...props}
                open={showEditModal}
                handleClose={ () => { setShowEditModal(false) } }
                dFile={selectedDFile}
                editMode={editMode}
                handleConfirmClose={() => { 
                    setShowEditModal(false);
                    refreshList && refreshList();
                }}
            />
            <ModalRenameFile
                {...props}
                open={showRenameModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowRenameModal(false) } }
                handleConfirmClose={() => { 
                    setShowRenameModal(false);
                    refreshList && refreshList();
                 }}
            />
            <ModalArchiveFile
                {...props}
                open={showArchiveModal}
                dFile={selectedDFile}
                handleClose={() => { setShowArchiveModal(false) } }
                handleConfirmClose={()=>{ 
                    setShowArchiveModal(false);
                    refreshList && refreshList();
                }}
            />
            <ModalUploadFile
                {...props}
                open={showDetailsModal}
                handleClose={ () => { setShowDetailsModal(false) } }
                dFile={selectedDFile}
                editMode={editMode}
                handleConfirmClose={() => { 
                    setShowDetailsModal(false);
                    refreshList && refreshList();
                }}
            />
            <ModalDuplicateFile
                {...props}
                open={showDuplicateModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowDuplicateModal(false) } }
                handleConfirmClose={() => { 
                    setShowDuplicateModal(false);
                    refreshList && refreshList();
                 }}
            />
            <ModalDeleteFile
                {...props}
                open={showDeleteModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowDeleteModal(false) } }
                handleConfirmClose={() => { 
                    setShowDeleteModal(false);
                    refreshList && refreshList();
                 }}
            />
        </Grid>
    );
}
export default RecentFiles;