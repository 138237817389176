import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export const blueTheme = createMuiTheme({ 
  contrastThreshold: 3,
  tonalOffset: 0.2,
  palette: { 
    primary: lightBlue,
    contrastText: "#fff"
  } 
});

const ThemeInfo = (props) => {
	return(
		<MuiThemeProvider theme={blueTheme}>
			{props.children}
		</MuiThemeProvider>
	);
}

export default ThemeInfo;