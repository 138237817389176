import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader';
import Modal from './common/Modal';
import ModalFooter from './common/ModalFooter';
import { Typography } from '@material-ui/core';
import { deleteItem } from 'services/actions/ItemAction'
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { connect } from 'formik';

export const ModalDeleteItem = (props) => {
  const { open, closeModal, confirmCloseModal, idItem } = props;

  const onDelete = () => {
    props.dispatch(showBackdrop(true));
    props.dispatch(deleteItem(idItem)).then((res) => {
      props.dispatch(showBackdrop(false));
      confirmCloseModal && confirmCloseModal();
      props.dispatch(showSnackBar("success", "Item Eliminado"));
    }).catch(err => {
      props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo Eliminar el Item"));
      props.dispatch(showBackdrop(false));
    });
  }

  return (
    <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
      >
        <ModalHeader
          text="Eliminar Item"
          className='positionElements'
          onCancel={closeModal}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
              ¿Está seguro de eliminar este item y sus estilos?
            </Typography>
        </ModalBody>
  
        <ModalFooter 
          confirmText="Eliminar"
          cancelText="Cancelar"
          onCancel={closeModal}
          className="footerRight"
          onConfirm={onDelete}
        />
      </Modal>
  );

}

const mapStateToProps = (state) => ({...state})
export default connect(mapStateToProps)(ModalDeleteItem)