import TemplateService from "services/api/TemplateService";

const service = new TemplateService();

export const createTemplate = (data) => async dispatch => {
    const res = await service.createTemplate(data);
    return res && res.data || [];
}

export const updateTemplate = (id, data) => async dispatch => {
    const res = await service.updateTemplate(id, data);
    return res && res.data || [];
}

export const findTemplate = (id) => async dispatch => {
    const res = await service.findTemplate(id);
    return res && res.data || [];
}

export const listTemplate = (term) => async dispatch => {
    const res = await service.listTemplate(term);
    return res && res.data || [];
}

export const deleteTemplate = (id) => async dispatch => {
    const res = await service.deleteTemplate(id);
    return res && res.data || [];
}

export const verifyTemplate = (id) => async dispatch => {
    const res = await service.verifyTemplate(id);
    return res && res.data || [];
}