import React from 'react';
import { Add } from '@material-ui/icons';
import  CustomTable  from 'components/CustomTable'
import {Autocomplete} from '@material-ui/lab';
import { TextField, Typography, Grid, Button} from '@material-ui/core';
import { customSizes, customWeights, customUnderline } from 'utils/customStyles';

const CustomAddStyle = (props) => {
  const {customStyles, data, table, setCategory, setStyle, setValue, addStyleTable, deleteStyleTable, formatValue } = props;

  const [categorySearch, setCategorySearch] = React.useState("");
  const [styleSearch, setStyleSearch] = React.useState("");
  const [typeValueSearch, setTypeValueSearch] = React.useState("");

  const categories = [
    {id: 'Letra', name: 'Letra'},
    // {id: 'Fondo', name: 'Fondo'},
    // {id: 'Borde', name: 'Borde'},
    // {id: 'Separar_Externo', name: 'Separar Externo'},
    // {id: 'Separar_Interno', name: 'Separar Interno'}, 
    // {id: 'Tamano', name: 'Tamaño'}, 
    // {id: 'Posicion', name: 'Posición'}, 
  ];

  const columns = [
    { type: 'text', field: 'name', label: 'Categoria', format: (row) => `${row.name || ""}` },
    { type: 'text', field: 'style', label: 'Estilo', format: (row) => `${row.style || ""}` },
    { type: 'text', field: 'label_value', label: 'Valor', format: (row) => `${row.label_value || ""}`},
    { type: 'options', field: 'options', label: 'Eliminar' }
  ];

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid item xs={12} >
          <Typography variant="subtitle1" className="custom-input"><b>Estilos </b></Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <Autocomplete
              id="category_id"
              value={data && data.category_id}
              onChange={(event, newValues) => {
                setCategory && setCategory(newValues);
              }}
              options={categories || []}
              getOptionLabel={(option) => option.name || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              inputValue={categorySearch}
              onInputChange={(event, newInputValue) => {
                setCategorySearch(newInputValue);
              }}
              filterSelectedOptions
              renderInput={params => (
                  <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              value={data && data.style}
              onChange={(event, newValues) => {
                setStyle && setStyle(newValues);
              }}
              options={customStyles || []}
              getOptionLabel={(option) => option.name || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              inputValue={styleSearch}
              onInputChange={(event, newInputValue) => {
                setStyleSearch(newInputValue);
              }}
              filterSelectedOptions
              renderInput={params => (
                  <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </Grid>
          {
            formatValue == "sizeSelect" && (
              <Grid item xs={3}>
                <Autocomplete
                  id="typeValue"
                  value={data.value || customSizes[2]}
                  inputValue={typeValueSearch}
                  onChange={(event, newValues) => {
                    setValue && setValue(newValues);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTypeValueSearch(newInputValue);
                  }}
                  options={customSizes || []}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderInput={params => (
                      <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </Grid>
            )
          }
          {
            formatValue == "weightSelect" && (
              <Grid item xs={3}>
                <Autocomplete
                  id="typeValue"
                  value={data.value || customWeights[0]}
                  inputValue={typeValueSearch}
                  onChange={(event, newValues) => {
                    setValue && setValue(newValues);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTypeValueSearch(newInputValue);
                  }}
                  options={customWeights || []}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderInput={params => (
                      <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </Grid>
            )
          }
          {
            formatValue == "decorateSelect" && (
              <Grid item xs={3}>
                <Autocomplete
                  id="typeValue"
                  value={data.value || customUnderline[0]}
                  inputValue={typeValueSearch}
                  onChange={(event, newValues) => {
                    setValue && setValue(newValues);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTypeValueSearch(newInputValue);
                  }}
                  options={customUnderline || []}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderInput={params => (
                      <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </Grid>
            )
          }
          {
            formatValue == "freeText" && (
              <Grid item xs={3}>
                <TextField
                  id="typeValue"
                  type="text" 
                  name="typeValue"
                  fullWidth
                  value={data.value || ""}
                  onChange={setValue}
                  placeholder="Valor"
                />
              </Grid>
            )
          }
          <Grid item xs={1}>
            <Button className="btnSection" style={{ backgroundColor: "green"}} onClick={() => {addStyleTable && addStyleTable()}}>
              <Add className="icoAdd"  />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <hr/>
      <Grid item xs={12} container>
        <CustomTable 
          columns={columns} 
          rows={table || []}
          onRowClick={() => {}}
          hasOptions
          onDelete={deleteStyleTable && deleteStyleTable}
        /> 
      </Grid>
    </Grid>
  )
}

export default CustomAddStyle;