//const { default: DFolderService } = require("services/api/DFolderService");

import DFolderService from "services/api/DFolderService";

const service = new DFolderService()

export const createDFolder = (data) => async dispatch => {
    const res = await service.createFolder(data);
    return res && res.data || [];
}

export const listMyFolders = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listMyFolders(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listSharedFolders = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedFolders(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listImportantFolders = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listImportantFolders(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listSharedCompanyFolders = (term, idCompany, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedCompanyFolders(term, idCompany, idFolder, sortBy);
    return res && res.data || [];
}

export const findDFolder = (id) => async dispatch => {
    const res = await service.findDFolder(id);
    return res && res.data || [];
}

export const verifyFindDFolder = (id) => async dispatch => {
    const res = await service.verifyFindDFolder(id);
    return res && res.data || [];
}

export const deleteDFolder = (id) => async dispatch => {
    const res = await service.deleteDFolder(id);
    return res && res.data || [];
}

export const updateDFolder = (id, data) => async dispatch => {
    const res = await service.updateFolder(id, data);
    return res && res.data || [];
}

export const renameDFolder = (id, name) => async dispatch => {
    const res = await service.renameDFolder(id, name);
    return res && res.data || [];
}

export const shareDFolder = (id, data) => async dispatch => {
    const res = await service.shareDFolder(id, data);
    return res && res.data || [];
}

export const listSharedFolderWithMe = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listSharedFolderWithMe(term, idFolder, sortBy);
    return res && res.data || [];
}

export const verifyFindWithMe = (id) => async dispatch => {
    const res = await service.verifyFindWithMe(id);
    return res && res.data || [];
}

export const sharedFoldersList = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.sharedFoldersList(term, idFolder, sortBy);
    return res && res.data || [];
}

export const listDFolderConfig = (term, idFolder, sortBy) => async dispatch => {
    const res = await service.listDFolderConfig(term, idFolder, sortBy);
    return res && res.data || [];
}

export const updateFolderConfig = (id, name, status) => async dispatch => {
    const res = await service.updateFolderConfig(id, name, status);
}

export const verifyFindSharedCompany = (id) => async dispatch => {
    const res = await service.verifyFindSharedCompany(id);
    return res && res.data || [];
}