import {Grid, makeStyles, TextField, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { listGeneralAdmin, listUsersByCompany } from 'services/actions/UserAction';
import ShareIcon from '@material-ui/icons/Share';
import { shareDFile } from 'services/actions/DFileAction';
import { getSessionInfo } from 'utils/common';
import {Autocomplete} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  fullInputText: {
      margin: theme.spacing(1),
      width: "100%"
  }
}));

export const ModalShareFile = props => {
  const { open, handleClose, handleConfirmClose, dFile } = props;
  const classes = useStyles();

  const [shared, setShared] = React.useState(false);
  const [sharedCompany, setSharedCompany] = React.useState(false);
  const [accessType, setAccessType] = React.useState("Publico");
  const [sharedPermission, setSharedPermission] = React.useState("Lector");
  const [sharedWith, setSharedWith] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [userSearch, setUserSearch] = React.useState("");

  const session = getSessionInfo();
  const adminRole = session && session.role || {};
  const isUserAdminCompany = adminRole.name == 'AdminEmpresa';
  const isUserAdminGeneral = adminRole.name == 'Admin';
  const user = session && session.user;

  const currentIdCompany = session && session.user && session && session.user.idCompany || "";

  React.useEffect(() => {
    if(open && dFile){
      if(isUserAdminGeneral){
        props.dispatch(listGeneralAdmin("")).then(res => {
          setUsers(res || []);
          setShared(!!dFile.shared || false);
          setSharedCompany(!!dFile.sharedCompany || false);
          setSharedWith(setCustomUser(dFile.sharedWith, res) || []);
          setAccessType(dFile.accessType || "Publico");
          setSharedPermission(dFile.sharedPermission || "Lector")
  
        }).catch(err => showError(err));
      }else{
        props.dispatch(listUsersByCompany("", currentIdCompany)).then(res => {
          setUsers(res || []);
          setShared(!!dFile.shared || false);
          setSharedCompany(!!dFile.sharedCompany || false);
          setSharedWith(setCustomUser(dFile.sharedWith, res) || []);
          setAccessType(dFile.accessType || "Publico");
          setSharedPermission(dFile.sharedPermission || "Lector")
  
        }).catch(err => showError(err));
      }
    
    } else{
      setShared(false);
      setSharedCompany(false);
      setSharedWith([]);
      setAccessType("Publico");
    }

  }, [open]);

  const showError = (err) => {
    props.dispatch(showSnackBar('error', ""));
    props.dispatch(showBackdrop(false));
    console.log(err);
  }

  const setCustomUser = (findUsers, response) => {
    const data = findUsers ? findUsers.map( user => response.find(value => value.id == user)) : [];
    return getCustomUsers(data);
  }

  const getCustomUsers = (originalUsers) => {
    return originalUsers ? originalUsers.map(user => ({id: user.id, name: (user.firstName + ' '+ user.lastName)})) : [];
  }

  const customUsers = getCustomUsers(users || []);

  const onSubmit = () => {

    const message = shared ? "Archivo Compartido" : "Archivo No Compartido";

    const formData = new FormData();
    formData.append("shared", shared);
    formData.append("sharedCompany", sharedCompany);
    formData.append("accessType", shared ? accessType : "");
    if(shared){
      formData.append('sharedPermission', sharedPermission);
    }
    if(shared && accessType == "Privado"){
      for (var i = 0; i < sharedWith.length; i++) {
        formData.append('sharedWith[]', sharedWith[i].id);
      }
    }

    props.dispatch(showBackdrop(true));
    props.dispatch(shareDFile(dFile.id, formData)).then(res => {
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
        props.dispatch(showSnackBar("success", message));
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo Compartir el Archivo"));
        props.dispatch(showBackdrop(false));
      });
  }

  return (
    <Modal open={open} handleClose={handleClose} size="sm">
      <ModalHeader
        icon={<ShareIcon />}
        text="Compartir"
        className="positionElements"
        onCancel={handleClose}
      ></ModalHeader>
      
      <ModalBody>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={5} className="item-center">
            <Typography variant="body1">Compartir: </Typography>
          </Grid>
          <Grid item xs={7}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              checked={shared}
              onChange={() => {setShared(!shared)}}
            />
          </Grid>
        </Grid>
        {
          isUserAdminCompany && shared && (
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={5} className="item-center">
                <Typography variant="body1">Compartir con la empresa: </Typography>
              </Grid>
              <Grid item xs={7}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={sharedCompany}
                  onChange={() => {setSharedCompany(!sharedCompany)}}
                />
              </Grid>
            </Grid>
          )
        }
        {
          shared && (
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body1">Tipo de Acceso: </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  id="accessType"
                  value={accessType}
                  onChange={(event) => { setAccessType(event.target.value) }}
                  fullWidth
                >
                  <MenuItem value="Privado">Privado</MenuItem>
                  <MenuItem value="Publico">Público</MenuItem>
                </Select>
              </Grid>
            </Grid>
          )          
        }
        {
          shared && accessType == "Publico" && (
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body1">Permisos: </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  id="sharedPermission"
                  value={sharedPermission}
                  onChange={(event) => { setSharedPermission(event.target.value) }}
                  fullWidth
                >
                  <MenuItem value="Lector">Lector</MenuItem>
                  <MenuItem value="Editor">Editor</MenuItem>
                </Select>
              </Grid>
            </Grid>
          )          
        }
        {
          shared && accessType == "Privado" && (
            <>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body1">Compartir con: </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                      id="users"
                      multiple
                      value={sharedWith || []}
                      onChange={(event, newValues) => {
                        setSharedWith(newValues);
                      }}
                      options={customUsers || []}
                      getOptionLabel={(option) => option.name || ""}
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionDisabled={(option) => option.id == user.id}
                      inputValue={userSearch}
                      onInputChange={(event, newInputValue) => {
                        setUserSearch(newInputValue);
                      }}
                      filterSelectedOptions
                      renderInput={params => (
                          <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body1">Permisos: </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Select
                    id="sharedPermission"
                    value={sharedPermission}
                    onChange={(event) => { setSharedPermission(event.target.value) }}
                    fullWidth
                  >
                    <MenuItem value="Lector">Lector</MenuItem>
                    <MenuItem value="Editor">Editor</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </>
          )
        }
      </ModalBody>
      <ModalFooter 
        confirmText="Compartir"
        cancelText="Cancelar"
        onCancel={handleClose}
        className="footerRight"
        onConfirm={onSubmit}
      />
    </Modal>
  );
};
