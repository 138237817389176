import React from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { Typography, IconButton } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import StarIcon from '@material-ui/icons/Star';
import { getSessionInfo } from 'utils/common';

export const MenuOptionsFolder = (props) => {

    const { options, open, handleClose, dFolder} =props;

    const session = getSessionInfo();
    const user = session && session.user || {};
    const role = session && session.role || {};
    const isUserAdminCompany = role.name == "AdminEmpresa";

    const onClickOption = (option) => {
        switch(option){
            case "rename": props.onRename && props.onRename(); break;
            case "important": props.onImportant && props.onImportant(); break;
            case "share": props.onShare && props.onShare(); break;
            case "deleted": props.onDeleted && props.onDeleted(); break;
        }
        handleClose(null);
    }

    const showOption = (option) => {
        var path = window.location.pathname;
        var showOptionInView = false;
        var userCreated = (user.id == dFolder.createdBy) ? true : false;
        var sharedWithMe = dFolder.sharedWith.includes(user.id) ? true : false;

        switch(option){
            case "important":
                showOptionInView = !["/importantes", "/compartidos", "/compartidos-conmigo", "/compartidos-empresa"].includes(path);
                return showOptionInView && userCreated;
            case "details":
                return true;
            case "rename":
                showOptionInView = !["/papelera"].includes(path);
                return  showOptionInView && (userCreated || sharedWithMe);
            default:
                showOptionInView = !["/papelera"].includes(path);
                if(path == "/compartidos-empresa" && !isUserAdminCompany){
                    return false;
                }
                if(path == "/compartidos-conmigo" && user.id != dFolder.createdBy){
                    return false;
                }
                userCreated = ["/compartidos-empresa"].includes(path) ? (userCreated || user.idRole == 2) : userCreated;
                return  showOptionInView && userCreated;
        }

    }

    return (
        <div>
             <Menu id="long-menu" anchorEl={options} className="optionsMenuDropdown" keepMounted open={open}  onClose={handleClose} TransitionComponent={Fade} >

                {
                    showOption("details") && (
                            <MenuItem onClick={() => { onClickOption("details") }}>
                                <InfoIcon className="icon"/> 
                                <Typography>
                                    Detalles
                                </Typography>
                            </MenuItem>
                    )
                }

                {
                    showOption("rename") && (
                        <MenuItem onClick={() => { onClickOption("rename") }}  style={{borderTop:"1px solid #929292"}}>
                            <BorderColorIcon className="icon"/> 
                            <Typography>
                                Renombrar
                            </Typography>
                        </MenuItem>
                    )
                }

                {
                    showOption("important") && (
                        <MenuItem onClick={() => { onClickOption("important") }}>
                            <StarIcon className="icon"/> 
                            <Typography>
                                Importante
                            </Typography>
                        </MenuItem>
                    )
                }

                {
                    showOption("share") && (
                        <MenuItem onClick={() => { onClickOption("share") }}>
                            <ShareIcon className="icon"/> 
                            <Typography>
                                Compartir
                            </Typography>
                        </MenuItem>
                    )
                }

                {
                    showOption("deleted") && (
                        <hr/>
                    )
                }
                
                {
                    showOption("deleted") && (
                        <MenuItem onClick={() => { onClickOption("deleted") }}>
                            <DeleteIcon className="icon"/> 
                            <Typography>
                                Eliminar
                            </Typography>
                        </MenuItem>
                    )
                }

            </Menu>
        </div>
    )
}
