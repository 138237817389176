import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "dFiles";

class DFileService {

    async createFile(data) {
        return await axios.post( getCustomUrl(apiPrefix, `/register`), data, defaultHeaders() );
    }

    async listMyFiles(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listMyFiles?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listSharedFiles(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listShared?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listImportantFiles(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listImportant?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listSharedCompanyFiles(term, idCompany, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listSharedCompany?term=${term}&idCompany=${idCompany}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }
    
    async listArchivedFiles(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/archived?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listPaperBinFiles(term, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listPaperBin?term=${term}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async findFile(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/find/${id}`), defaultHeaders() );
    }

    async deleteDFile(id) {
        return await axios.delete( getCustomUrl(apiPrefix, `/delete/${id}`), defaultHeaders() );
    }

    async updateFile(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/update/${id}`), data, defaultHeaders() );
    }

    async renameDFile(id, name) {
        return await axios.post( getCustomUrl(apiPrefix, `/rename/${id}`), {name}, defaultHeaders() );
    }

    async archiveDFile(id, archived) {
        return await axios.post( getCustomUrl(apiPrefix, `/archive/${id}`), {archived}, defaultHeaders() );
    }
    async importantDFile(id) {
        return await axios.post( getCustomUrl(apiPrefix, `/important/${id}`), {}, defaultHeaders() );
    }

    async addDownload(id) {
        return await axios.post( getCustomUrl(apiPrefix, `/addDownload/${id}`), {}, defaultHeaders() );
    }

    async duplicateDFile(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/duplicate/${id}`), data, defaultHeaders() );
    }

    async shareDFile(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/share/${id}`), data, defaultHeaders() );
    }
    
    async paperbinDFile(id) {
        return await axios.post( getCustomUrl(apiPrefix, `/paperBin/${id}`), {}, defaultHeaders() );
    }

    async restoreDFile(id) {
        return await axios.post( getCustomUrl(apiPrefix, `/restore/${id}`), {}, defaultHeaders() );
    }

    async globalSearchFiles(term) {
        return await axios.get( getCustomUrl(apiPrefix, `/globalSearch?term=${term}`), defaultHeaders() );
    }

    async listRecentFiles(term, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/recent?term=${term}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listSharedFileWithMe(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listSharedWithMe?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async sharedFilesList(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/sharedFilesList?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

}

export default DFileService;