import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import CustomFile from './CustomFile';
import { ModalRenameFile } from 'components/Modals/ModalRenameFile';
import { ModalArchiveFile } from 'components/Modals/ModalArchiveFile';
import ModalUploadFile from 'components/Modals/ModalUploadFile';
import config from 'config/Config';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { addDownload, importantDFile, restoreDFile } from 'services/actions/DFileAction';
import { ModalDuplicateFile } from 'components/Modals/ModalDuplicateFile';
import { ModalShareFile } from 'components/Modals/ModalShareFile';
import { ModalDeleteFile } from 'components/Modals/ModalDeleteFile';
import { useHistory } from 'react-router-dom';

const CustomFiles = (props) => {
    const { dFiles=[], title, refreshList, order } = props;

    const history = useHistory();
    
    const [selectedDFile, setSelectedDFile] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [showShareModal, setShowShareModal] = React.useState(false);
    const [showRenameModal, setShowRenameModal] = React.useState(false);
    const [showArchiveModal, setShowArchiveModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal ] = React.useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = React.useState(false);

    const onShare = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowShareModal(true);
    }

    const onEdit = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setEditMode(true);
        setShowEditModal(true);
    }
    
    const onDuplicate = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowDuplicateModal(true);
    }

    const onRename = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowRenameModal(true);
    }
    
    const showLocation = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        history.push(selectedDFile.folderPath);
    }
    
    const onImportant = (selectedDFile) => {
        props.dispatch(showBackdrop(true));
        props.dispatch(importantDFile(selectedDFile.id)).then(res => {
            props.dispatch(showBackdrop(false));
            refreshList && refreshList(order);
        }).catch(err => {
            props.dispatch(showSnackBar("warning", err.response.data.error || ""));
            props.dispatch(showBackdrop(false));
        })
    }

    const onArchived = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowArchiveModal(true);
    }

    const onDownload = (selectedDFile) => {
        props.dispatch(showBackdrop(true));
        props.dispatch(addDownload(selectedDFile.id)).then(res => {
            props.dispatch(showBackdrop(false));
            window.open(config.api + selectedDFile.filePath, '_blank');
        }).catch(err => {
            props.dispatch(showSnackBar("warning", err.response.data.error || ""));
            props.dispatch(showBackdrop(false));
        });
    }

    const onDetails = (selectedDFile) => {
        setEditMode(false);
        setSelectedDFile(selectedDFile);
        setShowDetailsModal(true);
    }

    const onDeleted = (selectedDFile) => {
        setSelectedDFile(selectedDFile);
        setShowDeleteModal(true);
    }

    const onRestore = (selectedDFile) => {
        props.dispatch(showBackdrop(true));
        props.dispatch(restoreDFile(selectedDFile.id)).then(res => {
            props.dispatch(showBackdrop(false));
            refreshList && refreshList(order);
        }).catch(err => {
            props.dispatch(showSnackBar("warning", err.response.data.error || ""));
            props.dispatch(showBackdrop(false));
        })
    }

    return(
        <Grid container item xs={12} className="folder_container">
            {
                dFiles.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <Typography> { title } </Typography>
                        </Grid>
                        {
                            dFiles.map((item, i) => {
                                return (
                                    <CustomFile
                                        dFile={item}
                                        key={i}
                                        keyId={i}
                                        onShare={onShare}
                                        onEdit={onEdit}
                                        onDuplicate={onDuplicate}
                                        onRename={onRename}
                                        showLocation={showLocation}
                                        onImportant={onImportant}
                                        onArchived={onArchived}
                                        onDownload={onDownload}
                                        onDetails={onDetails}
                                        onDeleted={onDeleted}
                                        onRestore={onRestore}
                                    />
                                )
                            })
                        }
                    </>
                )
            }
            <ModalShareFile
                {...props}
                open={showShareModal}
                handleClose={ () => { setShowShareModal(false) } }
                dFile={selectedDFile}
                handleConfirmClose={() => { 
                    setShowShareModal(false);
                    refreshList && refreshList(order);
                }}
            />
            {
                showEditModal && (
                    <ModalUploadFile
                        {...props}
                        open={showEditModal}
                        handleClose={ () => { setShowEditModal(false) } }
                        onDownload={onDownload}
                        dFile={selectedDFile}
                        editMode={editMode}
                        handleConfirmClose={() => { 
                            setShowEditModal(false);
                            refreshList && refreshList(order);
                        }}
                    />
                )
            }
            <ModalRenameFile
                {...props}
                open={showRenameModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowRenameModal(false) } }
                handleConfirmClose={() => { 
                    setShowRenameModal(false);
                    refreshList && refreshList(order);
                 }}
            />
            <ModalArchiveFile
                {...props}
                open={showArchiveModal}
                dFile={selectedDFile}
                handleClose={() => { setShowArchiveModal(false) } }
                handleConfirmClose={()=>{ 
                    setShowArchiveModal(false);
                    refreshList && refreshList(order);
                }}
            />
            <ModalUploadFile
                {...props}
                open={showDetailsModal}
                handleClose={ () => { setShowDetailsModal(false) } }
                dFile={selectedDFile}
                editMode={editMode}
                handleConfirmClose={() => { 
                    setShowDetailsModal(false);
                    refreshList && refreshList(order);
                }}
            />
            <ModalDuplicateFile
                {...props}
                open={showDuplicateModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowDuplicateModal(false) } }
                handleConfirmClose={() => { 
                    setShowDuplicateModal(false);
                    refreshList && refreshList(order);
                 }}
            />
            <ModalDeleteFile
                {...props}
                open={showDeleteModal}
                dFile={selectedDFile}
                handleClose={ () => { setShowDeleteModal(false) } }
                handleConfirmClose={() => { 
                    setShowDeleteModal(false);
                    refreshList && refreshList(order);
                 }}
            />
        </Grid>
    );
}
export default CustomFiles;