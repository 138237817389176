import {Grid, TextField, Checkbox } from '@material-ui/core';
import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { createDFolder } from 'services/actions/DFolderAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Field, Formik } from 'formik';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import { getSessionInfo } from 'utils/common';
import { useParams } from 'react-router-dom';

export const ModalAddFolder = (props) => {

  const { open, closeModal, currentPath, pageSharedCompany, isConfig, dFolder} = props;

  const {idFolder} = useParams();
  
  const session = getSessionInfo();
  const {idRole} = session && session.user;

  const [data, setData] = React.useState({
    name: "",
    path: currentPath,
    shared: false,
    sharedCompany: false,
    sharedWithAll: false,
    status: false,
    sharedWith: []
  });

  const [users, setUsers] = React.useState([
    {id: "1", firstName: "Edu", lastName: "Estula"},
    {id: "2", firstName: "Andy", lastName: "Estula"},
    {id: "3", firstName: "Cindy", lastName: "Estula"},
  ]);

  React.useEffect(() => {
    if(open){
      setData({
        name: "",
        path: currentPath,
        shared: false,
        sharedCompany: false,
        sharedWithAll: false,
        sharedWith: []
      });
    }
  }, [open])

  const validateForm = (values) => {
    let errors = {};
    
    if (!values.name) 
      errors.name = 'Nombre requerido';

    return errors;
  };

  const onSubmitForm = (values, {resetForm}) => {

    const path = data.path == "/compartidos-empresa";
    const validate = path && pageSharedCompany;
    const sharedCompany = validate ? true : values.sharedCompany;

    const formData = new FormData();
    formData.append("id", users.id || "");
    if(idFolder) formData.append("idFolder", idFolder);
    formData.append("name", values.name);
    formData.append("path", values.path);
    if(isConfig){
      formData.append("sharedCompany", true);
      formData.append("shared", true);
    }else{
      formData.append("sharedCompany", sharedCompany);
      formData.append("shared", sharedCompany ? true : false);
    }
    if(isConfig || sharedCompany) formData.append("status", true);
    formData.append("sharedWithAll", values.sharedWithAll);

    if(values.sharedWith){
      for(var i=0; i < values.sharedWith.length; i++){
        formData.append("sharedWith[]", values.sharedWith);
      }
    }

    // Agregar
    props.dispatch(showBackdrop(true));
    props.dispatch(createDFolder(formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Carpeta Creada" || ""));
        closeModal(true);
        resetForm();
    }).catch(error => {
        props.dispatch(showSnackBar('error', error.response.data.error));
        props.dispatch(showBackdrop(false));
        console.error('error', error);
    });
  }

  return (
    
    <Modal
      open={open}
      handleClose={()=> { closeModal(false) }}
      size="xs"
    >
      <ModalHeader
        icon={<FolderIcon />}
        text={dFolder ? "Detalle" : "Nueva Carpeta"}
        className='positionElements'
        onCancel={()=> { closeModal(false) }}
      >
      </ModalHeader>

      <ModalBody>
        
        <Formik initialValues={data} enableReinitialize validate={ (values) => { validateForm(values) }} onSubmit={onSubmitForm}>
          {
            ({handleChange, handleSubmit, setFieldValue, values, errors, touched}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} alignItems="center" justify="center">
                    { !dFolder && (
                        <Grid item xs={12} container>
                          <TextField
                            id="name"
                            type="text" 
                            value={values.name}
                            onChange={handleChange}
                            error={ errors.name && touched.name ? true : false }
                            placeholder="Nombre de la carpeta" 
                            fullWidth
                            style={{padding: "5px"}}
                          />
                        </Grid>
                     )
                    }
                    { 
                      !isConfig &&(idRole == 2) && (data.path != "/compartidos-empresa") && (
                        <Grid item xs={12} container>
                          <Grid item xs={6} className="item-center">
                            <Typography variant="body1">Compartir con la empresa: </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              checked={values.sharedCompany}
                              onChange={() => { setFieldValue("sharedCompany", !values.sharedCompany) }}
                            />
                          </Grid>
                        </Grid>
                      )
                    }
                    {
                      dFolder && (
                        <>
                        <Grid item xs={12} container>
                          <Grid item xs={6} className="item-center">
                              <Typography variant="body1">Nombre: </Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  value={dFolder.name}
                                  fullWidth
                                  disabled
                              />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                          <Grid item xs={6} className="item-center">
                              <Typography variant="body1">Número de Archivos: </Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  value={dFolder.numFiles}
                                  fullWidth
                                  disabled
                              />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                          <Grid item xs={6} className="item-center">
                              <Typography variant="body1">Tamaño (Bytes): </Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  value={dFolder.size}
                                  fullWidth
                                  disabled
                              />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                          <Grid item xs={6} className="item-center">
                              <Typography variant="body1">Estado: </Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  value={dFolder.status === 1 ? "Activo" : "Inactivo"}
                                  fullWidth
                                  disabled
                              />
                          </Grid>
                        </Grid>
                        </>
                      )
                    }
                  </Grid>

                  {
                    !dFolder && (
                      <ModalFooter 
                        confirmText="CREAR"
                        cancelText="CANCELAR"
                        onCancel={()=> { closeModal(false) }}
                        onConfirm={handleSubmit}
                        className="footerRight"
                      />
                    )
                  }

                </form>
              )
            }
          }
        </Formik>

      </ModalBody>

      
    </Modal>
    
  )
}