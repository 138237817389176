import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import CustomFolder from './CustomFolder';
import { ModalRenameFolder } from 'components/Modals/ModalRenameFolder';
import { ModalShareFolder } from 'components/Modals/ModalShareFolder';
import { ModalDeleteFolder } from 'components/Modals/ModalDeleteFolder';

const CustomFolders = (props) => {
    const { dFolders=[], title, refreshList, showBreadCrumb, order } = props;

    const [selectedDFolder, setSelectedDFolder] = React.useState(null);
    const [showRenameModal, setShowRenameModal] = React.useState(false);
    const [showShareModal, setShowShareModal] = React.useState(false);
    const [showDeleteModal, setshowDeleteModal ] = React.useState(false);

    const onRename = (selectedFolder) => {
        setSelectedDFolder(selectedFolder);
        setShowRenameModal(true);
    }

    const onImportant = (selectedFolder) => {
        console.log("onImportant", selectedFolder);
    }

    const onDeleted = (selectedFolder) => {
        setSelectedDFolder(selectedFolder);
        setshowDeleteModal(true);
    }

    const onShare = (selectedFolder) => {
        setSelectedDFolder(selectedFolder);
        setShowShareModal(true);
    }

    return(
        <Grid container item xs={12} className="folder_container">
            {
                dFolders.length > 0  && (
                    <>
                       <Grid item xs={12}>
                            <Typography> { title } </Typography>
                        </Grid>
                        {
                            dFolders.map((item, i) => {
                                return (
                                    <CustomFolder
                                        dFolder={item}
                                        key={i}
                                        keyId={i}
                                        onRename={onRename}
                                        onImportant={onImportant}
                                        onDeleted={onDeleted}
                                        onShare={onShare}
                                        showBreadCrumb={showBreadCrumb}
                                    />
                                )
                            })
                        }
                    </>
                )
            }
            <ModalRenameFolder
                {...props}
                open={showRenameModal}
                dFolder={selectedDFolder}
                handleClose={ () => { setShowRenameModal(false) } }
                handleConfirmClose={() => { 
                    setShowRenameModal(false);
                    refreshList && refreshList(order);
                 }}
            />
            <ModalShareFolder
                {...props}
                open={showShareModal}
                dFolder={selectedDFolder}
                handleClose={ () => { setShowShareModal(false) } }
                handleConfirmClose={() => { 
                    setShowShareModal(false);
                    refreshList && refreshList(order);
                 }}
            />
            <ModalDeleteFolder
                {...props}
                open={showDeleteModal}
                dFolder={selectedDFolder}
                handleClose={ () => { setshowDeleteModal(false) } }
                handleConfirmClose={() => { 
                    setshowDeleteModal(false);
                    refreshList && refreshList(order);
                 }}
            />
        </Grid>
    );
}
export default CustomFolders;