import React, { Component } from 'react';
import { Input, InputAdornment, Typography, Grid, IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import BasePage from 'pages/BasePage';
import 'assets/styles/docset-app.css';
import AddIcon from '@material-ui/icons/Add';
import 'assets/styles/docset-app.scss';
import  CustomTable  from 'components/CustomTable'
import { ModalCompany } from 'components/Modals/ModalCompany';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { listCompanies } from 'services/actions/CompanyAction';
import { getSessionInfo } from 'utils/common';
import { ModalConfirmDeleteCompany } from 'components/Modals/ModalConfirmDeleteCompany';

const columns = [
    { type: 'text', field: 'name', label: 'Nombre' },
    { type: 'text', field: 'rut', label: 'RUT' },
    { type: 'text', field: 'address', label: 'Dirección' },
    { type: 'text', field: 'adminName', label: 'Administrador' },
    { type: 'text', field: 'email', label: 'Correo' },
    { type: 'options', field: 'options', label: 'Opciones' }
    
];

class CompanyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCompanyModal:false,
            selectedCompany:null,
            editMode:false,
            showModalDelete:false,
            companies: [],
            search: "",
            searchTimeout: null,
        };
    }
    
    componentDidMount(){
        const session = getSessionInfo();
        const adminRole = session && session.role || {};
        const isUserAdmin = adminRole.name == 'Admin';
        isUserAdmin ? this.onListCompanies() : this.props.history.push('/');
    }

    onListCompanies = (term) => {
        this.props.dispatch(showBackdrop(true));
        this.props.dispatch(listCompanies(term)).then(res =>{
            this.setState({companies: res || []});
            this.props.dispatch(showBackdrop(false));
        }).catch(err => this.props.dispatch(showBackdrop(false)) );
    }

    onDetails = (selectedCompany) => {
        this.setState({
            editMode:false,
            selectedCompany:selectedCompany,
            showCompanyModal:true
        });
    }

    openModalDelete = (selectedCompany) => {
        this.setState({
            selectedCompany: selectedCompany,
            showModalDelete: true
        });
    }

    closeCompanyModal = () => {
        this.setState({showCompanyModal:false});
    }

    openCompanyModal = () => {
        this.setState({
            editMode:true,
            selectedCompany: null,
            showCompanyModal:true
        });
    }

    onEdit = (selectedCompany) => {
        this.setState({
            editMode:true,
            selectedCompany:selectedCompany,
            showCompanyModal:true
        });
      }

    onConfirmCompany = () => {
        this.closeCompanyModal();
        this.onListCompanies(this.state.search || "");
    }

    onSearch = (term) => {
      clearTimeout(this.state.searchTimeout)
      this.setState({search: term});
      this.setState({ searchTimeout: setTimeout(() => { this.onListCompanies(term)}, 1000) })
    };

    render(){
        const { showCompanyModal, selectedCompany, showModalDelete, editMode, companies=[] } = this.state;
        
        return(
            <BasePage>
                <Grid container className="design_body">
                    <Grid container item >
                        <Grid item xs={12} sm={6} md={3} style={{height:'70%'}}>
                            <div className="design_body_2">
                                <div className="add">
                                <Typography className="page_title" variant="h5" gutterBottom>
                                    <strong>EMPRESAS</strong>
                                </Typography>
                                <Button className="btnAdd" onClick={ this.openCompanyModal }>
                                    <AddIcon className="icoAdd"/>
                                </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid> 
                    <Grid item xs={4}>
                      <Input
                        fullWidth
                        className="search_wrap"
                        type="text"
                        placeholder="Buscar..."
                        disableUnderline
                        value={this.state.search}
                        onChange={event => this.onSearch(event.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton type="button" aria-label="search">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="designdiv2">
                        <CustomTable 
                            columns={columns} 
                            rows={companies || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDetails={this.onDetails}
                            onDelete={this.openModalDelete}
                            onEdit={this.onEdit}
                        />
                        </div>
                    </Grid>
                </Grid>
                {
                    showCompanyModal && (
                        <ModalCompany
                            {...this.props}
                            open={showCompanyModal}
                            handleClose={()=>{this.closeCompanyModal()}}
                            onConfirmCallBack={this.onConfirmCompany}
                            company={selectedCompany}
                            editMode={editMode}
                        />
                    )
                }
                <ModalConfirmDeleteCompany
                    {...this.props}
                    open={showModalDelete}
                    closeModal={()=> {this.setState({showModalDelete: false})}}
                    company={selectedCompany}
                    onListCompany={this.onListCompanies}
                />
            </BasePage>
        );
    }
}

export default CompanyPage;