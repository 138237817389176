import { Button, DialogActions, withStyles } from '@material-ui/core';
import React from 'react'

const ModalFooter = (props) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType,
    secondaryText, onSecondaryConfirm
  } = props;

    return (
      <DialogActions className={className || "modal-footer"}>
        {
          cancelText && (
            <Button onClick={onCancel} size="small" color={cancelColor || "secondary"} variant="contained" className={backgroundColor || ''}>
              {cancelText}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} type={buttonType} size="small" color={confirmColor || "primary"} autoFocus variant="contained">
              {confirmText}
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" color={confirmColor || "primary"} autoFocus variant="outlined">
              {secondaryText}
            </Button>
          )
        }
      </DialogActions>
    )
}

export default ModalFooter
