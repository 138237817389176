import React, { Component } from 'react';
import { Grid, Typography, Button, TextField  } from "@material-ui/core";
import BasePage from 'pages/BasePage';
import 'assets/styles/docset-app.css';
import ReactCustomizableProgressbar from 'react-customizable-progressbar';
import { getSessionInfo } from 'utils/common';
import { findCompany } from 'services/actions/CompanyAction';
import { verifyUserRole } from 'services/actions/UserAction';
import { ModalCompany } from 'components/Modals/ModalCompany';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import AddIcon from '@material-ui/icons/Add';
import  CustomTable  from 'components/CustomTable'
import { ModalAddFolder } from 'components/Modals/ModalAddFolder';
import { listDFolderConfig, listMyFolders, deleteDFolder } from 'services/actions/DFolderAction';
import { ModalRenameFolder } from 'components/Modals/ModalRenameFolder';
import { ModalDeleteFolder } from 'components/Modals/ModalDeleteFolder';

const columns = [
  { type: 'text', field: 'name', label: 'Nombre'},
  { type: 'text', field: 'numFiles', label: 'N° de Archivos', format:(row) => `${row.numFiles || "0"}`},
  { type: 'text', field: 'size', label: 'Tamaño (Bytes)', format:(row) => `${row.size || "0"}`},
  { type: 'text', field: 'status', label: 'Estado', format:(row) => `${row.status === 1 ? "activo" : "inactivo"}`},
  { type: 'options', field: 'options', label: 'Opciones'}
];


const Kb = Math.pow(10, -3);
const Mb = Math.pow(10, -6);
const Gb = Math.pow(10, -9);

class SettingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showFolderModal: false,
      showRenameFolderModal: false,
      showDetailsFolderModal:false,
      showDeleteFolderModal:false,
      showCompanyModal:false,
      currentPath: window.location.pathname,
      editMode:true,
      selectedDFolder:null,
      isConfig: true,
      dFolders: [],
      company: {},
    }
  }
  
  componentDidMount(){
    this.validateAccess("AdminEmpresa"); 
  }

  openFolderModal = () => {
    this.setState({
      showFolderModal:true,
    })
  }

  closeFolderModal = (refresh) => {
    this.setState({showFolderModal:false});
    this.setState({showDetailsFolderModal:false});
    if(refresh){
      this.getMyFolders();
    }
  }

  onDetails = (selectedDFolder) => {
    this.setState({
      editMode:false,
      selectedDFolder:selectedDFolder,
      showDetailsFolderModal:true
    });
  }
  onEdit = (selectedDFolder) => {
    this.setState({
      editMode:true,
      selectedDFolder:selectedDFolder,
      showRenameFolderModal:true
    });
  }
  onDelete = (selectedDFolder) => {
    this.setState({
      selectedDFolder:selectedDFolder,
      showDeleteFolderModal:true
    })
    /* this.props.dispatch(showBackdrop(true));
    this.props.dispatch(deleteDFolder(selectedDFolder.id)).then((res) => {
      this.props.dispatch(showBackdrop(false));
        this.getMyFolders();
        this.props.dispatch(showSnackBar("error", "Carpeta Eliminada"));
      }).catch(err => {
        this.props.dispatch(showSnackBar("warning", err.response.data.error || "No se pudo Eliminar la Carpeta"));
        this.props.dispatch(showBackdrop(false));
      }); */
  }

  closeFolderModalEdit = () => {
    this.setState({showRenameFolderModal:false})
  }


  getMyFolders = (sortBy) => {
    const { computedMatch={} } = this.props;
    const { params={} } = computedMatch;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listDFolderConfig("", params.idFolder || "", sortBy || "")).then(res => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ dFolders: res });
    })  
  }

  onFindCompany = (idCompany) =>{
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(findCompany(idCompany)).then(res =>{
      this.setState({company: res || {}});
      this.props.dispatch(showBackdrop(false));
    }).catch(err => this.props.dispatch(showBackdrop(false)) );
  }

  closeCompanyModal = () => {
    this.setState({
      showCompanyModal:false,
      showDeleteFolderModal:false
    });
  }

  openCompanyModal = () => {
    this.setState({
      showCompanyModal:true
    });
  }

  onConfirmCompany = () => {
    this.closeCompanyModal();
    this.onFindCompany(this.state.company.id);
  }

  getStorage(storage){
    let currentStorage = 0;
    if(storage <= 999){
      return `${storage}Byte`
    }
    if(storage <= 99999){
      currentStorage = Math.round((storage * Kb));
      return `${currentStorage}Kb`
    }
    if(storage <= 99999999){
      currentStorage = Math.round((storage * Mb));
      return `${currentStorage}Mb`
    }
    if(storage >= 1000000000){
      currentStorage = Math.round((storage * Gb));
      return `${currentStorage}GB`
    }
  }

  getUsedStorage(storage){
    let currentUsedStorage = 0;

    if(storage <= 999){
      return `${storage}Byte`
    }
    if(storage <= 99999){
      currentUsedStorage = Math.round((storage * Kb));
      return `${currentUsedStorage}Kb`
    }
    if(storage <= 99999999){
      currentUsedStorage = Math.round((storage * Mb));
      return `${currentUsedStorage}Mb`
    }
    if(storage >= 1000000000){
      currentUsedStorage = Math.round((storage * Gb));
      return `${currentUsedStorage}GB`
    }
  }

  getPercentStorage(usedStorage, storage){
    let percentStorage = 0;
    percentStorage = Math.ceil((usedStorage / storage) * 100)
    return percentStorage;
  }

  validateAccess = (term, idRole) => {
    const session = getSessionInfo();
    const idCompany = session.user.idCompany;

    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(verifyUserRole(term || "", idRole || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.onFindCompany(idCompany);
      this.getMyFolders();
    }).catch(err => {
      this.props.history.push('/mi-carpeta');
      this.props.dispatch(showSnackBar("warning", "Ud. no tiene accesso a esta vista"));
      this.props.dispatch(showBackdrop(false));
    });
  }

  render() {
    const {showCompanyModal, company={},  dFolders=[], showFolderModal, currentPath="", selectedDFolder, showRenameFolderModal, showDetailsFolderModal, showDeleteFolderModal} = this.state;

    return (
      <BasePage>
        <Grid container className="design_body">
          <Grid item xs={12} sm={6} >
              <Grid container className="design_body_2">
                <div className="">
                  <Typography className="page_title" variant="h5" gutterBottom>
                    <strong>Configuración de la Empresa</strong>
                  </Typography>
                </div>
              </Grid> 
          </Grid>

          <Grid container className="design_body_2" style={{paddingTop: '20px'}}>

            <Grid container className="design_body_2" style={content}>

              <Grid item >
                <ReactCustomizableProgressbar
                  progress={company.storage ? (this.getPercentStorage(company.usedStorage, company.storage)) : 0}
                  radius={40}
                  strokeLinecap={'round'}
                  transition={'0.3s ease'}
                  trackStrokeLinecap={'round'}
                  trackTransition={'1s ease'}
                  initialAnimation={true}
                  initialAnimationDelay={1}
                  strokeWidth={8}
                  trackStrokeWidth={8}
                  strokeColor={'#f4ce4a'}
                />
              </Grid>

              <Grid item style={{textAlign: 'center', marginRight:'35px'}}>
                <Typography className="" variant="subtitle1">Porcentaje usado</Typography>
                <Typography className="" variant="h6" >
                      <span>
                        {
                          company.storage ?
                          (this.getPercentStorage(company.usedStorage, company.storage)) : (0)
                        }
                        %
                      </span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography className="" variant="subtitle1" >Almacenamiento
                      </Typography>
                      <Typography className="" variant="h6">
                        <span style={{color: "#0acb0a"}}>
                          {company.storage ? (this.getUsedStorage(company.usedStorage)) : '0 Byte' } 
                        </span> 
                        / 
                        <span>
                          {company.storage ? (this.getStorage(company.storage)) : '0 Byte'} 
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
              </Grid>

              <Button color="primary" style={button} >
                Ampliar espacio
              </Button>

            </Grid>

            <Grid style={{marginTop: '25px', marginBottom: '18px'}}>
                  <Typography className="" variant="h6">Información de la Empresa
                  </Typography>
            </Grid>

            <Grid container className="design_body_2"  style={content2}>
               
              <Grid item xs={12} sm container >
                <Grid item xs container direction="column">
                    <Grid item xs>
                      <TextField
                        label="Administrador"
                        id="standard-size-normal"
                        value={`${company.adminName}`}
                        variant="standard"
                        disabled
                        style={text}
                      /> 
                      <TextField
                        label="Dirección"
                        id="standard-size-normal"
                        value={`${company.address}`}
                        variant="standard"
                        disabled
                        style={text}
                      /> 
                    </Grid>

                    <Grid item xs>
                      <TextField
                        label="Correo"
                        id="standard-size-normal"
                        value={`${company.email}`}
                        variant="standard"
                        disabled
                        style={text}
                      /> 
                      <TextField
                        label="N° Teléfono"
                        id="standard-size-normal"
                        value={`${company.phone}`}
                        variant="standard"
                        disabled
                        style={text}
                      /> 
                    </Grid>

                    <Grid item xs>
                      <TextField
                        label="Descripción"
                        id="standard-size-normal"
                        value={`${company.description}`}
                        variant="standard"
                        fullWidth
                        disabled
                        style={text}
                      /> 
                    </Grid>
                </Grid>
              </Grid>

              <Button color="primary" style={btnEdit} onClick={ this.openCompanyModal }>
                Editar Empresa
              </Button>

            </Grid>

            <Grid style={{marginTop: '25px', marginBottom: '18px'}}>
              <div className="add">
                  <Typography className="" variant="h6">Carpetas Compartidas
                  </Typography>
                  <Button className="btnConfigFolder" onClick={this.openFolderModal}>
                    <AddIcon className="icoAdd"/>
                  </Button>
              </div>
            </Grid>

            <CustomTable 
              columns={columns} 
              rows={dFolders || []}
              onRowClick={() => {}}
              hasOptions
              onDetails={this.onDetails}
              onDelete={this.onDelete}
              onEdit={this.onEdit}
            /> 

            {/* <Grid container className="design_body_2">
              <Grid item xs={12} sm container style={{textAlign: 'center'}}>
                <Grid item xs container direction="column">
                  <Grid item xs>
                      <TextField
                        label="N° máximo de Usuarios"
                        id="outlined-size-normal"
                        value={`50`}
                        variant="outlined"
                        disabled
                        style={text}
                      /> 
                      <TextField
                        label="N° de Usuarios registrados"
                        id="outlined-size-normal"
                        value={`15`}
                        variant="outlined"
                        disabled
                        style={text}
                      /> 
                      <TextField
                        label="Almacenamiento por Usuario"
                        id="outlined-size-normal"
                        value={`18 GB`}
                        variant="outlined"
                        disabled
                        style={text}
                      /> 
                  </Grid>   
                </Grid>   
              </Grid>
            </Grid> */}

          </Grid>  
        </Grid> 

        {
          showCompanyModal && (
              <ModalCompany
                  {...this.props}
                  open={showCompanyModal}
                  handleClose={()=>{this.closeCompanyModal()}}
                  onConfirmCallBack={this.onConfirmCompany}
                  company={company}
                  editMode
                  isConfig
              />
          )
        }  
        { 
          showFolderModal && (
            <ModalAddFolder
              {...this.props}
              open={showFolderModal}
              isConfig
              currentPath={currentPath}
              closeModal={this.closeFolderModal}
            />
          )
        }
        {
          showDetailsFolderModal && (
            <ModalAddFolder
              {...this.props}
              open={showDetailsFolderModal}
              isConfig
              currentPath={currentPath}
              dFolder={selectedDFolder}
              closeModal={this.closeFolderModal}
            />
          ) 
        }
        {
          showRenameFolderModal && (
            <ModalRenameFolder
              {...this.props}
              open={showRenameFolderModal}
              editMode
              dFolder={selectedDFolder}
              handleClose={this.closeFolderModalEdit}
              handleConfirmClose={() => { 
                  this.closeFolderModalEdit();
                  this.getMyFolders();
              }}
            />
          )
        }
        {
          showDeleteFolderModal && (
            <ModalDeleteFolder  
              {...this.props}
              open={showDeleteFolderModal}
              dFolder={selectedDFolder}
              handleClose={this.closeCompanyModal}
              handleConfirmClose={() => { 
                this.closeCompanyModal();
                this.getMyFolders();
              }}
            />
          )
        }
      </BasePage>
    );
  }
}


const content = {
  border: '1px solid #999',
  borderRadius: '8px',
  paddingTop: 0,
  marginTop: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const content2 = {
  borderTop: '1px solid #999',
  borderBottom: '1px solid #999',
  paddingTop: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}


const button = {
  color: '#fff',
  background: '#08cf58',
  borderRadius: 5,
  width: "200px",
  height: "50px",
  paddingTop: "10px"
}

const btnEdit = {
  color: '#fff',
  background: '#cf7e05 ',
  borderRadius: 5,
  width: "200px",
  height: "50px",
  paddingTop: "10px"
}

const text = {
  width: "320px",
  paddingRight: "50px",
  paddingBottom: "30px",
  color: 'black',
}

export default SettingPage;
