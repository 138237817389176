import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import 'assets/styles/docset-app.css';

class BasePage extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid container style={{paddingLeft: "100px", height: "100hv"}} className="basePage">
        <Grid item xs={12}  className="scrolly">
            { this.props.children }
        </Grid>
      </Grid>
    );
  }
}

export default BasePage;
