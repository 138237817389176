import axios from 'axios';
import config from "../../config/Config";

class AuthService {

    async renewToken() {
        return await axios.get(
            config.apiVersion + `mgmt/session/renew`,
            { 
                headers: {
                    ...config.headers, 
                    Authorization: `${process.env.REACT_APP_TOKEN || "Bearer"} ${JSON.parse(localStorage.getItem('session')).accessToken || ''}` 
                }
            }
    )}
   
}

export default AuthService;