import React, {Component} from 'react';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { listSection } from 'services/actions/SectionAction';
import { listItem } from 'services/actions/ItemAction'
import { createTemplate, updateTemplate } from 'services/actions/TemplateAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import BasePage from 'pages/BasePage';
import Select  from 'react-select';
import  CustomTable  from 'components/CustomTable';
import { Delete, Add, Edit } from '@material-ui/icons';
import { ModalSection } from 'components/Modals/ModalSection';
import { ModalDeleteSection } from 'components/Modals/ModalDeleteSection';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import { ModalTemplates } from 'components/Modals/ModalTemplates';
import { findTemplate, verifyTemplate } from 'services/actions/TemplateAction';
import { ModalItem } from 'components/Modals/ModalItem';
import {ModalDeleteItem} from 'components/Modals/ModalDeleteItem';

const columns = [
  { type: 'text', field: 'position', label: 'Posición' },
  { type: 'text', field: 'category', label: 'Categoría' },
  { type: 'text', field: 'item', label: 'Item', format: (row) => `${row.componentMapping && row.componentMapping.name}` },
  { type: 'options', field: 'options', label: 'Opciones' }
];

class GenerateDocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showSectionModal: false,
          showItemModal: false,
          showDeleteSectionModal: false,
          showModalTemplates: false,
          showDeleteItemModal: false,
          disableButton: true,
          editMode: false,
          editModeItem: false,
          templateName: "",
          sections: [],
          idSection: {value: null, label: 'Seleccionar Sección...'},
          items: [],
          idItem: null,
          addNewTemplate:false,
          searchTimeout: null,
          idTemplate: null
        }
    }

    async componentDidMount(){
      this.validateOpenTemplate();
    }

    async componentDidUpdate(prevProps) {
      if( prevProps.location.pathname !== this.props.location.pathname ){
        this.validateOpenTemplate();        
        //this.getTemplateOld();
      }
    }

    validateOpenTemplate = () => {
      const path = this.props.location.pathname;
      const split = path.split('/');
      const id = split && split[2];

      if (id) {
        this.props.dispatch(showBackdrop(true));
        this.props.dispatch(verifyTemplate(id)).then((res) => {
          this.props.dispatch(showBackdrop(false));
          this.getSections();
          this.getItems();
          this.setState({ idTemplate: res.id, templateName: res.name, addNewTemplate:true });
        }).catch(err => {
          this.props.history.push('/generar-documento');
          this.props.dispatch(showSnackBar("warning", err.response.data.error || "Plantilla no Encontrada"));
          this.props.dispatch(showBackdrop(false));
        });
      } else {
        this.getSections();
        this.getItems();
      }
    }

    getSections = () => {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(listSection("","")).then(res => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ sections: this.getCustomSections(res)});
      })
    }

    getItems = () => {
      this.props.dispatch(showBackdrop(true));
      this.props.dispatch(listItem("","","")).then(res => {
        this.props.dispatch(showBackdrop(false));
        this.setState({ items: res});
      })
    }

    addSectionModal = () => {
      this.setState({showSectionModal:true, editMode: false})
    }

    showAddNewTemplate = ()=>{
      this.setState({addNewTemplate:true})
    }

    addItemModal = () => {
      this.setState({showItemModal:true})
    }

    editSectionModal = () => {
      if (!this.state.idSection.value) {
        this.props.dispatch(showSnackBar('error', 'Seleccione una Sección'));
      }else{
        this.setState({showSectionModal:true, editMode: true})
      }
    }

    deleteSectionModal = () => {
      if (!this.state.idSection.value) {
        this.props.dispatch(showSnackBar('error', 'Seleccione una Sección'));
      }else{
        this.setState({showDeleteSectionModal:true})
      }
    }

    closeSectionModal = (refresh) => {
      this.setState({showSectionModal:false, idSection: {value: null, label: 'Seleccionar Sección...'}});
      if(refresh){
        this.getSections();
      }
    }

    closeItemModal = () => {
      this.setState({showItemModal:false, editModeItem: false, idItem: null});
    }

    confirmCloseItemModal = () => {
      this.setState({showItemModal:false,  editModeItem: false, idItem: null});
      this.getItems();
    }

    confirmCloseDeleteSectionModal = () => {
      this.setState({showDeleteSectionModal:false, sections:[], idSection: {value: null, label: 'Seleccionar Sección...'}});
      this.getSections();
    }

    openModalDeleteItem = (row) => {
      this.setState({showDeleteItemModal:true, idItem: row.id});
    }

    editItemModal = (row) => {
      this.setState({showItemModal:true, idItem: row.id, editModeItem: true})
    }

    confirmCloseDeleteItemModal = () => {
      this.setState({showDeleteItemModal:false});
      this.getItems();
    }

    getCustomSections = (originalSections) => {
      return originalSections ? originalSections.map(section => ({value: section.id, label: section.name})) : [];
    }

    changeInputTemplate = (event) => {
      clearTimeout(this.state.searchTimeout)
      this.setState({ templateName: event.target.value});
      this.setState({ 
        searchTimeout: setTimeout(() => {
              this.createTemplate()
        }, 1000)
      });
    }

    createTemplate = () => {
      const idTemplate = this.state.idTemplate;
      
      this.props.dispatch(showBackdrop(true));
      if (idTemplate) {
        this.props.dispatch(updateTemplate(idTemplate, {name: this.state.templateName})).then(res => {
          this.props.dispatch(showBackdrop(false));
          this.props.dispatch(showSnackBar("success", "Plantilla Actualizada"));
        });
      }else{
        this.props.dispatch(createTemplate({name: this.state.templateName})).then(res => {
          this.props.dispatch(showBackdrop(false));
          this.setState({ idTemplate: res.template.id })
          this.props.dispatch(showSnackBar("success", "Plantilla Creada"));
        });
      }
    }

    handleChange = (option) => {
      this.setState({idSection: {value: option.value, label: option.label}});
    }

    showTemplateModal = () => {
      this.setState({showModalTemplates:true})
    }

    closeTemplateModal = () => {
      this.setState({showModalTemplates:false})
    }

    render(){
      const { sections=[], idSection, idItem, items=[], templateName, showSectionModal, showDeleteSectionModal, showDeleteItemModal, showItemModal, editMode, editModeItem, showModalTemplates, idTemplate } = this.state;

        return (
            <BasePage>

                {<Grid container  className={this.state.addNewTemplate?"stateTemplate":""}  display="flex" alignItems='center' direction='row' spacing={0} justifyContent='center' style={{ minHeight: '100vh' }}>
                  <Grid item xs={12} md={6} style={{textAlign: 'right', marginTop:'-30px'}} >
                    <Button variant="outlined" onClick={this.showAddNewTemplate} endIcon={<AddIcon style={{fontSize:'50px', color:'#008f39'}} />} style={{marginRight:'15px', background:'#fff',color:'#000', paddingTop:'50px',paddingBottom:'50px' }}  >
                      Nueva Plantilla
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} style={{textAlign: 'left',marginTop:'-30px'}}  >
                    <Button variant="outlined" onClick={this.showTemplateModal} endIcon={<DescriptionIcon style={{fontSize:'50px',color:'#00528f'}} />} style={{marginLeft:'15px',background:'#fff',color:'#000', paddingTop:'50px',paddingBottom:'50px'}}>
                      Usar Plantilla Antigua
                    </Button>
                  </Grid>
                </Grid>}

                { <Grid className={this.state.addNewTemplate?"design_body_2":"stateTemplate"}>
                  <Grid container spacing={3} style={{height: "80vh" }}>
                    <Grid containter item xs={12} md={12} lg={6} xl={6} >
                      <Grid item xs={12} >
                        <Typography> <strong>Generar Documento</strong></Typography> 
                        <hr/>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          id="templateName"
                          type="text"
                          value={templateName}
                          onChange={this.changeInputTemplate}
                          placeholder="Ingresar título"
                          size="small"
                          variant="outlined"
                          fullWidth
                          style={{background:"#fff", marginTop:"12px", marginBottom:"15px"}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography> <strong>Secciones: </strong></Typography>
                      </Grid>
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                          <Select
                            value={idSection}
                            options={sections || []} 
                            isSearchable={false}
                            onChange={this.handleChange}                             
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Button className="btn_section" style={{ backgroundColor: "green"}} onClick={this.addSectionModal} disabled={!idTemplate}>
                            <Add className="icoAdd"  />
                          </Button>
                          <Button className="btn_section" style={{ backgroundColor: "yellow"}} onClick={this.editSectionModal} disabled={!idTemplate}>
                            <Edit  />
                          </Button>
                          <Button className="btn_section" style={{ backgroundColor: "red"}} onClick={this.deleteSectionModal} disabled={!idTemplate}>
                            <Delete className="icoAdd"  />
                          </Button>
                        </Grid>
                      </Grid>
                      <br/>
                      <hr/>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Typography> <strong>Items</strong></Typography> 
                        </Grid>
                        <Grid item xs={12}>
                          <br/>
                          <Button className="btn_item" style={{ backgroundColor: "green"}} onClick={this.addItemModal}>
                            <Add className="icoAdd" />
                          </Button>
                          <CustomTable 
                            columns={columns} 
                            rows={items || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDelete={this.openModalDeleteItem}
                            onEdit={this.editItemModal}
                          />
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    <Grid container item xs={12} md={12} lg={6} xl={6} style={{background:"#fff"}}>

                    </Grid>
                  </Grid>
                </Grid>}

              {
                showSectionModal && (
                  <ModalSection
                    {...this.props}
                    open={showSectionModal}
                    closeModal={this.closeSectionModal}
                    editMode={editMode}
                    idSection={editMode && idSection.value}
                    idTemplate={idTemplate}
                  />
                )
              }

              {
                showItemModal && (
                  <ModalItem
                    {...this.props}
                    open={showItemModal}
                    closeModal={this.closeItemModal}
                    confirmCloseModal={this.confirmCloseItemModal}
                    editMode={editModeItem}
                    idItem={editModeItem && idItem}
                  />
                )
              }

              {
                showItemModal && (
                  <ModalItem
                    {...this.props}
                    open={showItemModal}
                    closeModal={this.closeItemModal}
                    confirmCloseModal={this.confirmCloseItemModal}
                    editMode={editModeItem}
                    idItem={editModeItem && idItem}
                  />
                )
              }

              {
                showDeleteSectionModal && (
                  <ModalDeleteSection
                    {...this.props}
                    open={showDeleteSectionModal}
                    closeModal={ () => {this.setState({showDeleteSectionModal: false})}}
                    confirmCloseModal={this.confirmCloseDeleteSectionModal}
                    idSection={idSection.value}
                  />
                )
              }

              {
                showModalTemplates && (
                  <ModalTemplates
                    {...this.props}
                    open={showModalTemplates}
                    closeModal={ () => {this.setState({showModalTemplates: false})}}
                    showAddNewTemplate={this.showAddNewTemplate}
                  />
                )
              }
              {
                showDeleteItemModal && (
                  <ModalDeleteItem
                    {...this.props}
                    open={showDeleteItemModal}
                    closeModal={ () => {this.setState({showDeleteItemModal: false})}}
                    confirmCloseModal={this.confirmCloseDeleteItemModal}
                    idItem={idItem}
                  />
                )
              }

            </BasePage>
        )
    }
}

export default GenerateDocumentPage;