import { Grid, Button, Typography, Tooltip, colors } from '@material-ui/core';
import ReactToolTip from 'react-tooltip';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import SortSelect from './SortSelect';

const CustomHeader = props => {
    const { title, note, showAddIcon, addTooltipText, onAddClick, getMyFiles, showSort=true, getMyFolders, defaultOrder } = props;

    const currentRoute = window.location.pathname;

    return (
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={7}>
            <div className="add">
              <Typography className="page_title" > <strong>{ title }</strong></Typography>
              {
                showAddIcon && (
                  addTooltipText ?
                  <>
                    <Tooltip title={addTooltipText} placement='right'>
                      <Button className="btnAdd" onClick={onAddClick}>
                          <AddIcon className="icoAdd"  />
                      </Button>
                    </Tooltip>
                  </>:
                  <>
                    <Button className="btnAdd" onClick={onAddClick} data-tip data-for="addIcon">
                        <AddIcon className="icoAdd"  />
                    </Button>
                  </>
                )
              }
            </div>
          </Grid>
          {
            showSort && currentRoute != '/generar-documento' && (
              <Grid item xs={5}>
                <SortSelect
                  {...props}
                  getMyFiles={getMyFiles}
                  getMyFolders={getMyFolders}
                  defaultOrder={defaultOrder}
                />  
              </Grid>
            )
          }
        </Grid>
        {
          note && (
            <Grid container item xs={9}>
              <Typography className="page_note" > <strong>{ note }</strong></Typography>
            </Grid>
          )
        }
        
      </Grid>
    )
}

export default CustomHeader;