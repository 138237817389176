import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Box, Grid, InputAdornment, makeStyles, TextField, Typography, withStyles } from '@material-ui/core';
import { pColor } from 'assets/styles/docset-css';
import { getSessionInfo } from 'utils/common';
import { updatePassword } from 'services/actions/UserAction';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import 'assets/styles/docset-app.scss';

const useStyles = makeStyles(theme => ({
    typography: {
      align: 'justify',
    },
    button: {
      border: '3px solid',
      borderRadius: '50px',
      color: 'white'
    },
}));

const CssTextField = withStyles({
root: {
    '& .MuiInput-underline:after': {
    // borderBottomColor: pColor,
    },
    '& .MuiOutlinedInput-root': {
    '& fieldset': {
        borderColor: 'black',
    },
    '&:hover fieldset': {
        borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
        borderColor: pColor,
    },
    },
},
})(TextField);

export const ModalUpdatePassword = (props) => {

    const { open, handleClose, handleSessionClose } = props;
    const classes = useStyles();
    const session = getSessionInfo();
    const user = session && session.user || {};

    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [verifyPassword, setVerifyPassword] = React.useState("");
    const [errors, setErrors] = React.useState({});

    const [showPassword, setShowPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);


  const onChangeOldPassword = (e) => {
    const oldPassword = e.target.value;
    setOldPassword(oldPassword);
  };

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const onChangeVerifyPassword = (e) => {
    const verifyPassword = e.target.value;
    setVerifyPassword(verifyPassword);
  };


  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //Password
    if (!oldPassword) {
      formIsValid = false;
      errors["oldPassword"] = "Contraseña requerida";
    }

    //new Password
    if (!newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Contraseña requerida";
    }

    if (!verifyPassword) {
      formIsValid = false;
      errors["verifyPassword"] = "Contraseña requerida";
    }
    if (newPassword != verifyPassword) {
      formIsValid = false;
      errors["verifyPassword"] = "Las contraseñas no coinciden";
    }
    setErrors(errors);
    return formIsValid;
  }
  const body = {
    password: oldPassword,
    encrypted_password: newPassword

  }
  const clearModal = () => {
    setOldPassword('');
    setNewPassword('');
    setVerifyPassword('');
    setShowPassword(false);
    setShowNewPassword(false);
    setShowVerifyPassword(false);
    handleClose(false);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      props.dispatch(showBackdrop(true));
      props.dispatch(updatePassword(user.id, body)).then( res => {
        clearModal();
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar('success', 'Cambio de contraseña exitosa.'));
        handleSessionClose && handleSessionClose();
      }
      ).catch(err => {
        props.dispatch(showSnackBar("error", err.response.data ? err.response.data.error : "ERROR"));
      });   
    }   
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      size="sm"
    >
      <ModalHeader
        icon={<VpnKeyIcon />}
        text="Cambiar Contraseña"
        className='positionElements'
        onCancel={handleClose}
      />

      <ModalBody>
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography gutterBottom className={classes.typography}>¿Está seguro que desea cambiar su contraseña?</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0px' }}>
            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
              <CssTextField
                style={{ width: '350px' }}
                id="oldPassword"
                placeholder={"Ingrese actual contraseña"}
                value={oldPassword}
                required
                fullWidth
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                onChange={onChangeOldPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => { setShowPassword(!showPassword) }}
                        edge="end"
                        style={{ color: pColor }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <span style={{
              color: "red", display: 'flex', flexDirection: 'column',
              alignItems: 'center'
            }}>{errors["oldPassword"]}</span>
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0px' }}>
            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
              <CssTextField
                style={{ width: '350px' }}
                id="newPassword"
                placeholder={"Ingrese nueva contraseña"}
                value={newPassword}
                required
                fullWidth
                autoComplete="new-password"
                type={showNewPassword ? "text" : "password"}
                onChange={onChangeNewPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => { setShowNewPassword(!showNewPassword) }}
                        edge="end"
                        style={{ color: pColor }}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <span style={{
              color: "red", display: 'flex', flexDirection: 'column',
              alignItems: 'center'
            }}>{errors["newPassword"]}</span>
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0px' }}>
            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
              <CssTextField
                style={{ width: '350px' }}
                id="verifyNewPassword"
                placeholder={"Vuelva a ingresar nueva contraseña"}
                value={verifyPassword}
                required
                fullWidth
                autoComplete="new-password"
                type={showVerifyPassword ? "text" : "password"}
                onChange={onChangeVerifyPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => { setShowVerifyPassword(!showVerifyPassword) }}
                        edge="end"
                        style={{ color: pColor }}
                      >
                        {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <span style={{
              color: "red", display: 'flex', flexDirection: 'column',
              alignItems: 'center'
            }}>{errors["verifyPassword"]}</span>
          </Grid>
        </Grid>

        <ModalFooter
          confirmText={"Aceptar"}
          onConfirm={handleLogin}
          cancelText={"Cancelar"}
          onCancel={clearModal}
        />

      </ModalBody>
    </Modal>
  )

}