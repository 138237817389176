import React, { Component } from 'react';
import { Input, InputAdornment, Typography, Grid, IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import BasePage from 'pages/BasePage';
import 'assets/styles/docset-app.css';
import AddIcon from '@material-ui/icons/Add';
import 'assets/styles/docset-app.scss';
import  CustomTable  from 'components/CustomTable'
import { ModalUser } from 'components/Modals/ModalUser';
import { listUsers, listUsersByCompany } from 'services/actions/UserAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { getCustomRoleName } from 'utils/common';
import { ModalConfirmDeleteUser } from 'components/Modals/ModalConfirmDeleteUser';

const columns = [
  { type: 'text', field: 'name', label: 'Nombre', format: (row) => `${row.firstName} ${row.lastName}` },
  { type: 'text', field: 'username', label: 'Nombre de Usuario', format: (row) => `${row.username || ""}` },
  { type: 'text', field: 'roleName', label: 'Rol', format: (row) => getCustomRoleName(row.roleName)},
  { type: 'text', field: 'email', label: 'Correo' },
  { type: 'text', field: 'phone', label: 'N° Celular' },
  { type: 'options', field: 'options', label: 'Opciones' }
];

class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showUserModal:false,
      selectedUser:null,
      editMode:false,
      showModalDelete:false,
      users: [],
      search: "",
      searchTimeout: null,
    };
  }
  

  componentDidMount(){
    this.onListUser();
  }

  onListUser = (term) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listUsersByCompany(term)).then(res =>{
      this.setState({users: res || []});
      this.props.dispatch(showBackdrop(false));
    }).catch(err => this.props.dispatch(showBackdrop(false)) );
  }

  onDetails = (selectedUser) => {
    this.setState({
      editMode:false,
      selectedUser:selectedUser,
      showUserModal:true
    });
  }

  openModalDelete = (selectedUser) => {
    this.setState({
      selectedUser: selectedUser,
      showModalDelete: true
    });
  }
  
  closeUserModal = () => {
    this.setState({showUserModal:false});
  }

  openUserModal = () => {
    this.setState({
      editMode:true,
      selectedUser: null,
      showUserModal:true
    });
  }

  onEdit = (selectedUser) => {
    this.setState({
      editMode:true,
      selectedUser:selectedUser,
      showUserModal:true
    });
  }

  onConfirmUser = () => {
    this.closeUserModal();
    this.onListUser(this.state.search || "");
  }

  onSearch = (term) => {
    clearTimeout(this.state.searchTimeout)
    this.setState({search: term});
    this.setState({ searchTimeout: setTimeout(() => { this.onListUser(term)}, 1000) })
  };

  render() {
    const { showUserModal, selectedUser, showModalDelete, editMode, users=[] } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body">
          <Grid container item >
            <Grid item xs={12} sm={6} md={3} style={{height:'70%'}}>
              <div className="design_body_2">
                <div className="add">
                  <Typography className="page_title" variant="h5" gutterBottom>
                    <strong>USUARIOS</strong>
                  </Typography>
                  <Button className="btnAdd" onClick={this.openUserModal}>
                    <AddIcon className="icoAdd"/>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Input
              fullWidth
              className="search_wrap_user"
              type="text"
              placeholder="Buscar..."
              disableUnderline
              value={this.state.search}
              onChange={event => this.onSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <CustomTable 
            columns={columns} 
            rows={users || []}
            onRowClick={() => {}}
            hasOptions
            onDetails={this.onDetails}
            onDelete={this.openModalDelete}
            onEdit={this.onEdit}
          />
        </Grid>

        { 
          showUserModal && (
            <ModalUser
              {...this.props}
              open={showUserModal}
              handleClose={()=>{this.closeUserModal()}}
              onConfirmCallBack={this.onConfirmUser}
              user={selectedUser}
              editMode={editMode}
            />
          )
        }
        <ModalConfirmDeleteUser
          {...this.props}
          open={showModalDelete}
          closeModal={() => { this.setState({showModalDelete: false }) }}
          user={selectedUser}
          onListUser={this.onListUser}
        />
      </BasePage>
    );
  }
}

export default UserPage;
