import React from 'react';
import { IconButton, makeStyles, TablePagination, withStyles, ButtonGroup, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'assets/styles/docset-app.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ThemeError from './ThemeSettings/ThemeError';
import ThemeWarning from './ThemeSettings/ThemeWarning';
import ThemeInfo from './ThemeSettings/ThemeInfo';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 800,
    },
});

const CustomTable = (props)=> {

    const { columns=[], rows=[], loading=false, hasOptions } = props;

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
          color: theme.palette.common.black,
        },
        body: {
          fontSize: 14,
        },
    }))(TableCell);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead >
                        <TableRow >
                            {columns.map((column, i) => (
                                <StyledTableCell className="headTable" key={i}>
                                {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    { loading ? <caption>Cargando...</caption> : (
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onClick={() => { props.onRowClick(row) } } style={{cursor:'pointer'}}>
                                        {
                                            columns.map((column, i2) => {
                                                return column.type == "options" && hasOptions ? (
                                                    <TableCell className="cellTable" key={i2} align={column.align} style={{fontSize:"13px"}}>
                                                        <ButtonGroup variant="contained" color="primary" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                                          {
                                                            props.onDetails && (
                                                              <ThemeInfo>
                                                                  <IconButton size="small" color="primary" aria-label="view" onClick={() => { props.onDetails(row) }}>
                                                                      <VisibilityIcon />
                                                                  </IconButton>
                                                              </ThemeInfo>
                                                            )
                                                          }
                                                          {
                                                            props.onEdit && (
                                                              <ThemeWarning>
                                                                  <IconButton size="small" color="primary" aria-label="edit" onClick={() => { props.onEdit(row) }}>
                                                                      <EditIcon />
                                                                  </IconButton>
                                                              </ThemeWarning>
                                                            )
                                                          }
                                                          {
                                                            props.onDelete && (
                                                              <ThemeError>
                                                                  <IconButton size="small" color="primary" aria-label="delete" onClick={() => { props.onDelete(row) } }>
                                                                      <DeleteIcon />
                                                                  </IconButton>
                                                              </ThemeError>
                                                            )
                                                          }
                                                        </ButtonGroup>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell className="cellTable" key={i2} align={column.align}>
                                                        {column.format ? column.format(row) : row[column.field] || ""}
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    ) }
                </Table>
            </TableContainer>
            { !loading && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            ) }
        </>
    )
    }

export default CustomTable