import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "users";

class UserService {

    async createUser(data) {
        return await axios.post( getCustomUrl(apiPrefix, `/register`), data, defaultHeaders() );
    }
    
    async updateUser(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/update/${id}`), data, defaultHeaders() );
    }

    async findUser(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/find/${id}`), defaultHeaders() );
    }

    async listUsers(term="") {
        return await axios.get( getCustomUrl(apiPrefix, `/list?term=${term}`), defaultHeaders() );
    }

    async deleteUser(id) {
        return await axios.delete( getCustomUrl(apiPrefix, `/delete/${id}`), defaultHeaders() );
    }
    
    async getRoles() {
        return await axios.get( getCustomUrl(config.apiVersion, `roles/list`), defaultHeaders() );
    }

    async findUserByUserName(username, rut="") {
        return await axios.get( getCustomUrl(config.apiVersion, `findUser/${username}?rut=${rut}`), defaultHeaders() );
    }

    async recoverPassword(id, email) {
        return await axios.post( getCustomUrl(config.apiVersion, `recoverPassword/${id}`), {email}, defaultHeaders());
    }

    async listUsersByCompany(term = "") {
        return await axios.get( getCustomUrl(apiPrefix, `/listByCompany?term=${term}`), defaultHeaders() );
    }

    async listGeneralAdmin(term) {
        return await axios.get( getCustomUrl(apiPrefix, `/listGeneralAdmin?term=${term}`), defaultHeaders() );
    }

    async getStorage(idCompany) {
        return await axios.get( getCustomUrl(apiPrefix, `/getStorage`), defaultHeaders() );
    }

    async updatePassword(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/updatePassword/${id}`),data, defaultHeaders());
    }

    async verifyUserRole(term, idRole) {
        return await axios.get( getCustomUrl(apiPrefix, `/verifyUserRole?term=${term}&idRole=${idRole}`), defaultHeaders());
    }

}

export default UserService;