import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';

export const amberTheme = createMuiTheme({ 
  contrastThreshold: 3,
  tonalOffset: 0.2,
  palette: { 
    primary: amber,
    contrastText: "#fff"
  } 
});

const ThemeWarning = (props) => {
	return(
		<MuiThemeProvider theme={amberTheme}>
			{props.children}
		</MuiThemeProvider>
	);
}

export default ThemeWarning;