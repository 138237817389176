import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ModalUploadFile from 'components/Modals/ModalUploadFile';
import { useHistory } from 'react-router-dom';
import { ModalPerfil } from 'components/Modals/ModalPerfil';
import { pColor, tColor } from 'assets/styles/docset-css';
import { addDownload, listMyFiles, globalSearchFiles } from 'services/actions/DFileAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import config from 'config/Config';
import { getUserInitSession } from 'utils/common';
import { ModalUpdatePassword } from 'components/Modals/ModalUpdatePassword';


const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
    color: '#fff',
    background: '#1A73E8',
    borderRadius: 8,
    marginRight: theme.spacing(6),
    width: "200px"
  },
  blue: {
    color: theme.palette.getContrastText(tColor),
    backgroundColor: tColor,
    marginTop: 5,
    cursor: "pointer",
    [theme.breakpoints.up('sm')]: {
    }
  },
  white: {
    color: theme.palette.getContrastText(tColor),
    marginTop: 5,
    cursor: "pointer"
  },
  search: {
    position: 'relative',
    paddingLeft:'70px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft:0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    background: '#E5E5E5',
    borderRadius: 7,
    [theme.breakpoints.up('md')]: {
      width: '55ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const MainHeader = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const userInitial = getUserInitSession();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [searchTimeout, setSearchTimeout] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [showModalUpload, setShowModalUpload] = React.useState(false);

  const [showModalPerfil, setShowModalPerfil] = React.useState(false);
  const [showModalPassword, setShowModalPassword] = React.useState(false);

  const [dFiles, setDFiles] = React.useState([]);
  const [search, setSearch] = React.useState(null);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSessionClose = () => {
    localStorage.removeItem('session');
    history.push("/");
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenUpload = () => {
    setShowModalUpload(true);
  };

  const handleCloseUpload = () => {
    setShowModalUpload(false);
  };

  const handleOpenPerfil = () => {
    setShowModalPerfil(true);
  }

  const handleClosePerfil = () => {
    setShowModalPerfil(false);
  }

  const handleOpenModalPassWord = ()=>{
    setShowModalPassword(true);
  }

  const handleCloseModalPassWord = ()=>{
    setShowModalPassword(false);
  }

  const handleSearch = (value) => {
    clearTimeout(searchTimeout);

    if (value) {
      setSearchTimeout(
        setTimeout(() => {
          props.dispatch(showBackdrop(true));
          props.dispatch(globalSearchFiles(value)).then((res) => {
            props.dispatch(showBackdrop(false));
            setDFiles(res);
          })
        }, 1000)
      )
    } else {
      setDFiles([]);
    }
  }

  const handleOpenFile = (option) => {
    setSearch(option.value);
    props.dispatch(addDownload(option.value)).then(res => {
      props.dispatch(showBackdrop(false));
      window.open(config.api + option.filePath, '_blank');
    }).catch(err => {
      props.dispatch(showSnackBar("warning", err.response.data.error || ""));
    });
  }

  /* const handleOpenModalPassWord = (option) =>{
    setSearch(option.value);
    props.dispatch(addDownload(option.value)).then(res => {
      props.dispatch(showBackdrop(false));
      window.open(config.api + option.filePath, '_blank');
    }).catch(err => {
      props.dispatch(showSnackBar("warning", err.response.data.error || ""));
    });
  } */

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleOpenPerfil}>Ver Perfil</MenuItem>
      <MenuItem onClick={handleOpenModalPassWord}>Cambiar Contraseña</MenuItem>
      <MenuItem onClick={handleSessionClose}>Cerrar Sesión</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleOpenPerfil}>Ver Perfil</MenuItem>
      <MenuItem onClick={handleSessionClose}>Cerrar Sesión</MenuItem>
    </Menu>
  );

  const options = dFiles.map(dFile => {
    return { value: `${dFile.id}`, label: `${dFile.name}`, filePath: `${dFile.filePath}` };
  });

  return (
    <div className={classes.grow}>
      <AppBar style={{ background: pColor }} position="static">
        <Toolbar>
          <div className={classes.search}>
            <Select
              className='global-search'
              placeholder={'Buscar...'}
              value={search}
              options={options}
              onInputChange={handleSearch}
              onChange={handleOpenFile}
              classes={{
                input: classes.inputInput,
              }}
            />

          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button onClick={handleOpenUpload} className={"blue-button " + classes.button} startIcon={<CloudUploadIcon />}>
              Subir Archivo
            </Button>
            <Avatar
              className={classes.blue}
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              {userInitial}
            </Avatar>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton onClick={handleOpenUpload} >
              <CloudUploadIcon className={classes.white} />
            </IconButton>
            <Avatar
              className={classes.blue}
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              {userInitial}
            </Avatar>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <ModalPerfil
        open={showModalPerfil}
        handleClose={handleClosePerfil}
      />
      {
        !!showModalUpload && (
          <ModalUploadFile
            {...props}
            editMode
            open={showModalUpload}
            handleClose={handleCloseUpload}
          />
        )
      }
      {
        !!showModalPassword && (
          <ModalUpdatePassword
            {...props}
            open={showModalPassword}
            handleClose={handleCloseModalPassWord}
            handleSessionClose={handleSessionClose}
          />
        )
      }
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(withRouter(MainHeader));