import { Backdrop, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.tooltip,
      color: '#fff',
    },
  }));

const CustomBackdrop = props =>{
  const classes = useStyles();
  const currentPath = window.location.pathname;
    return(
        <Backdrop className={classes.backdrop} open={props.open}>
          {
            currentPath === '/generar-documento' && 
            (<Typography style={{color:'#F5F7FA'}}>Cargando componentes ...</Typography>) || (<CircularProgress style={{color:'#F5F7FA'}} />)
          }
        </Backdrop>
    )
}

export default CustomBackdrop