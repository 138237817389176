import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "dStyle";

class DStyleService {

    async listStyles(category) {
        return await axios.get( getCustomUrl(apiPrefix, `/list?category=${category || ""}`), defaultHeaders() );
    }
    async listValues(name){
        return await axios.get( getCustomUrl(apiPrefix, `/listValues?name=${name || ""}`), defaultHeaders() );
    }
    
}

export default DStyleService;