import axios from '../../utils/axios';
import config from "../../config/Config";
import { defaultHeaders, getCustomUrl } from 'utils/common';

const apiPrefix = config.apiVersion + "dFolders";

class DFolderService {

    async createFolder(data) {
        return await axios.post( getCustomUrl(apiPrefix, `/register`), data, defaultHeaders() );
    }

    async listMyFolders(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listMyFolder?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }
    
    async listSharedFolders(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listShared?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listImportantFolders(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listImportant?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async listSharedCompanyFolders(term, idCompany, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listSharedCompany?term=${term}&idCompany=${idCompany}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async findDFolder(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/find/${id}`), defaultHeaders() );
    }

    async verifyFindDFolder(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/verifyFind/${id}`), defaultHeaders() );
    }

    async updateFolder(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/update/${id}`), data, defaultHeaders() );
    }

    async deleteDFolder(id) {
        return await axios.delete( getCustomUrl(apiPrefix, `/delete/${id}`), defaultHeaders() );
    }

    async renameDFolder(id, name) {
        return await axios.post( getCustomUrl(apiPrefix, `/rename/${id}`), {name}, defaultHeaders() );
    }

    async shareDFolder(id, data) {
        return await axios.post( getCustomUrl(apiPrefix, `/share/${id}`), data, defaultHeaders() );
    }

    async listSharedFolderWithMe(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listSharedWithMe?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async verifyFindWithMe(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/verifyFindWithMe/${id}`), defaultHeaders() );
    }

    async sharedFoldersList(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/sharedFoldersList?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }
    
    async listDFolderConfig(term, idFolder, sortBy="updated_at") {
        return await axios.get( getCustomUrl(apiPrefix, `/listDFolderConfig?term=${term}&idFolder=${idFolder}&sortBy=${sortBy}`), defaultHeaders() );
    }

    async updateFolderConfig(id, name, status) {
        return await axios.post( getCustomUrl(apiPrefix, `/updateFolderConfig/${id}`), {name, status}, defaultHeaders() );
    }

    async verifyFindSharedCompany(id) {
        return await axios.get( getCustomUrl(apiPrefix, `/verifyFindSharedCompany/${id}`), defaultHeaders() );
    }

}

export default DFolderService;