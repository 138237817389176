import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { renameDFolder, updateFolderConfig } from 'services/actions/DFolderAction';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { Field, Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const ModalRenameFolder = (props) => {

  const { open, handleClose, handleConfirmClose, dFolder, editMode} = props;

  const [data, setData] = React.useState({
    name: "",
    status: dFolder && (dFolder.status === 1 ? true : false)
  });

  React.useEffect(() => {
    if(open){
      setData({
        name: dFolder && dFolder.name,
        status: dFolder && (dFolder.status === 1 ? true : false)
      });
    }else{
      setData({
        name: "",
        status: dFolder && (dFolder.status === 1 ? true : false)
      });
    }
  }, [open]);

  const validateForm = (values) => {
    let errors = {};
    
    if (!values.name) errors.name = 'Nombre requerido';
    
    return errors;
  }

  const onSubmit = (values) => {
    props.dispatch(showBackdrop(true));
    if(editMode){
      props.dispatch(updateFolderConfig(dFolder.id, values.name, values.status)).then(res => {
        props.dispatch(showSnackBar("success", "Carpeta Editada"));
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || ""));
        props.dispatch(showBackdrop(false));
      });
    }else{
      props.dispatch(renameDFolder(dFolder.id, values.name)).then(res => {
        props.dispatch(showSnackBar("success", "Carpeta renombrada"));
        props.dispatch(showBackdrop(false));
        handleConfirmClose && handleConfirmClose();
      }).catch(err => {
        props.dispatch(showSnackBar("warning", err.response.data.error || ""));
        props.dispatch(showBackdrop(false));
      });
    } 
  }

  return (
    
    <Modal
      open={open}
      handleClose={handleClose}
      size="xs" 
    >
      <ModalHeader
        icon={<BorderColorIcon />}
        text={editMode && "Editar Carpeta" || "Renombrar Carpeta"}
        className='positionElements'
        onCancel={handleClose}
      >
      </ModalHeader>

      <ModalBody>
        
        <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {
            ({handleChange, handleSubmit, setFieldValue, values, errors, touched}) => {
              return (
                <Form onSubmit={handleSubmit}>

                  <TextField
                    id="name"
                    type="text" 
                    value={values.name}
                    onChange={handleChange}
                    error={ errors.name && touched.name ? true : false }
                    placeholder="Nombre de la carpeta" 
                    fullWidth
                    style={{padding: "5px"}}
                  />

                  {
                    editMode && (
                      <Grid item xs={12} container style={{marginTop: "5px"}}>
                        <Grid item xs={2} className="item-center">
                          <Typography variant="body1">Estado: </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={values.status}
                            onChange={() => { setFieldValue("status", !values.status) }}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <ModalFooter 
                    confirmText={editMode && "Editar" || "RENOMBRAR"}
                    cancelText="CANCELAR"
                    onCancel={handleClose}
                    onConfirm={handleSubmit}
                    className="footerRight"
                  />

                </Form>
              )
            }
          }
        </Formik>

      </ModalBody>

      
    </Modal>
    
  )
};