import _ from 'lodash';
import moment from 'moment';
import Docx from 'assets/images/fileIcons/docx.png';
import Pdf from 'assets/images/fileIcons/pdf.png';
import Pptx from 'assets/images/fileIcons/pptx.png';
import Txt from 'assets/images/fileIcons/txt.png';
import Xlsx from 'assets/images/fileIcons/xlsx.png';
import Incog from 'assets/images/fileIcons/incog.png';

import config from 'config/Config';


export const saveUser = (value) => {
  if (window && window.localStorage) {
    return window.localStorage.saveObject("user", value);
  }

  return null;
}

export const getFileImage = (type) => {
  switch(type){
    case "docx":
      return Docx;
    case "xlsx":
      return Xlsx;
    case "pdf":
      return Pdf;
    case "pptx":
      return Pptx;
    case "txt":
      return Txt;
    default:
      return Incog;
  }
}
export const getSessionInfo = () => {
  return localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : null;
}

export const getIdCompanySession = () => {
  const sesion = getSessionInfo();
  return sesion && sesion.user && sesion.user.idCompany || "";
}

export const getUserInitSession = () => {
  const {user} = getSessionInfo();
  const initial = user.firstName.charAt(0) + user.lastName.charAt(0);
  return initial || "";
}

export const defaultHeaders = () => { 
  const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};
  const token = session.token || "";
  const user = session.user || {};
  const idCompany = user.idCompany || null;

  return {
      headers: {
          ...config.headers, 
          Authorization: `${process.env.REACT_APP_TOKEN || "Bearer"} ${token}`
      },
      params: {
        idCompany: idCompany
      }
  }
};

export const getCustomUrl = (apiPrefix, url) => {
  return apiPrefix + url;
}

export const customRolesName = [
  { name: "Admin", value: "Administrador General" },
  { name: "AdminEmpresa", value: "Administrador de Empresa"},
  { name: "UserEmpresa", value: "Usuario"},
  { name: "Guest", value: "Invitado" }
]

export const getCustomRoleName = (oldRolName) => {
  const customRol = customRolesName.find(rol => rol.name == oldRolName);
  return  customRol ? customRol.value : oldRolName;
}