import React from 'react';
import ModalBody from './common/ModalBody';
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import ModalFooter from './common/ModalFooter';
import { Formik } from 'formik';
import { TextField, Typography, Grid, Divider} from '@material-ui/core';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { listStyles } from 'services/actions/DStyleAction'
import { createSection, findSection, updateSection } from 'services/actions/SectionAction'
import CustomAddStyle from 'components/CustomAddStyle';
import { customSizes, customWeights, customUnderline } from 'utils/customStyles';

export const ModalSection = (props) => {
  const { open, closeModal, editMode, idSection, idTemplate} = props; 

  const [data, setData] = React.useState({
    id: null,
    name: "",
  });

  const [dataTable, setDataTable] = React.useState({
    category_id: null,
    style: null,
    value: null
  });

  React.useEffect(()=>{
    if(open){
      if(idSection){
        props.dispatch(showBackdrop(true));
        props.dispatch(findSection(idSection)).then(res => {
            props.dispatch(showBackdrop(false));
            setData({
              ...data,
              id: res.order,
              name: res.name
            });
            setTable(setCustomTable(res.mappedStyles))
        });
      }
    }
  },[open])

  const [table, setTable] = React.useState([]);
  const [styles, setStyles] = React.useState([]);
  const [formatValue, setFormatValue] = React.useState("freeText");
  
  const validateForm = (values) => {
    let errors = {};

    const formats = {
      name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
    };

    if(!values.name) errors.name = "Nombre requerido";
    if(values.name && !formats.name.test(values.name)) errors.name = "Nombre inválido";
    if(!values.id) errors.id = "Código requerido";
    return errors;
  };

  const onSubmit = (values, {resetForm} ) => {

    if(table.length === 0){
      return props.dispatch(showSnackBar("warning", "Agregar almenos un estilo a la tabla"));
    }
    const formData = new FormData();
    formData.append('order', values.id);
    formData.append('name', values.name);
    formData.append('idTemplate', idTemplate);

    const selectStyles = table && table.map((row) => ({
      idStyle: row.id,
      value: row.value,
      label_value: row.label_value
    })) || [];

    formData.append('styles', JSON.stringify(selectStyles));

    props.dispatch(showBackdrop(true));
    if (idSection) {
      props.dispatch(updateSection(idSection, formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Sección Actualizada"));
        closeModal(true);
      }).catch(err => {
        props.dispatch(showSnackBar('error', err.response.data.error));
        props.dispatch(showBackdrop(false));
      });
    }else{
      props.dispatch(createSection(formData)).then(res => {
        props.dispatch(showBackdrop(false));
        props.dispatch(showSnackBar("success", "Sección Registrada"));
        closeModal(true);
      }).catch(err => {
        props.dispatch(showSnackBar('error', err.response.data.error));
        props.dispatch(showBackdrop(false));
      });
    }
  }

  const getStyles = (category) =>{
    if (category) {
      props.dispatch(showBackdrop(true));
      props.dispatch(listStyles(category.id)).then(res => {
        props.dispatch(showBackdrop(false));
        setStyles(getCustomStyles(res));
      })
    }
  }

  const getCustomStyles = (originalStyles) => {
    return originalStyles ? originalStyles.map(style => ({
        id: style.id,
        name: style.name,
        inputType: style.inputType
      })) : [];
  }

  const customStyles = getCustomStyles(styles || []);
  
  const setCustomTable = (res) => {
    return res ? res.map(style => ({
        index: style.index,
        id: style.id, name: style.category,
        style: style.name,
        value: style.value,
        label_value: style.label_value
      })) : [];
  }

  const setCategory = (event) => {
    setDataTable({
        ...dataTable,
        category_id: event,
        style: null,
        value: null
      });
    getStyles(event);
  }

  const setStyle = (event) => {
    setDataTable({
        ...dataTable,
        style: event
      });
    setFormatValue(event.inputType); 
  }

  const setValue = (event) => {
    switch (dataTable.style.inputType) {
      case "decorateSelect": 
      case "sizeSelect": 
      case "weightSelect":
        setDataTable({
          ...dataTable,
          value: event
        });
        break;
      default: setDataTable({
          ...dataTable,
          value: event.target.value
        });
        break;
    }
  }

  const addStyleTable = () => {
    switch (dataTable.style.inputType) {
      case "sizeSelect": 
        if (validateValue()) {
          const index = table.length;
          setTable([
            ...table,
            {
              index: index,
              id: dataTable.style.id,
              name: dataTable.category_id.name,
              style: dataTable.style.name,
              value: dataTable.value ? dataTable.value.id : customSizes[2].id,
              label_value: dataTable.value ? dataTable.value.name : customSizes[2].name
            }
          ]);
        }
        break;
      case "decorateSelect": if (validateValue()) {
          const index = table.length;
          setTable([
            ...table,
            {
              index: index,
              id: dataTable.style.id,
              name: dataTable.category_id.name,
              style: dataTable.style.name,
              value: dataTable.value ? dataTable.value.id : customUnderline[0].id,
              label_value: dataTable.value ? dataTable.value.name : customUnderline[0].name
            }
          ]);
        }
        break;
      case "weightSelect":
        if (validateValue()) {
          const index = table.length;
          setTable([
            ...table,
            {
              index: index,
              id: dataTable.style.id,
              name: dataTable.category_id.name,
              style: dataTable.style.name,
              value: dataTable.value ? dataTable.value.id : customWeights[0].id,
              label_value: dataTable.value ? dataTable.value.name : customWeights[0].name
            }
          ]);
        }
        break;
      default: 
        if (validateValue()) {
          const index = table.length;
          setTable([
            ...table,
            {
              index: index,
              id: dataTable.style.id,
              name: dataTable.category_id.name,
              style: dataTable.style.name,
              value: dataTable.value,
              label_value: dataTable.value
            }
          ]);
        }
        break;
    }
    if (validateValue()) {
      setDataTable({ category_id: null, style: null, value: null });
      setFormatValue("freeText");
    }
  }

  const validateValue = () => {
    if (formatValue == "freeText") {
      if (!dataTable.style || !dataTable.category_id || !dataTable.value) {
        props.dispatch(showSnackBar('error', 'Llenar todos los campos de estilos'));
        return false;
      }else{
        return true;
      }
    }else{
      if (!dataTable.style || !dataTable.category_id) {
        props.dispatch(showSnackBar('error', 'Llenar todos los campos de estilos'));
        return false;
      }else{
        return true;
      }
    }
  }

  const deleteStyleTable = (row) => {
    const index = table.findIndex(value => value.index === row.index);
    table.splice(index,1);
    setTable([
      ...table
    ]);
  }

  return (
    <Modal
      open={open}
      handleClose={()=> { closeModal(false) }}
      size="md"
    >
      <ModalHeader
        text={editMode ? "Editar Sección" : "Nueva Sección"}
        className='positionElements'
        onCancel={()=> { closeModal(false) }}
      >
      </ModalHeader>

      <ModalBody>
        
        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems="center" justifyContent="center">

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>N°: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="id"
                        type="number" 
                        name="id"
                        value={values.id}
                        onChange={handleChange}
                        placeholder="Código"
                        error={errors.id && touched.id ? true : false}
                        disabled={editMode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" className="custom-input"><b>Nombre: </b></Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="name"
                        type="text" 
                        name="name"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Nombre"
                        error={errors.name && touched.name ? true : false}
                      />s
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <CustomAddStyle
                      {...props}
                      customStyles={customStyles}
                      data={dataTable}
                      table={table}
                      formatValue={formatValue}
                      setCategory={setCategory}
                      setStyle={setStyle}
                      setValue={setValue}
                      addStyleTable={addStyleTable}
                      deleteStyleTable={deleteStyleTable}
                    />
                  </Grid>

                </Grid>

                <Divider className="custom-divider"/>
                <ModalFooter 
                  buttonType="submit"
                  confirmText={editMode ? "ACTUALIZAR" : "REGISTRAR"}
                  cancelText="CANCELAR" 
                  onCancel={()=> { closeModal(false) }}
                  onConfirm={handleSubmit}
                />

              </form>
            )
          }}
          </Formik>

      </ModalBody>

    </Modal>
  )

}