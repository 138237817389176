import React, { Component } from 'react';
import { Card, CardMedia, Grid, CardContent, Typography } from "@material-ui/core";
import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core';
import { getFileImage } from 'utils/common';
import MenuOptions from 'components/File/MenuOptions';
import moment from 'moment';

const CustomFile = (props) => {
    const { dFile, keyId } = props;

    const getFooterText = () => {
        var path = window.location.pathname;
        
        switch(true){
            case path.includes("/compartidos"):
                return <Typography className="fontTime"> N° Descargas: {dFile.downloads}</Typography>;
            case path.includes("/recientes"):
                return <Typography className="fontTime">Fecha de modificación {moment(dFile.updated_at).format('DD/MM/YYYY')}</Typography>;
            default:
                return <Typography className="fontTime">Subido el {moment(dFile.created_at).format('DD/MM/YYYY')}</Typography>;
        }
    }

    return(
        <Grid key={keyId} item xs={12} sm={4} md={3} lg={2} className="grid_card">
            <Card>
                <MenuOptions
                    onShare={() => { props.onShare && props.onShare(dFile)}}
                    onEdit={() => { props.onEdit && props.onEdit(dFile) }}
                    onDuplicate={() => { props.onDuplicate && props.onDuplicate(dFile) }}
                    onRename={() => { props.onRename && props.onRename(dFile) }}
                    showLocation={() => { props.showLocation && props.showLocation(dFile) }}
                    onImportant={() => { props.onImportant && props.onImportant(dFile) }}
                    onArchived={() => { props.onArchived && props.onArchived(dFile) }}
                    onDownload={() => { props.onDownload && props.onDownload(dFile) }}
                    onDetails={() => { props.onDetails && props.onDetails(dFile) }}
                    onDeleted={() => { props.onDeleted && props.onDeleted(dFile) }}
                    onRestore={() => { props.onRestore && props.onRestore(dFile) }}
                    dFile={ dFile}
                />
                
                <CardMedia className="media" image={getFileImage(dFile.extension)} />
                <CardContent className="content">
                    <Grid container>
                    <Grid item xs={12}>
                        <Typography>{dFile.name}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        { getFooterText() }
                    </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
export default CustomFile;