import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BasePage from 'pages/BasePage';
import { listArchivedFiles } from 'services/actions/DFileAction';
import CustomHeader from 'components/CustomHeader';
import CustomFiles from 'components/File/CustomFiles';
import { showBackdrop } from 'services/actions/CustomAction';

class ArchivedPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dFiles: [],
      showFilesMessage: true,
      filesMessage: "Cargando documentos..."
    }
  }

  async componentDidMount() {
    this.getMyFiles();
  }

  getMyFiles = (sortBy) => {
    this.props.dispatch(showBackdrop(true));
    this.props.dispatch(listArchivedFiles("", sortBy || "")).then((res) => {
      this.props.dispatch(showBackdrop(false));
      this.setState({ 
        dFiles: res || [],
        showFilesMessage: res.length <= 0,
        filesMessage: "No hay documentos."
      });
      
    })
  }

  render() {
    const { dFiles = [], showFilesMessage, filesMessage } = this.state;

    return (
      <BasePage>
        <Grid container className="design_body_2">
          <CustomHeader
            title="Archivados"
            getMyFiles={this.getMyFiles}
            defaultOrder={"updated_at"}
          />
          {
            showFilesMessage ? (
              <Typography variant='caption'>{filesMessage}</Typography>
            ) : (
              <CustomFiles
                {...this.props}
                dFiles={dFiles}
                title="Archivos"
                refreshList={this.getMyFiles}
              />
            )
          }
        </Grid>

      </BasePage>
    );
  }
}

export default ArchivedPage;