import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';

import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import StarIcon from '@material-ui/icons/Star';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Typography } from '@material-ui/core';
import Archive from '@material-ui/icons/Archive';
import Restore from '@material-ui/icons/Restore';
import { sColor } from 'assets/styles/docset-css';
import { getSessionInfo } from 'utils/common';


const MenuOptions = (props) => {
    const { dFile } = props;

    const [options, setOptions] = React.useState(null);
    const open = Boolean(options);

    const session = getSessionInfo();
    const user = session && session.user || {};
    const role = session && session.role || {};
    const isUserAdminCompany = role.name == "AdminEmpresa";

    const handleClick = (event) => {
      setOptions(event.currentTarget);
    };
  
    const handleClose = () => {
        setOptions(null);
    };

    const onClickOption = (option) => {
        switch(option){
            case "share":props.onShare && props.onShare(); break;
            case "edit": props.onEdit && props.onEdit(); break;
            case "duplicate": props.onDuplicate && props.onDuplicate(); break;
            case "rename": props.onRename && props.onRename(); break;
            case "showLocation": props.showLocation && props.showLocation(); break;
            case "important": props.onImportant && props.onImportant(); break;
            case "archived": props.onArchived && props.onArchived(); break;
            case "restore": props.onRestore && props.onRestore(); break;
            case "download": props.onDownload && props.onDownload(); break;
            case "details": props.onDetails && props.onDetails(); break;
            case "deleted": props.onDeleted && props.onDeleted(); break;        
        }
        setOptions(null);
    }


    const showOption = (option) => {
        var path = window.location.pathname;
        var showOptionInView = false;
        var userCreated = (user.id == dFile.createdBy || user.idRole == 2) ? true : false;        
        var sharedWithMe = dFile.sharedWith.includes(user.id) ? true : false;
        var typePermission = sharedWithMe && dFile.sharedPermission == "Editor" ? true : false
        
        switch(option){
            case "duplicate":
            case "important":
                showOptionInView = !["/importantes", "/compartidos", "/compartidos-empresa", "/compartidos-conmigo", "/papelera", `/compartidos-empresa/${dFile.idFolder}`].includes(path);
                return  showOptionInView && userCreated;
            case "restore":
                showOptionInView = ["/papelera"].includes(path);
                return  showOptionInView && userCreated;
            case "showLocation":
                showOptionInView = !["/mi-carpeta", "/compartidos-empresa", "/compartidos-conmigo", "/papelera", "/archivados", `/compartidos-empresa/${dFile.idFolder}`].includes(path);
                return  showOptionInView && userCreated;
            case "details":
                return true;
            case "archived":
                showOptionInView = !["/compartidos-empresa", "/compartidos-conmigo", "/papelera", "/archivados", `/compartidos-empresa/${dFile.idFolder}`].includes(path);
                return showOptionInView && userCreated;
            case "download":
                return !["/papelera"].includes(path);
            case "edit":
                showOptionInView = !["papelera"].includes(path);
                return showOptionInView && (userCreated || typePermission);
            case "share":
            case "rename":
            case "deleted":
                showOptionInView = !["/papelera"].includes(path);
                if(path == "/compartidos-empresa" && !isUserAdminCompany){
                    return false;
                }
                if(path == "/compartidos-conmigo" && user.id != dFile.createdBy){
                    return false;
                }
                userCreated = ["/compartidos-empresa"].includes(path) ? (userCreated || user.idRole == 2) : userCreated;
                return showOptionInView && userCreated;
        }

    }

    return (
        <div>
            {
                dFile.important && <StarIcon style={{color: sColor, fontSize: "30px", float: "left"}} />
            }
            <IconButton aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} className="option">
                <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={options} keepMounted open={open} onClose={handleClose} TransitionComponent={Fade} >
                {
                    showOption("details") && (
                            <MenuItem onClick={() => { onClickOption("details") }} style={{borderBottom:"1px solid #929292"}}>
                                <InfoIcon className="icon"/> 
                                <Typography>
                                    Detalles
                                </Typography>
                            </MenuItem>
                    )
                }
                {
                    showOption("share") && (
                        <MenuItem onClick={() => { onClickOption("share") }}>
                            <ShareIcon className="icon"/> 
                            <Typography>
                                Compartir
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("edit") && (
                        <MenuItem onClick={() => { onClickOption("edit") }}>
                            <EditIcon className="icon"/> 
                            <Typography>
                                Editar
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("duplicate") && (
                        <MenuItem onClick={() => { onClickOption("duplicate") }}>
                            <FileCopyIcon className="icon"/> 
                            <Typography>
                                Duplicar
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("rename") && (
                        <MenuItem onClick={() => { onClickOption("rename") }}>
                            <BorderColorIcon className="icon"/> 
                            <Typography>
                                Renombrar
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("showLocation") && (
                        <MenuItem onClick={() => { onClickOption("showLocation") }}>
                            <FolderIcon className="icon"/> 
                            <Typography>
                                Ir a ubicación del archivo
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("important") && (
                        <MenuItem onClick={() => { onClickOption("important") }}>
                            <StarIcon className="icon"/> 
                            <Typography>
                                { dFile.important && "No "} Importante
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("archived") && (
                        <MenuItem onClick={() => { onClickOption("archived") }}>
                            <Archive className="icon"/> 
                            <Typography>
                                {dFile.archived ? "Desarchivar" : "Archivar"}
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("restore") && (
                        <MenuItem onClick={() => { onClickOption("restore") }}>
                            <Restore className="icon"/> 
                            <Typography>
                                Restaurar
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("download") && (
                        <MenuItem onClick={() => { onClickOption("download") }}>
                            <GetAppIcon className="icon"/> 
                            <Typography>
                                Descargar
                            </Typography>
                        </MenuItem>
                    )
                }
                {
                    showOption("deleted") && (
                        <MenuItem onClick={() => { onClickOption("deleted") }}  style={{borderTop:"1px solid #929292"}}>
                            <DeleteIcon className="icon"/> 
                            <Typography>
                                Eliminar
                            </Typography>
                        </MenuItem>
                    )
                }
            </Menu>
        </div>
    );
}

export default MenuOptions;