import {Grid, makeStyles, TextField, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import React from 'react'
import ModalBody from './common/ModalBody'
import ModalHeader from './common/ModalHeader'
import Modal from './common/Modal'
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ModalFooter from './common/ModalFooter';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { showBackdrop, showSnackBar } from 'services/actions/CustomAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Formik } from 'formik';
import { createDFile, findDFile, updateDFile } from 'services/actions/DFileAction';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getSessionInfo } from 'utils/common';
import moment from 'moment';
import { updateFileList } from 'services/actions/CommonAction';
import Divider from '@material-ui/core/Divider';
import { findDFolder } from 'services/actions/DFolderAction';

const FILE_MAX_SIZE = 10000000;

const useStyles = makeStyles(theme => ({
    fullInputText: {
        margin: theme.spacing(1),
        width: "100%"
    }
}));

const ModalUploadFile = (props) => {

    const { open, handleClose, editMode, dFile, handleConfirmClose, onDownload } = props;
    const classes = useStyles();
    
    const cleanData = {
        name: "",
        extension: "",
        type: "",
        idFolder: "",
        filePath: "",
        folderPath: dFile ? dFile.folderPath : "/mi-carpeta",
        size: 0,
        accessType: "Privado",
        important: false,
        archived: false,
        shared: false,
        sharedWith: [],
        sharedCompany: false
    };
    
    const [data, setData] = React.useState(cleanData);

    const [currentFile, setCurrentFile] = React.useState(null);
    const [nameFolder, setNameFolder] = React.useState("");
    const [butonAction, setButonAction] = React.useState("SUBIR");
    
    const ruta = (window.location.pathname).split('/');
    const idFolder = ruta && ruta[2];

    React.useEffect(()=>{
        if(open){
            if(dFile){
                props.dispatch(showBackdrop(true));
                props.dispatch(findDFile(dFile.id)).then(res => {
                    props.dispatch(showBackdrop(false));
                    setData({
                        ...res,
                    });
                });
                setButonAction("ACTUALIZAR");
            }else{
                setData(cleanData);
            }    

            if(idFolder){
                props.dispatch(findDFolder(idFolder)).then(res => {
                    setNameFolder(res.name)
                }).catch(err => showError(err));
            } 
        }
        
    },[open]);

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentFile = files[0];

        if(currentFile){
            if(currentFile.size > FILE_MAX_SIZE) {
                props.dispatch(showSnackBar("warning", "El archivo seleccionado excede el tamaño requerido"));
                return "";
            }
            
            setCurrentFile(currentFile);

            const nameAsArray = currentFile.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";

            return nameFile;
        }

        return "";
    };

    const replaceFile = (event) =>{
        
        const newFiles = event.target && event.target.files || [];
        const newFile = newFiles[0];
        
        if(newFile){
            if(newFile.size > FILE_MAX_SIZE) {
                props.dispatch(showSnackBar("warning", "El archivo seleccionado excede el tamaño requerido"));
                return "";
            }                

            const nameComplete = newFile.name.split('.') || [];
            const extensionCurrenFile = nameComplete[1];

            const extension = dFile.extension;
            
            if(extension != extensionCurrenFile){
                props.dispatch(showSnackBar("warning", "El nuevo archivo es otro tipo de extension"));
                setButonAction("ACTUALIZAR");
            }else{
                setButonAction("REEMPLAZAR");
            }
            
            setCurrentFile(newFile);
        }
        return "";
    };

    const validateForm = (values) => {
        let errors = {};

        if(!values.name) errors.name = "Nombre de archivo requerido";
    
        return errors;
    };

    const showError = (error) => {
        props.dispatch(showSnackBar("error", error.response.data.error || ""));
        props.dispatch(showBackdrop(false));
        console.log(error);
    }

    const onSubmit = (values, {resetForm} ) => {
        const session = getSessionInfo();
        const user = session && session.user || {};
        const formData = new FormData();

        if(!dFile && !currentFile){
            return props.dispatch(showSnackBar("warning", "Seleccione un archivo"));
        }

        if(currentFile){
            if(currentFile.size > FILE_MAX_SIZE){
                props.dispatch(showSnackBar("error", 'No se puede subir el archivo. Excede el tamaño requerido'));
                return "";
            }

            const nameAsArray = currentFile.name.split(".") || [];
            const extension = nameAsArray[nameAsArray.length-1];
            
            formData.append("type", currentFile.type);
            formData.append("filePath", currentFile);
            formData.append("size", currentFile.size); //bytes
            formData.append("extension", extension);
        }

        formData.append("name", values.name);
        formData.append("accessType", values.accessType);
        formData.append("important", values.important);
        formData.append("archived", false);
        formData.append("shared", values.shared);
        formData.append("sharedCompany", values.sharedCompany);

        if(values.sharedWith){
            for(var i=0; i < values.sharedWith.length; i++){
                formData.append("sharedWith[]", values.sharedWith);
            }
            formData.append("sharedPermission", "Lector");
        }

        props.dispatch(showBackdrop(true));
        if(dFile){
            // Editar
            props.dispatch(updateDFile(dFile.id, formData)).then(res => {
                props.dispatch(showBackdrop(false));
                props.dispatch(showSnackBar("success", "Propiedades actualizadas"));
                handleConfirmClose && handleConfirmClose();
            }).catch(error => {
                showError(error);
            });
        } else{
            // Agregar
            if(user.idCompany) formData.append("idCompany", user.idCompany);
            formData.append("downloads", 0);
            if(idFolder) formData.append("idFolder", idFolder);
            formData.append("folderPath", values.folderPath);

            props.dispatch(createDFile(formData)).then(res => {
                props.dispatch(showBackdrop(false));
                props.dispatch(showSnackBar("success", "Archivo Subido"));
                props.dispatch(updateFileList(true));
                handleClose();
            }).catch(error => {
                showError(error);
            });
        }
    }
   
    return (
        <Modal
            open={open}
            handleClose={handleClose}
            size="sm"
        >
            <ModalHeader
                icon={<InsertDriveFileIcon />}
                text={dFile ? (editMode ? "Editar" : "Detalles") : "Subir Archivo"}
                className='positionElements'
                onCancel={handleClose}
            />
            {
                nameFolder && (
                    <>
                        <Divider/>
                        <Grid item xs={12} container  alignItems="center" justify="center" style={{backgroundColor: '#c0d7f7'}}>
                                <Grid item xs={12} className="item-center" container alignItems="center" justify="center">    
                                    {
                                        ! dFile ? (
                                            <Typography variant="body1"> Este archivo se subirá dentro de la carpeta <b>{nameFolder}</b></Typography>
                                        ) : (
                                            <Typography variant="body1"> El archivo está guardado en la carpeta <b>{nameFolder}</b></Typography>
                                        )
                                    }
                                </Grid>
                        </Grid>
                    </>
                )
            }
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
                        return(
                            <form onSubmit={handleSubmit}>
                                
                                <Grid container spacing={3} alignItems="center" justify="center">
                                    {
                                        !dFile && (
                                            <Grid item xs={12} container>
                                                <Grid item xs={4} className="item-center">
                                                    <Typography variant="body1">Seleccionar Archivo: </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                    >
                                                        <GetAppIcon style={{marginRight: "12px"}} />
                                                        <input id="filePath" type="file" name="filePath" onChange={(event) => {
                                                            setFieldValue("name", setNameFile(event))
                                                        }}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} container>
                                        <Grid item xs={4} className="item-center">
                                            <Typography variant="body1">Nombre: </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="name"
                                                type="text"
                                                value={values.name}
                                                onChange={handleChange}
                                                placeholder="Nombre del archivo" 
                                                fullWidth
                                                className={classes.fullInputText}
                                                disabled={!editMode}
                                                error={errors.name && touched.name ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    {
                                        (!dFile || !editMode) && (
                                            <Grid item xs={12} container>
                                                <Grid item xs={4} className="item-center">
                                                    <Typography variant="body1">Importante: </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checkedIcon={<CheckBoxIcon />}
                                                        checked={values.important}
                                                        onChange={() => { setFieldValue("important", !values.important) }}
                                                        disabled={!editMode}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} container>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">Compartir con la Empresa: </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon />}
                                            checkedIcon={<CheckBoxIcon />}
                                            checked={values.sharedCompany}
                                            onChange={() => { setFieldValue("sharedCompany", !values.sharedCompany) }}
                                            disabled={!editMode}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        dFile && (
                                            <>
                                            <Grid item xs={12} container>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">
                                                    <i>Para reemplazar el archivo físico puede subir un nuevo archivo  o descargar el original con las modificaciones pertinentes y adjuntarlo en el cuadro.</i>
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container>
                                                <Grid item xs={4} className="item-center">
                                                    <Typography variant="body1">Archivo Actual: </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        color= "primary"
                                                        fullWidth
                                                        onClick={() => {onDownload && onDownload(dFile)}}
                                                    >
                                                        <GetAppIcon style={{marginRight: "12px"}} />
                                                        <Typography variant="body1">Descargar Archivo </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container>
                                                <Grid item xs={4} className="item-center">
                                                    <Typography variant="body1">Nuevo Archivo: </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        fullWidth
                                                    >
                                                        <GetAppIcon style={{marginRight: "12px"}} />
                                                        <input id="filePath" type="file" name="filePath" onChange={(event) => {
                                                            replaceFile(event);
                                                        }}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            </>
                                        )
                                    }

                                    {
                                        !editMode && (
                                            <>
                                                <Grid item xs={12} container>
                                                    <Grid item xs={4} className="item-center">
                                                        <Typography variant="body1">Fecha de creación: </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="dateUploaded"
                                                            type="text"
                                                            value={moment(values.created_at).format('DD/MM/YYYY')}
                                                            fullWidth
                                                            className={classes.fullInputText}
                                                            disabled={!editMode}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} container>
                                                    <Grid item xs={4} className="item-center">
                                                        <Typography variant="body1">Última modificación: </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="lastUpdate"
                                                            type="text"
                                                            value={moment(values.updated_at).format('DD/MM/YYYY')}
                                                            fullWidth
                                                            className={classes.fullInputText}
                                                            disabled={!editMode}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </> 
                                        )
                                    }

                                </Grid>
                            
                                {
                                    editMode && (
                                        <ModalFooter
                                            buttonType="submit"
                                            confirmText= {butonAction}
                                            cancelText="CANCELAR"
                                            onCancel={handleClose}
                                            onConfirm={handleSubmit}
                                        />
                                    )
                                }
                            </form> 
                        )    
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps =  ( state ) => ({ ...state });
export default connect(mapStateToProps)(withRouter(ModalUploadFile));
